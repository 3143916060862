import {useContext, useEffect, useState} from 'react';
import {Dropdown, Form, Grid, Icon, Input, TextArea, Popup} from 'semantic-ui-react';
import {CourseActionBar} from './CourseActionBar';
import {TagEditor,MailerTagEditor} from '../../lib/tags';
import {AppContext} from '../../app/AppContext';
import {InfoField, InfoLabel, parseLinkName} from '../../lib/utils';

const FormTooltips = [
	"The name of your course that will appear on the certificate by default",
	"A unique ID to refer to this course in custom funnel links",
	"Example of the unique ID used in a custom funnel link",
	"A description of your course, for internal use only",
	"Tags to organize/filter courses. We aim to synchronize tags to your CRM for intelligent marketing."
]

const MailerLabels = [
	{text:'None',info:''},
	{text:'MailChimp Audience',info:'The Mailchimp audience group to add subscribers to'},
 	{text:'MailerLite Group',info:'The MailerLite group to add subscribers to'},
	{text:'ConvertKit Form',info:'The ConvertKit form to add subscribers to'}];

function FunnelLink(props) {
	const {app,linkPath} = props;
	const linkBase = app.session.baseName !== '' ? app.session.baseName : 'custom_link_name';
	return <InfoField>{app.pubBase}/{linkBase}/<strong>{linkPath}</strong></InfoField>
}

export default function EditCoursePage(props) {
	const app = useContext(AppContext);
	const mailer = app.session.mailer ;
	const [name		,setName		] = useState('');
	const [desc		,setDesc		] = useState('');
	const [tags		,setTags		] = useState([]);
	const [mailTags ,setMailTags	] = useState([]);
	const [linkPath ,setLinkPath	] = useState('');
	const [mailGroup,setMailGroup	] = useState('');


	function excludeTags(list1, list2) {
		return list1.filter(tag => !list2.includes(tag));
	}
	
	useEffect(() => {
		if (app.course !== null) {
			const course = app.course;
			const tags = course.tags !== ''?course.tags.split(','):[];
			const mailTags = excludeTags(app.mailingTags.map(item => item.name),tags);
			setName(course.name);
			setDesc(course.desc);
			setLinkPath(course.linkPath);
			setMailGroup(course.mailGroup);
			setMailTags(mailTags);
			setTags(tags);
		}
	},	[app.course,app.mailingTags]);

	const handleUpdate = () => {
		const course = Object.assign({}, app.course);
		course.name = name;
		course.desc = desc;
		course.tags = tags.join(',');
		course.linkPath = linkPath;
		course.mailGroup = mailGroup;
		course.save = true;
		app.setCourse(course);
		if (app.devMode) {
			console.log(course);
			console.log('Course details updated.');
		}
		return course;
	};

	const generateLinkPath = () => {
		if (!linkPath || linkPath === '') {
			const parsed_name = name.replace(/\s+/g,'_');
			setLinkPath(parseLinkName(parsed_name,true));
		}
		return true;
	};

	return (
		<>
			<CourseActionBar update={handleUpdate} commit={app.saveCourse} />
			<Grid columns={1} padded centered>
				<Grid.Column width={12}>
					<Form>
						<Form.Field>
							<InfoLabel info={FormTooltips[0]}>Course Name</InfoLabel>
							<Input value={name} onChange={e => setName(e.target.value)} onBlur={generateLinkPath}  autoFocus />
						</Form.Field>
						<br />
						<Form.Field>
							<InfoLabel info={FormTooltips[1]}>Unique ID</InfoLabel>
							<Input value={linkPath} onChange={e => setLinkPath(parseLinkName(e.target.value,true))} onBlur={generateLinkPath} />
						</Form.Field>
						<Form.Field>
							<InfoLabel info={FormTooltips[2]}>Example Link</InfoLabel>
							<FunnelLink app={app} linkPath={linkPath} />
						</Form.Field>
						{false &&
						<Form.Field>
							<label>Description <Popup content={FormTooltips[3]} trigger={<Icon name="info circle" />} /></label>
							<TextArea value={desc} onChange={e => setDesc(e.target.value)} rows="5" />
						</Form.Field>}
						{mailer !== 0 &&
							<Form.Field>
								<InfoLabel info={MailerLabels[mailer].info}>{MailerLabels[app.session.mailer].text}</InfoLabel>
								<Dropdown options={app.mailingGroups} value={mailGroup} onChange={(e,d)=>setMailGroup(d.value)} selection />
							</Form.Field>}
						<Form.Field>
							<InfoLabel info={FormTooltips[4]}>Tags</InfoLabel>
							{mailer === 0?
							<TagEditor tags={tags} setTags={setTags} />:
							<MailerTagEditor
								tags={tags} setTags={setTags}
								list={mailTags} setList={setMailTags} />}<br />
						</Form.Field>
					</Form>
				</Grid.Column>
			</Grid>
		</>
	);
}