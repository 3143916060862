import {Grid } from 'semantic-ui-react';
import { PaymentList } from '../../app/Payment';

export default function PaymentProfileSection(props) {
	const {app} = props;
	return (
		<Grid columns={1}
			padded centered>
			<Grid.Column width={12}>
				<PaymentList app={app} userId={app.session.id} pageSize={10} />
			</Grid.Column>
		</Grid>
	)

}