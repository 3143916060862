import {useState} from 'react';
import {Button, Card, Menu, Modal, Popup} from 'semantic-ui-react';
import {Confirm} from '../../lib/alerts';

export function CertTemplateSelector(props) {
	const app = props.app;
	const [confirm, setConfirm]  = useState(false);
	const [templateId, setTemplateId] = useState(props.templateId);
	const [open, setOpen] = useState(props.opened);
	const [type, setType] = useState(0);
	const handleTemplateUpdate = () => {
		if (confirm) setConfirm(false);
		console.log('Template confirmed:',templateId);
		props.setTemplateId(templateId);
		setOpen(false);
	}
	const handleTemplateSelect = (templateId) => {
		if (!props.templateId)  {
			console.log('Template confirmed:',templateId);
			props.setTemplateId(templateId);
			setOpen(false);
			return;
		}
		console.log('Template selected:',templateId);
		setTemplateId(templateId);
		setConfirm(true);
	};

	const closeable = props.certTemplateId && props.certTemplateId !== '';
	const GetPreviewImage = item => '/images/previews/' + item.name + '.jpg';

	return (
		<>
		<Confirm open={confirm} setOpen={setConfirm} caption="Select Design" message="This will replace all items in the current certificate. Do you wish to proceed?" onConfirm={handleTemplateUpdate} />
		<Popup content="change certificate design" trigger={<Menu.Item icon="file" onClick={e=>setOpen(true)} />} />
		<Modal
			open={open}
			closeOnEscape={closeable}
			closeOnDimmerClick={closeable}
			onClose={() => setOpen(false)}
		>
		<Modal.Header>Certificate Design</Modal.Header>
		<Modal.Actions>
			<Menu>
				<Menu.Item onClick={()=>setType(0)} disabled={type === 0} link>Portrait	</Menu.Item>
				<Menu.Item onClick={()=>setType(1)} disabled={type === 1} link>Landscape</Menu.Item>
			</Menu>
		</Modal.Actions>
		<Modal.Content image scrolling>
			<Card.Group itemsPerRow={4}>
				{app.certTemplates
				.filter(item => item.type === type)
				.map(item => <Card key={item.id} image={GetPreviewImage(item)}
								color={item.id === templateId ?"green":"gray"}
								onClick={e => handleTemplateSelect(item.id)} />
				)}
			</Card.Group>
		</Modal.Content>
		<Modal.Actions>
		  <Button disabled={!closeable} onClick={() => setOpen(false)} primary>Close</Button>
		</Modal.Actions>
	  </Modal>
		</>
	  );
}