import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Grid, Header, Icon } from 'semantic-ui-react';
import { AppContext } from '../app/AppContext';
import { Comment, Failure, Loading } from '../lib/alerts';
import { apiSignIn } from '../lib/api';

export default function SignInPage(props) {
	const app = useContext(AppContext);
	const [mail			,setMail		] = useState('');
	const [password		,setPassword	] = useState('');
	const [hidePassword	,setHidePassword] = useState(true);
	const [remember		,setRemember	] = useState(false);
	const [valid		,setValid		] = useState(false);
	const [loading		,setLoading		] = useState('');
	const [failure		,setFailure		] = useState('');

	useEffect(() => setValid(mail !== '' && password !== ''),[mail,password]);

	const handleSignIn = e => {
		e.preventDefault();
		setFailure('');
		setLoading('Signing in to your account ...');
		const data = { mail:mail, password:password, remember:remember?1:0 };
		apiSignIn(app, data,
			result => { setLoading(''); app.setSession(result); },
			result => { setLoading(''); setFailure(result);		}
		);
		return false;
	};

	return (
		<Grid columns={1} centered padded>
			<Grid.Column>
				<Header size="large">SIGN IN</Header><br />
				<Form>
					<Form.Input label="Email" value={mail} onChange={(e,d) => setMail(d.value)} onBlur={() => setMail(mail.trim())} autoFocus />
					<Form.Input label="Password" type={hidePassword?'password':'text'} value={password} onChange={(e,d) => setPassword(d.value)} onBlur={() => setPassword(password.trim())} 
						icon={<Icon name={hidePassword?'eye slash':'eye'} onClick={()=>setHidePassword(!hidePassword)} link />}/>
					<Form.Checkbox label='Remember me' checked={remember} onChange={(e,d) => setRemember(d.checked)} />
					<Loading message={loading} />
					<Failure icon="times circle" message={failure} />
					{loading === '' &&<><Form.Button color="blue" disabled={!valid} onClick={handleSignIn}>Sign In</Form.Button>
					<Comment icon="info circle"  message={<span>Don't have an account? Sign up for a free account&nbsp;<Link className="cf-link" to="/signup">here</Link>.</span>} />
					<Comment icon="info circle"  message={<span>Have an account but forgot your password? You can reset it&nbsp;<Link className="cf-link" to="/reset/password">here</Link>.</span>} /></>}
				</Form>
			</Grid.Column>
		</Grid>
	);
}