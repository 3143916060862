import { useEffect, useState } from 'react';
import { Button, Form, Grid, Icon, Image } from "semantic-ui-react";
import { Warning } from '../../lib/alerts';
import { ColorPicker} from '../../lib/colors';
import { FontSelector } from '../../lib/fonts';
import { ImageSelector } from '../../lib/images';
import { InfoField, InfoLabel, parseLinkName } from '../../lib/utils';
import { Failure, Loading, Success} from '../../lib/alerts';
import { useStore } from '../../lib/hooks';
import { apiUpdateUserBranding } from '../../lib/api';

const Tooltips = [
	"Your brand, company or instructor name (required)",
	"A unique brand ID to be used in links to your funnels (required)",
	"An example of a custom funnel link with the Brand ID inserted",
	"An URL to your website or social presence if you have one (optional)",
	"Default heading font to be used in your funnel site and surveys",
	"Default text font to be used in your funnel site and surveys",
	"Your brand's main colour, used as background for funnel header",
	"Your brand's text colour, used for text in funnel header",
	"Your brand logo to be used on your funnel site header",
	"A web browser icon for your funnel site",
	"Your standard logo to be placed into certificates",
	"A default signature for your certificates",
];

const CustomLinkDescription = () => {
	return (
		<Warning icon="warning circle" message={<span>
			The brand ID allows you to create a custom link for all your funnels, quite often it will be your brand name or the main instructor name.
			Note that changing the brand ID will break previous links to funnels, thus it should not be changed after distributing funnel links to students.
		</span>} />
	);
}

function FunnelLink(props) {
	const app = props.app;
	const baseName = props.baseName !== '' ? props.baseName : 'brand-id';
	return <InfoField>{app.pubBase}/<strong>{baseName}</strong>/funnel-id</InfoField>
}

const StoreKey = 'guest/settings/brand/'

export function BrandSettingSection(props) {
	const {app, onUpdated} = props;
	const [loading		, setLoading	] = useState('');
	const [success		, setSuccess	] = useState('');
	const [failure		, setFailure	] = useState('');
	const [valid		, setValid		] = useState(false);
	const [brandName	, setBrandName	] = useStore(StoreKey + 'brandname'	, '');
	const [brandLink	, setBrandLink	] = useStore(StoreKey + 'brandlink'	, '');
	const [baseName		, setBaseName	] = useStore(StoreKey + 'brandid'	, '');
	const [backColor	, setBackColor	] = useStore(StoreKey + 'backcolor'	, '#000000');
	const [foreColor	, setForeColor	] = useStore(StoreKey + 'forecolor'	, '#ffffff');
	const [headFont		, setHeadFont	] = useStore(StoreKey + 'headfont'	, 'Lato');
	const [bodyFont		, setBodyFont	] = useStore(StoreKey + 'bodyfont'	, 'Lato');
	const [siteLogo		, setSiteLogo	] = useStore(StoreKey + 'sitelogo'	, '');
	const [siteIcon		, setSiteIcon	] = useStore(StoreKey + 'siteicon'	, '');
	const [certLogo		, setCertLogo	] = useStore(StoreKey + 'certlogo'	, '');
	const [signature	, setSignature	] = useStore(StoreKey + 'signature'	, '');
	const [imageVars	, setImageVars	] = useState(['','',()=>{}]);
	const [imageOpen	, setImageOpen	] = useState(false);

	useEffect(() => setValid(brandName !== '' && baseName !== ''),[brandName,baseName]);
	useEffect(() => {
		const session = app.session;
		if (session.baseName  !== '') setBaseName(session.baseName);
		if (session.brandData !== '') {
			const data = JSON.parse(app.session.brandData);
			setBrandName(data.brandName);
			setBrandLink(data.brandLink);
			setBackColor(data.backColor);
			setForeColor(data.foreColor);
			setHeadFont(data.headFont || 'Lato');
			setBodyFont(data.bodyFont || 'Lato');
			setCertLogo(data.certLogo);
			setSiteLogo(data.siteLogo);
			setSiteIcon(data.siteIcon);
			setSignature(data.signature||'');
		}
	},[app,
		setBaseName,setBrandName,setBrandLink,
		setBackColor,setForeColor,setHeadFont,setBodyFont,
		setCertLogo,setSiteLogo,setSiteIcon,setSignature]);

	const makeBaseName = () => {
		if (baseName === '') setBaseName(parseLinkName(brandName));
	};

	const getBrandData = () => {
		return {
			baseName	: baseName,
			brandData	: JSON.stringify({
				brandName	:	brandName	,
				brandLink	:	brandLink	,
				backColor	:	backColor	,
				foreColor	:	foreColor	,
				headFont	:	headFont	,
				textFont	:	bodyFont	,
				certLogo	:	certLogo	,
				siteLogo	:	siteLogo	,
				siteIcon	:	siteIcon	,
				signature	:	signature
			})
		};
	}

	const handleSave = e => {
		e.preventDefault();
		const data = getBrandData();
		setFailure('');
		setSuccess('');
		setLoading('Saving brand settings ...');
		apiUpdateUserBranding(app, data,
			result => {
				setLoading('');
				setSuccess('Brand settings saved.');
				app.session.baseName	= result.baseName	;
				app.session.brandData	= result.brandData	;
				if (onUpdated) onUpdated();
			},
			result => {
				setLoading('');
				setFailure(result);
			}
		);
	};

	const getImagePath = path => {
		if (path === '') path = '/images/blank-image.png';
		return app.resBase + path;
	};

	const ImageField = props => {
		const vars = props.vars;
		const handleDeleteImage = (e) => {
			e.stopPropagation();
			vars[2]('');
		}
		const handleSelectImage = (e) => {
			e.stopPropagation();
			setImageVars(vars);
			setImageOpen(true);
		};
		return (
			<Form.Field width={8}>
				<InfoLabel info={Tooltips[props.tooltip]}>{vars[0]}</InfoLabel>
				<div className="cf-image-field" onClick={handleSelectImage} >
					<Image src={getImagePath(vars[1])} />
					<nav>{vars[1] !== '' &&<Button onClick={handleDeleteImage} icon><Icon name="times" /></Button>}</nav>
				</div>
			</Form.Field>
		);
	};

	return (
		<Grid columns={1} padded centered>
			<Grid.Column width={12}>
				<Form>
					<Form.Input label={<InfoLabel info={Tooltips[0]}>Brand Name</InfoLabel>	} value={brandName} onChange={(e,d)=>setBrandName(d.value)	} onBlur={()=>{setBrandName(brandName.trim());	makeBaseName();	}} autoFocus />
					<Form.Group>
						<Form.Input width={8} label={<InfoLabel info={Tooltips[1]}>Brand ID</InfoLabel>} value={baseName}	onChange={(e,d)=>setBaseName(d.value)} onBlur={()=>{setBaseName ( baseName.trim());	makeBaseName();	}} />
						<Form.Field width={8}><InfoLabel info={Tooltips[2]}>Custom Link Example</InfoLabel><FunnelLink app={app} baseName={baseName} /></Form.Field>
					</Form.Group>
					<Form.Field><CustomLinkDescription /></Form.Field>
					<Form.Input label={<InfoLabel info={Tooltips[3]}>Website / Social Link</InfoLabel>} value={brandLink} onChange={(e,d)=>setBrandLink(d.value)	} onBlur={()=>{setBrandLink(brandLink.trim())					}} placeholder="https://www.domain_name.com" />
					<Form.Group>
						<Form.Field width={8}>
							<InfoLabel info={Tooltips[4]}>Brand Heading Font</InfoLabel>
							<FontSelector value={headFont} setValue={setHeadFont} />
						</Form.Field>
						<Form.Field width={8}>
							<InfoLabel info={Tooltips[5]}>Brand Text Font</InfoLabel>
							<FontSelector value={bodyFont} setValue={setBodyFont} />
						</Form.Field>
					</Form.Group>
					<Form.Group>
						<Form.Field width={8}>
							<InfoLabel info={Tooltips[6]}>Brand Primary Colour</InfoLabel>
							<ColorPicker color={backColor} alpha={1} setColor={setBackColor} />
						</Form.Field>
						<Form.Field width={8}>
							<InfoLabel info={Tooltips[7]}>Brand Text Colour</InfoLabel>
							<ColorPicker color={foreColor} alpha={1} setColor={setForeColor} />
						</Form.Field>
					</Form.Group>
					<ImageField tooltip={ 8} vars={['Site Logo'		,siteLogo ,setSiteLogo ]} />
					<ImageField tooltip={ 9} vars={['Site Icon'		,siteIcon ,setSiteIcon ]} />
					<ImageField tooltip={10} vars={['Cert Logo'		,certLogo ,setCertLogo ]} />
					<ImageField tooltip={11} vars={['Cert Signature',signature,setSignature]} />
					<Loading message={loading} />
					<Success icon="check circle" message={success} />
					<Failure icon="times circle" message={failure} />
					{loading === '' && <Form.Button color="blue" disable={!valid} onClick={handleSave}>Save Settings</Form.Button>}
				</Form>
				<ImageSelector app={app} open={imageOpen} setOpen={setImageOpen} vars={imageVars} />
			</Grid.Column>
		</Grid>
	);
}