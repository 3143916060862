import { useEffect , useState } from 'react';
import { Label } from 'semantic-ui-react';
import copyToClipboard from 'copy-to-clipboard';
const PasswordGenerator = require('generate-password');
const PasswordGenerateOptions = { length:8, symbols:true, numbers:true, strict:true };
const PasswordLevelColors = ['red','yellow','green'];

export function generatePassword() {
	const password = PasswordGenerator.generate(PasswordGenerateOptions);
	copyToClipboard(password);
	return password;
}

export function PasswordValidate(props) {
	const { password, setLevel, level } = props;
	const [label, setLabel] = useState('');
	useEffect(() => {
		if (password === 'P@ssw0rd'			) { setLevel(0); setLabel('Password is too common');								} else
		if (password.indexOf(' ') >= 0		) { setLevel(0); setLabel('Password must not contain spaces');						} else
		if (password.length < 8				) { setLevel(0); setLabel('Password has less than 8 characters');					} else
		if (!(/[A-Z]/.test(password))		) { setLevel(1); setLabel('Password should have at least one uppercase alphabet');	} else
		if (!(/[a-z]/.test(password))		) { setLevel(1); setLabel('Password should have at least one lowercase alphabet');	} else
		if (!(/[0-9]/.test(password))		) { setLevel(1); setLabel('Password should have at least one digit');				} else
		if (!(/[^a-z0-9]/i.test(password))	) { setLevel(1); setLabel('Password should have at lease one symbol');				} else {
			setLevel(2); setLabel('Password is good');
		}
	},[password, setLevel]);
	return (<Label color={PasswordLevelColors[level]}>{label}</Label>);
}