import { useState } from 'react';
import Gravatar from 'react-gravatar';
import { Button, Checkbox, Form, Modal } from 'semantic-ui-react';
import { Loading, Failure, Success } from '../../lib/alerts';
import { apiImpersonateUser, apiUpdateUserStatus } from '../../lib/api';

const UserTypeNames = ['Subscriber','Agent','Administrator'];
const UserStatNames = ['Unactivated','Active','Locked'];
const UserPlanTypes = ['Free','Monthly','Annual']

export default function BrowseUserPopup(props) {
	const { app, open, setOpen, item, onUpdated } = props;
	const [loading, setLoading] = useState('');
	const [failure, setFailure] = useState('');
	const [success, setSuccess] = useState('');

	const handleOnClose = () => {
		setOpen(false);
		setLoading('');
		setFailure('');
		setSuccess('');
	}

	const handleImpersonate = () => {
		setFailure('');
		setLoading('Attempting to sign in as user ...');
		apiImpersonateUser(app, item.id,
			result => app.setSession(result),
			result => setFailure(result)
		);
	};

	const handleActivate = () => {
		setFailure('');
		setLoading('Attempting to activate user account ...');
		const data = {id:item.id, status:1};
		apiUpdateUserStatus(app, data,
			result => {
				setLoading('');
				setSuccess('Account activated.');
				onUpdated (result);
			},
			result => {
				setLoading('');
				setFailure(result)
			}
		);
	};

	const self = item !== null && item.id === app.session.id;
	return (
		<Modal
			size="small"
			open={open}
			onClose={handleOnClose}
			closeOnDimmerClick={true}
			closeIcon centered>
			<Modal.Header>User</Modal.Header>
			{loading === '' &&
			<Modal.Content>
				{item !== null &&
				<Form>
					<Form.Group>
						<Form.Field width={8}><label>Name</label>{item.name}</Form.Field>
						<Form.Field width={8}><label>Email</label>{item.mail}</Form.Field>
					</Form.Group>
					<Form.Group>
						<Form.Field width={8}><label>Type</label>{UserTypeNames[item.type-1]}</Form.Field>
						<Form.Field width={8}><label>Status</label>{UserStatNames[item.status]}</Form.Field>
					</Form.Group>
					{item.type === 1 &&
					<Form.Group>
						<Form.Field width={8}><label>Subscription plan</label>{UserPlanTypes[item.plan]}</Form.Field>
						<Form.Field width={8}><label>Expires at</label>{item.plan === 0 ? 'none' : item.expiredAt}</Form.Field>
					</Form.Group>}
					<Form.Group>
						<Form.Field width={8}><label>Created at</label>{item.createdAt}</Form.Field>
						<Form.Field width={8}><label>Last updated at</label>{item.updatedAt}</Form.Field>
					</Form.Group>
					<Form.Group>
						<Form.Field width={8}><label>Last sign in </label>{item.lastSignIn ?item.lastSignIn :'none'}</Form.Field>
						<Form.Field width={8}><label>Last sign out</label>{item.lastSignOut?item.lastSignOut:'none'}</Form.Field>
					</Form.Group>
					<Form.Field className="cf-right">
						<Gravatar className="cf-avatar" email={item.mail} />
					</Form.Field>
				</Form>}
				<Loading message={loading} />
				<Success message={success} icon="check circle" />
				<Failure message={failure} icon="times circle" />
			</Modal.Content>}
			{item !== null && !self &&
			<Modal.Actions>
					{item.type === 1 && item.status === 0 && <Checkbox className="cf-float-left" label="Activate Account" checked={false} onChange={handleActivate} toggle />}
					<Button color="blue" onClick={handleImpersonate}>Sign In As User</Button>
			</Modal.Actions>}
		</Modal>
	);
}