import { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { Button, Card, Grid } from 'semantic-ui-react';
import { Success, Failure, Warning } from '../../lib/alerts';
import { getExpiredDays } from '../../lib/dates';
import { apiGetPaymentLinks, apiGetPaymentPortal } from '../../lib/api';
import { PlanFullNames } from '../../app/Payment';
import { LinkButton } from '../../lib/utils';

const GracePeriods = [0,7,30];

export function SubscriptionSettingSection(props) {
	const [text1, setText1] = useState('');
	const [text2, setText2] = useState('');
	const [text3, setText3] = useState('');
	const [link1, setLink1] = useState('');
	const [link2, setLink2] = useState('');
	const [grace, setGrace] = useState(true);
	const app = props.app;
	const session = app.session;
	const expires = getExpiredDays(session.expiredAt);
	const ExpiresDate = dateFormat(new Date(session.expiredAt),'mmmm dS, yyyy');
	const gracePeriod = GracePeriods[session.plan];

	useEffect(()=> {
		const grace = session.plan === 0 || expires > -gracePeriod; const value = -(expires + gracePeriod);
		setText3(grace?' now':(' in ' + (value + ((value > 1)? ' days' : ' day'))));
		setGrace(grace);
		if (grace) {
			apiGetPaymentLinks(app,
				result => {
					setLink1(result[0]);
					setLink2(result[1]);
				},
				result => console.log(result));
		}
	},	[app,session.plan,expires,gracePeriod]);

	const handleLink = link => {
		window.open(link);
	};

	const SubscriptionPlanCard = props => {
		return (
			<Card>
				<Card.Content>
					<Card.Header className="cf-center">
						{props.plan}
					</Card.Header>
				</Card.Content>
				<Card.Content>
					<div className="cf-center">
						<span style={{fontSize:'32pt'}}>{props.price}</span>
						<span style={{fontSize:'16pt'}}>&nbsp;GBP</span>
					</div>
				</Card.Content>
				<Card.Content>
					<Button color="blue" disabled={!grace} onClick={()=>handleLink(props.link)} fluid>{props.action}{text3}</Button>
				</Card.Content>
			</Card>
		);
	};

	const handleManageSubscription = () => {
		apiGetPaymentPortal(app, success => window.open(success));
	};

	const AccountStatusInfo = () => {
		if (session.plan === 0) {
			setText1("Upgrade");
			setText2("Upgrade");
			return (
				<Warning icon="exclamation circle" message={<p>
					You are currently on a <strong>{PlanFullNames[session.plan]}</strong>.
					You can only have two active funnels at any time with your current plan.
					Upgrade to one of the following plans to get unlimited active funnels.</p>
			}/>);
		}
		if (expires < 0) {
			setText1("Renew"	);
			setText2("Upgrade"	);
			return (
				<Success icon="check circle" message={<p>
					Your <strong>{PlanFullNames[session.plan]}</strong> is still active until {ExpiresDate}.
					You have {-expires} day{expires < -1 && 's'} before your subscription expires.
					You can manage your subscription <LinkButton onClick={handleManageSubscription}>here</LinkButton>.</p>
			}/>);
		}

		setText1("Downgrade");
		setText2("Renew"	)
		return (
			<Failure icon="times circle" message={<p>
				Your <strong>{PlanFullNames[session.plan]}</strong> has expired on {ExpiresDate}.
				You are {expires} day{expires > 1 && 's'} late to renew or upgrade your subscription.
				You can renew your subscription <LinkButton onClick={handleManageSubscription}>here</LinkButton>.</p>
			} />
		);
	};

	return (
		<Grid centered>
			<Grid.Column width={8}>
				<AccountStatusInfo /><br />
				{session.plan === 0 &&
				<Card.Group itemsPerRow={2}>
					<SubscriptionPlanCard plan="Monthtly Subscription"	price="9.95"	action={text1} link={link1} />
					<SubscriptionPlanCard plan="Annual Subscription"	price="99.00"	action={text2} link={link2} />
				</Card.Group>}
			</Grid.Column>
		</Grid>
	);
}