import moment from 'moment';
import uuid from 'react-uuid';
import Moveable from 'react-moveable';
import React, {useContext,useState,useRef,useEffect} from 'react';
import {Popup,TextArea,Menu,Modal,Input,Icon,Button,Grid,Form,Select} from 'semantic-ui-react';
import {DateFormatOptions,DateFormatSelector} from '../../lib/dates';
import { FontSelector,FontWeightSelector} from '../../lib/fonts';
import {Confirm} from '../../lib/alerts';
import {ColorPicker} from '../../lib/colors';
import {ImageSelector} from '../../lib/images';
import {AppContext} from '../../app/AppContext';
import {CourseActionBar} from './CourseActionBar';
import {CertTemplateSelector} from './CertTemplateSelector';
import {ScaleSelector} from './ScaleSelector';

const ViewBoxMargin = 128;
const CommandBarOffset = 300;

const FieldTypeOptions = [
	{ key: "0", value:"0", text:"Custom Text" 	},
	{ key: "1", value:"1", text:"Course Name" 	},
	{ key: "2", value:"2", text:"Recipient Name"},
	{ key: "3", value:"3", text:"Issue Date"	},
	{ key: "4", value:"4", text:"Certificate ID"}
];

function RenderImage(props) {
	const item = props.item;
	const target = useRef(null);
	const cmdbar = useRef(null);
	const moveable = useRef(null);
	const selected = item === props.selectedItem;
	const deleteItem = e => props.deleteItem(item);
	const updateItem = e => props.updateItem(item);
	const handleSelectItem = e => { if (props.activeItem === null) props.setSelectedItem(item); };
	const handleUnselectItem = e => { if (props.activeItem === null) props.setSelectedItem(null); };
	const handleTranslate = e => {
		if (e.target !== null) {
			const transform = e.transform;
			e.target.style.transform = transform;
			item.x = e.translate[0]
			item.y = e.translate[1]
			if (cmdbar.current)
				cmdbar.current.style.transform =
					`translate(${item.x-CommandBarOffset}px,${item.y}px)`;
		}
	};
	const handleScale = e => {
		if (e.target !== null) {
			const transform = e.transform;
			e.target.style.transform = transform;
			item.w = e.scale[0];
			item.h = e.scale[1];
			if (cmdbar.current)
				cmdbar.current.style.transform =
					`translate(${item.x-CommandBarOffset}px,${item.y}px)`;
		}
	};
	const setCommandBar = state => {
		if (cmdbar.current)
			cmdbar.current.style.visibility = state?'visible':'hidden';
	}
	const beginOperation = e => {
		if (props.selectedItem === item) {
			props.setActiveItem(item);
			setCommandBar(false);
		}
	};
	const endOperation = e => {
		if (props.selectedItem === item) {
			props.setActiveItem(null);
			setCommandBar(true);
		}
	}

	if (!selected) return (
		<img ref={target} alt="" onContextMenu={e => e.preventDefault()}
			style={{cursor:"pointer",position:"absolute",left:0,top:0,textAlign:item.a,transformOrigin:"top left",transform:`translate(${item.x}px,${item.y}px) scale(${item.w},${item.h})`}} src={item.text}
			onMouseEnter={handleSelectItem} onMouseLeave={handleUnselectItem} />
	);

	return (
		<React.Fragment>
			<Moveable className="moveable-image" ref={moveable} target={target.current} container={null}
				draggable={true} scalable={true} keepRatio={true}
				onDragStart={beginOperation} onDragEnd={endOperation}
				onScaleStart={beginOperation} onScaleEnd={endOperation}
				onDrag={handleTranslate} onScale={handleScale} />
			<img ref={target} alt="" onContextMenu={e => e.preventDefault()}
				style={{cursor:"move",position:"absolute",textAlign:item.a,left:0,top:0,transformOrigin:"top left",transform:`translate(${item.x}px,${item.y}px) scale(${item.w},${item.h})`}} src={item.text}/>
			<div ref={cmdbar} style={{backgroundColor:"white",border:"2px solid #cccccc",borderRadius:'4px',padding:'8px',position:"absolute",zIndex:99,left:0,top:0,transform:`translate(${item.x-CommandBarOffset}px,${item.y}px)`}}>
				<Icon style={{margin:'4px'}} color="black" link size="massive" onClick={deleteItem} name="trash"/>
				<Icon style={{margin:'4px'}} color="black" link size="massive" onClick={updateItem} name="pencil" />
			</div>
		</React.Fragment>
	);
}

function RenderLabel(props) {
	const app = props.app;
	const item = props.item;
	const target = useRef(null);
	const cmdbar = useRef(null);
	const moveable = useRef(null);
	const selected = item === props.selectedItem;
	const deleteItem = e => { console.log(e); e.preventDefault(); e.stopPropagation(); props.deleteItem(item); }
	const updateItem = e => { console.log(e); e.preventDefault(); e.stopPropagation(); props.updateItem(item); }
	const handleSelectItem = e => { if (props.activeItem === null) props.setSelectedItem(item); };
	const handleUnselectItem = e => { if (props.activeItem === null) props.setSelectedItem(null); };
	const handleScale = e => {
		if (e.target !== null) {
			item.w = e.offsetWidth * e.scale[0];
			e.target.style.width = item.w + 'px';
		}
	};
	const handleTransform = e => {
		if (e.target !== null) {
			item.x = e.translate[0];
			item.y = e.translate[1];
			e.target.style.transform = `translate(${item.x}px,${item.y}px)`;
		}
	};

	const setCommandBar = state => {
		if (cmdbar.current)
			cmdbar.current.style.visibility = state?'visible':'hidden';
	}
	const beginOperation = e => {
		if (props.selectedItem === item) {
			props.setActiveItem(item);
			setCommandBar(false);
		}
	};
	const endOperation = e => {
		if (props.selectedItem === item) {
			props.setActiveItem(null);
			setCommandBar(true);
		}
	}

	const getCertVerification = id => {
		const baseName = app.session.baseName;
		const baseSite = app.devMode ?
			'http://localhost:3000/verify/'	+ baseName :
			'http://portal.accredemy.com/'	+ baseName + '/verify';
		return 'Verify this certificate ID:' + id + ' at ' + baseSite;
	}

	var text = '';
	switch (item.data) {
		case 0 : text = item.text;								break;
		case 1 : text = app.course.name;						break;
		case 2 : text = app.session.name;						break;
		case 3 : text = moment().format(item.text); 			break;
		case 4 : text = getCertVerification('62957412E471C');	break;
		default: text = 'Invalid text field';					break;
	}
	if (!item.w) {
		var minWidth = text.length * item.z;
		if (minWidth > props.width) minWidth = props.width;
		item.w = minWidth;
	}
	console.log(item.w);

	var alignment1 = {};
	var alignment2 = {};
	switch (item.a) {
		case 'left':
			alignment1 = {textAlign:'left',top:0,left:0,transform:`translate(${item.x}px,${item.y}px)`};
			alignment2 = {top:0,left:(-CommandBarOffset-40)+'px',transform:`translate(${item.x}px,${item.y}px)`};
			break;
		case 'center':
			alignment1 = {textAlign:'center',top:0,left:0,transform:`translate(${item.x}px,${item.y}px)`};
			alignment2 = {top:0,left:(-CommandBarOffset-40)+'px',transform:`translate(${item.x}px,${item.y}px)`};
			break;
		case 'right':
			alignment1 = {textAlign:'right',top:0,left:0,transform:`translate(${item.x}px,${item.y}px)`};
			alignment2 = {top:0,left:(-CommandBarOffset-40)+'px',transform:`translate(${item.x}px,${item.y}px)`};
			break;
		default:
			break;
	}

	if (!selected) return (
		<p onContextMenu={e => e.preventDefault()} contentEditable={false} dangerouslySetInnerHTML={{__html:text.replaceAll("\n","<br>")}}
			style={{cursor:"pointer",fontFamily:item.font,fontSize:item.z+"px",fontWeight:item.b,color:item.c,position:"absolute",...alignment1,width:item.w +'px'}}
			onMouseEnter={handleSelectItem} onMouseLeave={handleUnselectItem} />
	);

	return (
		<React.Fragment>
			<Moveable className="moveable-label" ref={moveable}
				target={target.current} container={null} draggable={true} scalable={true}
				onDragStart={beginOperation} onDragEnd={endOperation}
				onScaleStart={beginOperation} onScaleEnd={endOperation}
				onDrag={handleTransform} onScale={handleScale}>
			</Moveable>
			<p ref={target} alt="" onContextMenu={e => e.preventDefault()} onClick={e=> { e.preventDefault(); e.stopPropagation();}} contentEditable={false} dangerouslySetInnerHTML={{__html:text.replaceAll("\n","<br>")}}
				style={{cursor:"move",fontFamily:item.font,fontSize:item.z+"px",fontWeight:item.b,color:item.c,position:"absolute",...alignment1,width:item.w + 'px'}} />
			<div ref={cmdbar} style={{fontSize:'16px',backgroundColor:"white",border:"2px solid #cccccc",borderRadius:'4px',padding:'8px',position:"absolute",...alignment2,zIndex:99}}>
				<Icon style={{margin:'4px'}} color="black" link size="massive" onClick={deleteItem} name="trash"/>
				<Icon style={{margin:'4px'}} color="black" link size="massive" onClick={updateItem} name="pencil" />
			</div>
		</React.Fragment>
	);
}

function RenderItem(props) {
	const item = props.item;
	const deleteItem = props.deleteItem;
	const updateItem = props.updateItem;
	const activeItem = props.activeItem;
	const setActiveItem = props.setActiveItem;
	const selectedItem = props.selectedItem;
	const setSelectedItem = props.setSelectedItem;
	switch (item.type) {
		case  0: return <RenderImage scale={props.scale} item={item} selectedItem={selectedItem} setSelectedItem={setSelectedItem} deleteItem={deleteItem} updateItem={updateItem} activeItem={activeItem} setActiveItem={setActiveItem} />;
		case  1: return <RenderLabel app={props.app} scale={props.scale} item={item} selectedItem={selectedItem} setSelectedItem={setSelectedItem} deleteItem={deleteItem} updateItem={updateItem} activeItem={activeItem} setActiveItem={setActiveItem} width={props.width} />;
		default: return <></>;
	}
}

function AddFieldPopup(props) {
	const app = props.app;
	const [open,setOpen] = useState(props.open?true:false);
	const [data,setData] = useState(props.data?props.data:'0');
	const [text,setText] = useState(props.text?props.text:'' );
	const [fieldFont, setFieldFont] = useState(props.fieldFont?props.fieldFont:'Lato');
	const [fieldA, setFieldA] = useState(props.fieldA?props.fieldA:'left');
	const [fieldC, setFieldC] = useState(props.fieldC?props.fieldC:'#000000');
	const [fieldB, setFieldB] = useState(props.fieldC?props.fieldB:'400');
	const [fieldZ, setFieldZ] = useState(props.fieldZ?props.fieldZ:144);

	const handleConfirm = () => {
		props.onConfirm(uuid(),parseInt(data),text,fieldFont,fieldA,fieldB,fieldC,fieldZ);
		if (props.setOpen) props.setOpen(false);
		else setOpen(false);
	};

	const handleClose = () => {
		if (props.open) props.setOpen(false);
		else setOpen(false);
	}

	const selectData = data => {
		const item = FieldTypeOptions.find(item => item.value === data);
		setText(item.value === '3' ? DateFormatOptions[0].value : item.text);
		setData(data);
	}

	const getCertVerification = id => {
		const baseName = app.session.baseName;
		const baseSite = app.devMode ?
			'http://localhost:3000/verify/'	+ baseName :
			'http://portal.accredemy.com/'	+ baseName + '/verify';
		return 'Verify this certificate ID:' + id + ' at ' + baseSite;
	}

	const getText = value => {
		var text = '';
		switch (parseInt(data)) {
			case 0 : text = value;									break;
			case 1 : text = app.course.name;						break;
			case 2 : text = app.session.name;						break;
			case 3 : text = moment().format(value); 				break;
			case 4 : text = getCertVerification('62957412E471C');	break;
			default: text = 'Invalid text field';					break;
		}
		return text;
	};

	return (
		<React.Fragment>
		<Popup content="add text" trigger={<Menu.Item icon onClick={e=>setOpen(true)}><Icon name="paragraph" /></Menu.Item>} />
		<Modal open={open}
			closeOnDimmerClick={false}
			onClose={() => setOpen(false)}
			size="tiny" centered>
			<Modal.Header>{props.open?'Edit':'Add'} Text</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Field>
						<Select placeholder='Select field type' options={FieldTypeOptions}
							onChange={(e,d)=>selectData(d.value)} value={data} />
					</Form.Field>
					<Form.Group>
						<Form.Field>
							<Button.Group>
								<Button icon disabled={fieldA==='left'	} onClick={e=>setFieldA('left'	)}><Icon name='align left'	/></Button>
								<Button icon disabled={fieldA==='center'} onClick={e=>setFieldA('center')}><Icon name='align center'/></Button>
								<Button icon disabled={fieldA==='right'	} onClick={e=>setFieldA('right'	)}><Icon name='align right'	/></Button>
							</Button.Group>
						</Form.Field>
						<Form.Field><ColorPicker color={fieldC} alpha={1.0} setColor={setFieldC} /></Form.Field>
						<Form.Field width={10}><FontSelector value={fieldFont} setValue={setFieldFont} /></Form.Field>
					</Form.Group>
					<Form.Group>
						<Form.Field width={8}><FontWeightSelector value={fieldB} setValue={setFieldB} /></Form.Field>
						<Form.Field width={8}><Input label="Size" value={fieldZ} onChange={e => setFieldZ(e.target.value)} /></Form.Field>
					</Form.Group>
					{data === '0' && <Form.Field><TextArea label="Text" value={text} onChange={e=>setText(e.target.value)} fluid autoFocus /></Form.Field>}
					{data === '3' && <Form.Field><DateFormatSelector value={text} setValue={setText} /></Form.Field>}
					<Form.Field>
						<div style={{border:'solid 1px #c0c0c0',overflow:'scroll', maxHeight:'40vh'}}>
							<p align={fieldA} onContextMenu={e => e.preventDefault()} contentEditable={false} dangerouslySetInnerHTML={{__html:getText(text).replace(/(?:\r\n|\r|\n)/g, '<br>')}}
								style={{pointerEvents:'none',width:"max-content", minWidth:fieldZ+"px",fontFamily:fieldFont,fontSize:(fieldZ * props.scale)+"px",fontWeight:fieldB,color:fieldC}} />
						</div>
					</Form.Field>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button disabled={data === "0" && text.length === 0} onClick={handleConfirm} positive>Confirm</Button>
				<Button onClick={handleClose} negative>Cancel</Button>
			</Modal.Actions>
		</Modal>
		</React.Fragment>
	);
}

export default function EditCertificatePage(props) {
	const app = useContext(AppContext);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [confirmUpdate, setConfirmUpdate] = useState(false);
	const [imageVars, setImageVars] = useState(['','',()=>{}]);
	const [imageOpen, setImageOpen] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [certTemplateId, setCertTemplateId] = useState('');
	const [certData, setCertData] = useState(null);
	const [items,setItems] = useState(null);
	const [scale,setScale] = useState(0.2);
	const [view_w,setView_w] = useState(1760);
	const [view_h,setView_h] = useState( 810);
	const [selectedItem, setSelectedItem] = useState(null);
	const [activeItem, setActiveItem] = useState(null);
	const viewRef = useRef();
	const backgroundRef = useRef();

	const handleUpdate = () => {
		certData.items = items;
		const course = Object.assign({}, app.course);
		course.certData = JSON.stringify(certData);
		course.certTemplateId = certTemplateId;
		course.save = true;
		app.setCourse(course);
		if (app.devMode) {
			console.log(course);
			console.log('Course certificate updated.');
		}
		return course;
	};

	useEffect(() => {
		if (app.course !== null) {
			const course = app.course;
			setCertTemplateId(course.certTemplateId);
			if (course.certTemplateId !== '') {
				const certData = JSON.parse(course.certData);
				setCertData(certData); setItems(certData.items);
			}
		}
	},	[app.course]);

	useEffect(() => {
		setRefresh(flag => !flag);
	}, [selectedItem]);

	useEffect(() => {
		setView_w(window.innerWidth);
		setView_h(window.innerHeight - ViewBoxMargin);
	},	[scale]);

	const updateTemplateWithBranding = certData => {
		const branding = JSON.parse(app.session.brandData);
		const items = certData.items;
		for(let i = 0; i < items.length; i++) {
			if (items[i].name === 'logo'		&& branding.certLogo	!== '') items[i].text = branding.certLogo; else
			if (items[i].name === 'signature'	&& branding.signature	!== '') items[i].text = branding.signature;
		}
		return certData;
	};

	const updateTemplateId = id => {
		const template = app.selectCertTemplate(id);
		const certData = updateTemplateWithBranding(JSON.parse(template.data));
		if (backgroundRef.current) backgroundRef.current.scrollIntoView();
		const course = Object.assign({}, app.course);
		course.certData = JSON.stringify(certData);
		course.certTemplateId = id;
		course.save = true;
		app.setCourse(course);
		setCertTemplateId(id);
		setCertData(certData);
		setItems(certData.items);
	};

	useEffect(() => {
		const onWindowResize = e =>  {
			setView_w(window.innerWidth);
			setView_h(window.innerHeight - ViewBoxMargin);
		};
		onWindowResize();
		window.addEventListener('resize', onWindowResize);
		return () => window.removeEventListener('resize', onWindowResize);
	},[]);

	const updateScale = value => {
		setScale(value);
	};

	const insertImage = (name,text, x, y) => {
		if (viewRef.current) {
			const view = viewRef.current;
			x = x + certData.w / 2;
			y = y + certData.h / 2;
			view.scrollLeft = x * scale - view.clientWidth  / 2;
			view.scrollTop  = y * scale - view.clientHeight / 2;
		}
		const image = {
			type	: 0,
			name	: name,
			text	: text,
			x		: x,
			y		: y,
			w		: 1,
			h		: 1,
			v		: true
		};
		setItems([...items, image]);
		setSelectedItem(image);
	};
	
	const insertField = (name,data,text,fieldFont,fieldA,fieldB,fieldC,fieldZ) => {
		var x = 0;
		var y = 0;
		if (viewRef.current) {
			const view = viewRef.current;
			x = x + certData.w / 2;
			y = y + certData.h / 2;
			view.scrollLeft = x * scale - view.clientWidth  / 2;
			view.scrollTop  = y * scale - view.clientHeight / 2;
		}
		const field = {
			type  	: 1,
			name	: name,
			text	: text,
			data	: data,
			font	: fieldFont,
			a		: fieldA,
			c 		: fieldC,
			b		: fieldB,
			z		: fieldZ,
			x		: x,
			y		: y,
			v		: true
		};
		setItems([...items, field]);
		setSelectedItem(field);
	};
	
	const removeSelectedItem = e => {
		if (selectedItem !== null)  {
			const item = selectedItem;
			setItems([...items.filter(x => x !== item)]);
			setSelectedItem(null);
		}
	};

/*
	useEffect(() => {
		const handleKeyDown = e => {
			if (e.keyCode === 46){
				e.preventDefault();
				setConfirmDelete(true);
			}
		}
		document.addEventListener('keydown', handleKeyDown);
		return function cleanup() { document.removeEventListener('keydown', handleKeyDown); }
	}, [items,selectedItem]);
*/

	const selectImage = path => insertImage(uuid(),path,0,0);
	const updateImage = path => {
		if (selectedItem !== null && selectedItem.type === 0) {
			selectedItem.text = path;
			setRefresh(!refresh);
			setSelectedItem(null);
		}
		setConfirmUpdate(false);
	}

	const updateField = (name,data,text,fieldFont,fieldA,fieldB,fieldC,fieldZ) => {
		if (selectedItem !== null && selectedItem.type === 1) {
			selectedItem.data = data;
			selectedItem.text = text;
			selectedItem.font = fieldFont;
			selectedItem.a = fieldA;
			selectedItem.b = fieldB;
			selectedItem.c = fieldC;
			selectedItem.z = fieldZ;
			setRefresh(!refresh);
			setSelectedItem(null);
		}
	}

	if (!app.course) return <></>;

	const onCloseImageSelector = value => {
		setImageOpen(value); if (!value) setConfirmUpdate(false);
	};

	const openImageSelectorForInsert = () => {
		setImageVars(['Add Image','',selectImage]);
		setImageOpen(true);
	};

	const openImageSelectorForUpdate = () => {
		setImageVars(['Update Image',selectedItem.text,updateImage]);
		setImageOpen(true);
	};

	const handleUpdateItem = item => {
		setConfirmUpdate(true);
		if (item.type === 0) {
			openImageSelectorForUpdate();
		}
	};

	return (
		<>
			<CourseActionBar update={handleUpdate} commit={app.saveCourse} />
			<Grid style={{padding:"0",margin:"0"}}>
				<Grid.Column width={16} style={{padding:"0"}} centered>
					<Menu>
						<CertTemplateSelector app={app} opened={app.course.certTemplateId===''} templateId={certTemplateId} setTemplateId={updateTemplateId} />
						<ScaleSelector scale={scale} setScale={updateScale} />
						<Menu.Item icon onClick={openImageSelectorForInsert}><Icon name="image" /></Menu.Item>
						<AddFieldPopup key="insert-field" app={app} scale={scale} onConfirm={insertField} />
						{confirmUpdate && selectedItem.type ===1 && <AddFieldPopup key="update-field" app={app} open={true} setOpen={setConfirmUpdate} scale={scale} onConfirm={updateField} data={""+selectedItem.data} text={selectedItem.text} fieldA={selectedItem.a} fieldB={selectedItem.b} fieldC={selectedItem.c} fieldZ={selectedItem.z} fieldFont={selectedItem.font} />}
						{selectedItem !== null && <Confirm caption={"Delete " + (selectedItem.type === 0?'Image':'Field')} message="Are you sure you want to remove this item?" open={confirmDelete} setOpen={setConfirmDelete} onConfirm={removeSelectedItem} />}
					</Menu>
				</Grid.Column>
			</Grid>
			<Grid style={{width:"100%",backgroundColor:"#555",padding:"0",margin:"0"}}>
				{certTemplateId !== '' &&
				<Grid.Column width={16} style={{padding:"0"}}>
					<div ref={viewRef} style={{margin:"0",border:"1px solid #555",maxWidth:(view_w) + "px",height:view_h + "px",overflow:"scroll",backgroundColor:"#444"}}>
						<div alt={refresh.toString()} style={{margin:"auto",paddingTop:"1rem",width:(certData.w * scale) + "px",height:(certData.h * scale) + "px",overflow:"visible",transformOrigin:"top left",position:"relative",transform:"scale(" + scale + "," + scale + ")"}}>
							<img ref={backgroundRef} alt="" src={certData.image} onContextMenu={e => e.preventDefault()} onClick={e=>setSelectedItem(null)} />
							{items && items.map(x => <RenderItem app={app} key={x.name} item={x} scale={scale}
								setSelectedItem={setSelectedItem} selectedItem={selectedItem}
								setActiveItem={setActiveItem} activeItem={activeItem}
								deleteItem={()=>setConfirmDelete(true)}
								updateItem={handleUpdateItem}
								width={view_w} />)}
						</div>
					</div>
				</Grid.Column>}
			</Grid>
			<ImageSelector app={app} open={imageOpen} setOpen={onCloseImageSelector} vars={imageVars} />
		</>
	);
}