import { useContext, useEffect, useState } from 'react';
import { Grid, Pagination, Table } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from './AppContext';
import { apiGetPaymentDetails, apiGetPaymentList } from '../lib/api';
import { LinkButton } from '../lib/utils';
import { saveToStore, useStore } from '../lib/hooks';
import { Failure, Success } from '../lib/alerts';
import dateFormat from 'dateformat';

export const PlanNames = [
	'Free Plan',
	'Monthly Plan',
	'Annual Plan'
];

export const PlanFullNames = [
	'Free Subscription Plan',
	'Monthly Subscription Plan',
	'Annual Subscription Plan'
];

export function PaymentList(props) {
	const { app , userId, pageSize } = props;
	const [pageIndex,setPageIndex] = useStore('app/paymentlist/index',0);
	const [pageCount,setPageCount] = useState(0);
	const [list,setList] = useState([]);
	const [page,setPage] = useState([]);
	const [failure,setFailure] = useState('');

	useEffect(() => {
		setFailure('');
		apiGetPaymentList(app, userId, 
			result => {
				setList(result);
				const count = result.length;
				if (count === 0) setFailure('No payments found.');
				setPageIndex(index => Math.min(index , count));
				setPageCount(Math.ceil(count / pageSize));
				setPage(result.slice(0, pageSize));
			},
			result => {
				setList([]);
				setPageIndex(0);
				setPageCount(0);
				setPage([]);
			});
		},[app,userId,pageSize,setPageIndex]);
	
	const updatePageIndex = pageIndex => {
		const itemIndex = (pageIndex - 1) * pageSize;
		setPage(list.slice(itemIndex, itemIndex + pageSize));
		setPageIndex(pageIndex);
	};

	const getFormattedDate = date => {
		return dateFormat(new Date(date),'mmmm dS, yyyy');
	};

	return (
		<>
		<Failure message={failure} />
		{pageCount > 0 &&
		<Table celled>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>ID</Table.HeaderCell>
					<Table.HeaderCell>Plan</Table.HeaderCell>
					<Table.HeaderCell ><div className="cf-right">Payment</div></Table.HeaderCell>
					<Table.HeaderCell>Renewed</Table.HeaderCell>
					<Table.HeaderCell>Expires</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{page.map(item =>
					<Table.Row>
						<Table.Cell>{item.id}</Table.Cell>
						<Table.Cell>{PlanNames[item.plan]}</Table.Cell>
						<Table.Cell><div class="cf-right">{item.payment}</div></Table.Cell>
						<Table.Cell>{getFormattedDate(item.renewalAt)}</Table.Cell>
						<Table.Cell>{getFormattedDate(item.expiredAt)}</Table.Cell>
					</Table.Row>
				)}
			</Table.Body>
		</Table>}
		<div class="cf-right">
		{pageCount > 1 &&
			<Pagination
				size="tiny"
				activePage={pageIndex}
				totalPages={pageCount}
				boundaryRange={1} siblingRange={4}
				showEllipses={true} firstItem={null} lastItem={null}
				onPageChange={(e,d)=> updatePageIndex(d.activePage)}
			/>}
		</div>
		</>
	);
}

export default function PaymentPage(props) {
	const app = useContext(AppContext);
	const params = useParams();
	const navigate = useNavigate();
	const mode = params.mode;
	const data = params.data;
	const [user,setUser] = useState(null);
	const [plan,setPlan] = useState(0)

	useEffect(() => {
		apiGetPaymentDetails(app, data,
			result => {
				const user = result[0];
				const plan = result[1];
				setUser(user);
				setPlan(plan);
				if (app.session.plan !== plan ||
					app.session.expiredAt !== user.expiredAt)
					app.setSession(user);
		});
	},[app,data]);


	const handleRepay = () =>  {
		saveToStore('guest/settings/section','subscription');
		navigate('/settings');
	};

	const handleAudit = () => {
		saveToStore('guest/profile/section','payments');
		navigate('/profile');
	};

	const handleLogin = () => {
		app.setSession(user);
	};

	const RenderSuccessLinks = () => {
		if (app.session.type !== 0) return <span>Click <LinkButton onClick={handleAudit}>here</LinkButton> to view your payment history.</span>;
		return <span>Click <LinkButton onClick={handleLogin}>here</LinkButton> to login to access your payment history.</span>
	}

	const RenderFailureLinks = () => {
		if (app.session.type !== 0) return <span>Click <LinkButton onClick={handleRepay}>here</LinkButton> to retry your payment.</span>
		return <span>Click <LinkButton onClick={handleLogin}>here</LinkButton> to login to retry your payment.</span>
	}

	return (
		<Grid columns={1}
			centered padded>
			<Grid.Column width={12}>
				{mode === 'success' && <Success icon="check circle" message={<span>
					Thanks for your continued patronage.<br />
					Your payment for <strong>{PlanNames[plan]}</strong> has been completed.<br />
					<RenderSuccessLinks />
				</span>} />}
				{mode === 'failure' && <Failure icon="times circle" message={<span>
					Sorry we're not able to process your subscription.<br />
					Your payment for <strong>{PlanNames[plan]}</strong> has failed to complete.<br />
					<RenderFailureLinks />
				</span>} />}
			</Grid.Column>
		</Grid>
	);
}