import { Button, Icon, Popup } from 'semantic-ui-react';

export function LinkButton(props) {
	return <span className="cf-link" onClick={props.onClick}>{props.children}</span>;
}

export function InfoLabel(props) {
	const icon = props.icon || 'info circle';
	return <label>{props.children}&nbsp;<Popup content={props.info} trigger={<Icon name={icon} />}/></label>
}

export function InfoBlock(props) {
	return <div><Popup content={props.info} trigger={props.children}/></div>;
}

export function InfoField(props) {
	return <div className="cf-field">{props.children}</div>;
}

export function ChoiceButton(props) {
	const {
		value	= false,
		values	= [false, true],
		choices = ['Off', 'On']
	} = props;
	return (
		<Button.Group fluid>
			<Button color={value===values[0]?'blue':'white'} onClick={e=>props.setValue(values[0])}>{choices[0]}</Button>
			<Button color={value===values[1]?'blue':'white'} onClick={e=>props.setValue(values[1])}>{choices[1]}</Button>
		</Button.Group>
	)
}

export function PreviewIndicator({preview}) {
	if (!preview) return <></>;
	return <div className="cf-floater cf-indicator">PREVIEW</div>
}

export function parseLinkName(name, path) {
	var expr = path?/[^a-z0-9-_/]/g:/[^a-z0-9-_]/g;
	return name.toLowerCase().replace(expr, '');
}

export function sortByName(a, b) {
	if (a.name < b.name) return -1;
	if (a.name > b.name) return +1;
	return 0;
}