import { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Icon, Modal } from 'semantic-ui-react';
import { generatePassword } from '../../lib/passwords';
import { Failure, Loading } from '../../lib/alerts';
import { apiInsertUser } from '../../lib/api';

const UserTypeOptions = [
	{key:'1', value:'1', text:'Subscriber'		},
	{key:'2', value:'2', text:'Agent'			},
	{key:'3', value:'3', text:'Administrator'	},
];

const UserPlanOptions = [
	{key:'0', value:'0', text:'Free Plan'	},
	{key:'1', value:'1', text:'Monthly Plan'},
	{key:'2', value:'2', text:'Annual Plan'	},
]

export default function InsertUserPopup(props) {
	const {app, open, setOpen, onUpdated} = props;
	const [name		, setName		] = useState('');
	const [mail		, setMail		] = useState('');
	const [type		, setType		] = useState(1);
	const [plan		, setPlan		] = useState(0);
	const [activate	, setActivate	] = useState(false);
	const [password	, setPassword	] = useState('');
	const [loading	, setLoading	] = useState('');
	const [failure	, setFailure	] = useState('');
	const [valid	, setValid		] = useState(false);

	useEffect(() => setValid(name !== '' && mail !== '' && password !== ''),[name,mail,password]);

	const handleOnClose = () => setOpen(false);
	const handleGeneratePassword = () => setPassword(generatePassword());
	const handleSuccess = result => {
		setOpen(false);
		setLoading('');
		setName('');
		setMail('');
		setType(1);
		setPlan(0);
		setPassword('');
		setActivate(false);
		onUpdated(result);
	};

	const handleFailure = result => { 
		setFailure(result);
		setLoading('');
	};

	const handleConfirm = () => {
		setFailure('');
		setLoading('Attempting to add a new user account ...');
		const data = {name:name, mail:mail, password:password, type:type, plan:plan, activate:activate};
		apiInsertUser(app, data,
			result => handleSuccess(result),
			result => handleFailure(result)
		);
	};

	return (
		<Modal
			size="small"
			open={open}
			onClose={handleOnClose}
			closeOnDimmerClick={false}
			centered>
			<Modal.Header>Add User</Modal.Header>
			<Modal.Content>
				{loading === '' &&
				<Form>
					<Form.Group>
						<Form.Input width={10} label="Name" value={name} onChange={(e,d)=>setName(d.value)} onBlur={()=>setName(name.trim())} autoFocus />
						<Form.Dropdown width={6} label="Type" options={UserTypeOptions} value={type + ''} onChange={(e,d)=>setType(parseInt(d.value))} selection />
					</Form.Group>
					{type === 1 && <Form.Dropdown label="Subscription plan" options={UserPlanOptions} value={plan + ''} onChange={(e,d)=>setPlan(parseInt(d.value))} selection />}
					<Form.Input label="Email" value={mail} onChange={(e,d)=>setMail(d.value)} onBlur={()=>setMail(mail.trim())} />
					<Form.Input label="Password" value={password} onChange={(e,d)=>setPassword(d.value)} onBlur={()=>setPassword(password.trim())}
						icon={<Icon name="refresh" onClick={handleGeneratePassword} link />} />
				</Form>}
				<Loading message={loading} />
				<Failure message={failure} icon="times circle" />
			</Modal.Content>
			{loading === ''&&
			<Modal.Actions>
				{type === 1 && <Checkbox className="cf-float-left" label="Activate Account" checked={activate === 1} onChange={(e,d)=>setActivate(d.checked?1:0)} toggle />}
				<Button positive disabled={!valid} onClick={handleConfirm}>Confirm</Button>
				<Button negative onClick={handleOnClose}>Cancel</Button>
			</Modal.Actions>}
		</Modal>
	);
}