import axios from 'axios';
import { CSVLink } from 'react-csv';
import React, { useContext, useState, useEffect } from 'react';
import { Checkbox, Form, Grid, Icon, Modal, Pagination, Table } from 'semantic-ui-react';
import { SearchData, SearchBar } from '../lib/search';
import {AppContext} from '../app/AppContext';
import { useStore } from '../lib/hooks';
import { Failure } from '../lib/alerts';
import { apiGetCourseList } from '../lib/api';

const NonSurveyVariables = {
	"id"			: undefined,
	"name"			: undefined,
	"course"		: undefined,
	"issued"		: undefined,
	"course_name"	: undefined,
	"cert_link"		: undefined,
	"privacy_policy": undefined,
	"first_name"	: undefined,
	"last_name"		: undefined,
	"email"			: undefined,
	"optin"			: undefined
};

function UserDataBrowser(props) {
	const { open, setOpen, data} = props;
	const [list, setList] = useState(null );
	const DataItemRenderer = props => {
		var item = props.item;
		return (
			<Table.Row>
				<Table.Cell collapsing>{item.id}</Table.Cell>
				<Table.Cell>{item.value}</Table.Cell>
			</Table.Row>
		);
	};

	useEffect(() => {
		if (data !== null) {
			const list = [];
			for (const [key, value] of Object.entries(JSON.parse(data)))
				if (!(key in NonSurveyVariables))
					list.push({id: key, value: value});
			setList(list);
		}
	}, [data]);

	return (
		<Modal size="small"
			open={open}
			onClose={() => setOpen(false)}
			closeOnDimmerClick={false}
			closeIcon centered>
			<Modal.Header>Survey Data</Modal.Header>
			<Modal.Content>
				<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Field</Table.HeaderCell>
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				{list !== null &&
				<Table.Body>
					{list.map(item => <DataItemRenderer key={item.id} item={item} />)}
				</Table.Body>}
				</Table>
			</Modal.Content>
		</Modal>
	);
}

const PageSize = 5;

export default function ExportPage(props) {
	const app = useContext(AppContext);
	const [courseList	, setCourseList	] = useState([]);
	const [searchData	, setSearchData	] = useStore('guest/exportpage/search',SearchData());
	const [exportSurvey, setExportSurvey] = useState(0);
	const [data			, setData		] = useState(null);
	const [list			, setList		] = useState([]);
	const [page			, setPage		] = useState([]);
	const [pageCount	, setPageCount	] = useState(0);
	const [pageIndex	, setPageIndex	] = useStore('guest/exportpage/index',1);
	const [failure		, setFailure	] = useState('');
	const [dataOpen		, setDataOpen	] = useState(false);
	const [dataItem		, setDataItem	] = useState(null);

	useEffect(() => {
		apiGetCourseList(app,
			result => setCourseList(result),
			result => console.log(result)
		);
	},[app]);

	useEffect(() => {
		setFailure('');
		axios.post(app.apiBase + '/search/certificates',searchData).then(response => {
			var data = response.data;
			if (app.devMode) console.log(data);
			if (data.status) {
				const result = data.result;
				setList(result);
				const count = result.length;
				if (count === 0) setFailure('No certificates found.');
				setPageIndex(index => Math.min(index , count));
				setPageCount(Math.ceil(count / PageSize));
				setPage(result.slice(0, PageSize));
				setData(null);
			}
		}).catch(error => console.log(error));
	},[app, searchData, setPageIndex]);

	useEffect(() => {
		if (list !== null && data === null) {
			const data = [];
			const keys = ['id','name','email','course','issued'];
			if (exportSurvey === 1) {
				list.forEach(item => {
					const surveyData = JSON.parse(item.data);
					Object.keys(surveyData).forEach(key => {
						if (!(key in NonSurveyVariables)
						&&	!(keys.includes(key)))
							keys.push(key);
					});
				});
			}
			data.push(keys);
			list.forEach(item => {
				const values = [item.id,item.name,item.email,item.course_name,item.createdAt];
				if (exportSurvey === 1) {
					const surveyData = JSON.parse(item.data);
					keys.forEach(key => {
						if (!(key in NonSurveyVariables)) {
							if (!(key in surveyData)) values.push('');
							else values.push(surveyData[key]);
						}
					});
				}
				data.push(values);
			});
			setData(data);
		}
	},[app, list, data, exportSurvey]);

	const handleOpenDataBrowser = data => {
		setDataItem(data);
		setDataOpen(true);
	};

	const RenderItem = props => {
		const item = props.item;
		return (
			<Table.Row>
				<Table.Cell>{item.id.substring(2,15)}</Table.Cell>
				<Table.Cell>{item.name}</Table.Cell>
				<Table.Cell>{item.email}</Table.Cell>
				<Table.Cell>{item.course_name}</Table.Cell>
				<Table.Cell collapsing>{item.createdAt}</Table.Cell>
				<Table.Cell collapsing><div className="cf-center">{item.optIn &&<Icon name="check" />}</div></Table.Cell>
				<Table.Cell collapsing><div className="cf-center"><Icon name="eye" onClick={()=>handleOpenDataBrowser(item.data)} link /></div></Table.Cell>
			</Table.Row>
		);
	}

	const updatePageIndex = pageIndex => {
		const itemIndex = (pageIndex - 1) * PageSize;
		setPage(list.slice(itemIndex, itemIndex + PageSize));
		setPageIndex(pageIndex);
	};

	return (
		<>
		<Grid columns={1} padded centered>
			<Grid.Column>
				<SearchBar data={searchData} setData={setSearchData} courseList={courseList} />
				<Failure message={failure} />
				{pageCount > 0 &&
				<>
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>ID</Table.HeaderCell>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Email</Table.HeaderCell>
							<Table.HeaderCell>Course</Table.HeaderCell>
							<Table.HeaderCell>Issued</Table.HeaderCell>
							<Table.HeaderCell>Opt&nbsp;In</Table.HeaderCell>
							<Table.HeaderCell>Survey</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{page.map(item => <RenderItem key={item.id} item={item} />)}
					</Table.Body>
				</Table>
				<Form>
					<Form.Group>
						<Form.Field width={8}>
							{data !== null && 
							<CSVLink className='ui button blue'
							filename="certificates.csv"
							data={data}>Export to CSV</CSVLink>}
							<span>&nbsp;&nbsp;</span>
							<Checkbox label="Export survey data?"
								value={exportSurvey}
								onChange={(e,d)=>{
								setExportSurvey(d.checked?1:0);
								setData(null);
							}} />
						</Form.Field>
						<Form.Field width={8} className="cf-right">
						{pageCount > 1 &&
						<Pagination
							size="tiny"
							activePage={pageIndex}
							totalPages={pageCount}
							boundaryRange={1} siblingRange={4}
							showEllipses={true} firstItem={null} lastItem={null}
							onPageChange={(e,d)=> updatePageIndex(d.activePage)}
						/>}
						</Form.Field>
					</Form.Group>
				</Form>
				</>}
				<UserDataBrowser open={dataOpen} setOpen={setDataOpen} data={dataItem} />
			</Grid.Column>
		</Grid>
		</>
	);
}