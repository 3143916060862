import Gravatar from 'react-gravatar';
import { useNavigate } from 'react-router';
import { Card, Form, Grid } from 'semantic-ui-react';
import { SubscriptionCard } from '../dashboard/Subscription';
import { saveToStore } from '../../lib/hooks';

export default function AccountProfileSection(props) {
	const app = props.app;
	const session = app.session;
	const navigate = useNavigate();
	const navigateToSubscriptionSetting = () => {
		saveToStore('guest/settings/section','subscription');
		navigate('/settings');
	}
	return (
		<Grid
			columns={2}
			centered padded>
			<Grid.Column width={8}>
				<Card fluid>
					<Card.Content>
						<Card.Header>Personal Details</Card.Header>
					</Card.Content>
					<Card.Content>
						<Form>
							<Form.Group>
								<Form.Field width={6} className="cf-center">
									<Gravatar className="cf-avatar" size={100} email={session.mail} />
								</Form.Field>
								<Form.Field width={10}>
									<label>Full Name</label>
									{session.name}<br /><br />
									<label>Email</label>
									{session.mail}<br />
								</Form.Field>
							</Form.Group>
						</Form>
					</Card.Content>
				</Card>
			</Grid.Column>
			<Grid.Column width={4}>
				<SubscriptionCard session={session} onSettings={navigateToSubscriptionSetting} />
			</Grid.Column>
		</Grid>
	);
}