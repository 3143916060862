import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { SketchPicker } from 'react-color';

function hexToRgba(color,alpha) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
	return result ? {
	  r: parseInt(result[1], 16),
	  g: parseInt(result[2], 16),
	  b: parseInt(result[3], 16),
	  a: alpha
	} : null;
  }

export function ColorPicker(props) {
	const setColor = props.setColor;
	const color = hexToRgba(props.color,props.alpha?props.alpha:1);
	const [visible, setVisible] = useState(false);
	const handleClick = () => setVisible(!visible);
	const handleClose = () => setVisible(false);
	const popupStyle = { position: 'absolute', zIndex: 2 };
	const coverStyle = { position:'fixed',top:'0',left:'0',bottom:'0',right:'0' };
	const colorStyle = { backgroundColor: props.color, border: '1px solid gray' };
	return (
		<>
			{visible?
			<div style={popupStyle}>
				<div style={coverStyle} onClick={handleClose} />
				<SketchPicker color={color} onChange={(e)=>setColor(e.hex,e.rgb.a)} /></div>:
			<Button style={colorStyle} content='&nbsp;' onClick={handleClick} fluid />}
		</>
	);
}