import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Grid, Header, Icon } from 'semantic-ui-react';
import { AppContext} from '../app/AppContext';
import { PasswordValidate } from '../lib/passwords';
import { Comment, Failure, Loading, Success } from '../lib/alerts';
import { apiRequestResetPassword, apiResetPassword } from '../lib/api';

export default function ResetPasswordPage(props) {
	const app = useContext(AppContext);
	const [mail			,setMail			] = useState('');
	const [mailSent 	,setMailSent		] = useState(false);
	const [hidePassword	,setHidePassword	] = useState(true);
	const [passwordLevel,setPasswordLevel	] = useState(0);
	const [password		,setPassword		] = useState('');
	const [resetKey		,setResetKey		] = useState('');
	const [valid		,setValid			] = useState(false);
	const [loading		,setLoading			] = useState('');
	const [failure		,setFailure			] = useState('');
	const [success		,setSuccess			] = useState('');

	const handleRequestResetPassword = e => {
		e.preventDefault();
		setFailure('');
		setLoading('Locating your account and sending password reset code ...')
		apiRequestResetPassword(app, mail,
			result => {
				setLoading('');
				if (app.devMode)
					setResetKey(result);
				setMailSent(true);
			},
			result => {
				setLoading('');
				setFailure(result);
			}
		);
		return false;
	};

	const handleResetPassword = e => {
		e.preventDefault();
		setFailure('');
		setLoading('Updating account password ...');
		const data = { password:password,resetKey:resetKey };
		apiResetPassword(app, mail, data,
			result => {
				setLoading('');
				setSuccess(result);
			},
			result => {
				setLoading('');
				setFailure(result);
			}
		);
		return false;
	};

	useEffect(() => setValid(mail !== '' && resetKey !== '' && passwordLevel > 0),[mail,resetKey,passwordLevel]);

	const RenderSuccess = props => {
		if (props.message === '') return <></>;
		return <Success icon="check circle" message={<span>{props.message} You can now sign in&nbsp;<Link className="cf-link" to="/signin">here</Link> with your new password.</span>} />
	};

	const RenderFailure = props => {
		if (props.message === '') return <></>;
		return <Failure icon="times circle" message={props.message} />
	}

	return (
		<Grid columns={1} padded centered>
			<Grid.Column>
				<Header>Reset Account Password</Header><br />
				{success === ''?
				<Form>
					{mailSent?
					<>
						<Form.Field><label>Email</label><div>{mail}</div></Form.Field>
						<Form.Input label="Reset Code" placeHolder="Paste your reset code here ..." value={resetKey} onChange={(e,d) => setResetKey(d.value)} onBlur={() => setResetKey(resetKey.trim())} autoFocus />
						<Form.Input label="New Password" type={hidePassword?'password':'text'} value={password} onChange={(e,d) => setPassword(d.value)} onBlur={() => setPassword(password.trim())} icon={<Icon name={hidePassword?'eye slash':'eye'} onClick={()=>setHidePassword(!hidePassword)} link />}/>
						<Form.Field className="cf-right"><PasswordValidate password={password} level={passwordLevel} setLevel={setPasswordLevel} /></Form.Field>
						<Loading message={loading} />
						<RenderFailure message={failure} />
						{loading === '' && <>
						<Form.Button color="blue" disabled={!valid} onClick={handleResetPassword}>Update Password</Form.Button>
						<Comment icon="info circle" message="Enter the reset code from your email and your new password." />
						<Comment icon="info circle" message={<span>If you did not receive a reset code, click&nbsp;<a className="cf-link" href="/reset/password">here</a> to try again.</span>} /></>}
					</>:
					<>
						<Form.Input label="Email" value={mail} onChange={(e,d) => setMail(d.value)} onBlur={() => setMail(mail.trim())} autoFocus />
						<Loading message={loading} />
						<RenderFailure message={failure} />
						{loading === '' && <>
						<Form.Button color="blue" disabled={loading !== '' || mail === ''} onClick={handleRequestResetPassword}>Get Reset Code</Form.Button>
						<Comment icon="info circle" message="Enter your account email and we will send a password reset code to you." />
						<Comment icon="info circle" message={<span>If you remember your password, you can sign in&nbsp;<Link className="cf-link" to="/signin">here</Link>.</span>} /></>}
					</>}
				</Form>:
				<RenderSuccess message={success} />}
			</Grid.Column>
		</Grid>
	);
}