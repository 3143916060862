import {useContext } from 'react';
import {Grid, Menu } from 'semantic-ui-react';
import {AppContext} from '../app/AppContext';
import { BrandSettingSection } from './settings/Brand';
import { MailerSettingSection } from './settings/Mailer';
import { PasswordSettingSection } from './settings/Password';
import { SubscriptionSettingSection } from './settings/Subscription';
import { useStore } from '../lib/hooks';

export default function SettingsPage(props) {
	const app = useContext(AppContext);
	const [section, setSection] = useStore('guest/settings/section','branding');

	const RenderSection = props => {
		if (section === 'branding'		) return <BrandSettingSection			key="branding"		app={app}	/>;
		if (section === 'mailer'		) return <MailerSettingSection			key="mailer"		app={app}	/>;
		if (section === 'password'		) return <PasswordSettingSection		key="password"		app={app}	/>;
		if (section === 'subscription'	) return <SubscriptionSettingSection	key="subscription"	app={app}	/>;
		return <></>;
	};

	return (
		<>
		<Grid columns={1} centered padded>
			<Grid.Column width={12} textAlign="center">
				<Menu widths={4}>
					<Menu.Item active={section==='branding'		} onClick={()=>setSection('branding')		}>Branding</Menu.Item>
					<Menu.Item active={section==='mailer'		} onClick={()=>setSection('mailer'	)		}>Mailer</Menu.Item>
					<Menu.Item active={section==='password'		} onClick={()=>setSection('password')		}>Password</Menu.Item>
					<Menu.Item active={section==='subscription'	} onClick={()=>setSection('subscription')	}>Subscription</Menu.Item>
				</Menu>
			</Grid.Column>
		</Grid>
		<RenderSection section={section} />
		</>
	);
}