import axios from 'axios';
import { useState } from 'react';
import {Form, Grid } from 'semantic-ui-react';
import { useStore } from '../../lib/hooks';
import {Success,Failure} from '../../lib/alerts';

export function PasswordSettingSection(props) {
	const app = props.app;
	const [password1, setPassword1	] = useStore('guest/setting/password/old','');
	const [password2, setPassword2	] = useStore('guest/setting/password/new','');
	const [success	, setSuccess	] = useState('');
	const [failure	, setFailure	] = useState('');

	const validatePasswordData = () => {
		if (password1.trim().length === 0) setFailure('Old password required.'); else
		if (password2.trim().length === 0) setFailure('New password required.'); else
			return {old_password:password1,new_password:password2};
		return false;
	};

	const handleUpdatePassword = e => {
		e.preventDefault();
		setSuccess('');
		setFailure('');
		const data = validatePasswordData();
		if (data) {
			axios.post(app.apiBase + '/settings/password' ,data).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) setSuccess("Password updated.");
				else setFailure(data.result);
			}).catch(error => console.log(error));
		}
	};

	return (
		<Grid columns={1} padded centered>
			<Grid.Column width={12}>
				<Form>
					<Form.Input type="password" label="Enter old password"		value={password1} onChange={(e,d)=>setPassword1(d.value)} autoFocus />
					<Form.Input type="password" label="Enter new password"		value={password2} onChange={(e,d)=>setPassword2(d.value)} />
					<Form.Button color="blue" onClick={handleUpdatePassword}>Update Password</Form.Button>
				</Form>
				<Success message={success} />
				<Failure message={failure} />
			</Grid.Column>
		</Grid>);
}