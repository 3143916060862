import axios from 'axios';
import moment from 'moment';
import {useCallback, useContext, useEffect,useState} from 'react';
import {Button,Grid,Label,Image as ReactImage} from 'semantic-ui-react';
import {AppContext} from '../app/AppContext';
import {Loading} from './alerts';
import {FontUrls} from './fonturls';

export function CertificateCanvas(props) {
	const {id,preview,downloadText,buttonSize,buttonStyle,inline,upload} = props;
	const app = useContext(AppContext);
	const [baseName	 ,setBaseName  ] = useState('');
	const [courseName,setCourseName] = useState('');
	const [issuedName,setIssuedName] = useState('');
	const [issuedDate,setIssuedDate] = useState('');
	const [imageCache,setImageCache] = useState(null);
	const [fontsCache,setFontsCache] = useState(null);
	const [downloaded,setDownloaded] = useState(0);
	const [imageBytes,setImageBytes] = useState('');
	const [data,setData] = useState(null);

	const downloadImages = useCallback(data => {
		const promises = [];
		const images = {};
		const backgroundImage = new Image();
		backgroundImage.crossOrigin = 'anonymous';
		backgroundImage.src = app.resBase + data.image; images[id] = backgroundImage;
		promises.push(new Promise(resolve => backgroundImage.onload = () => resolve(true)));
		data.items.forEach(item => {
			if (item.type === 0) {
				const image = new Image();
				image.crossOrigin = 'anonymous';
				image.src = app.resBase + item.text; images[item.name] = image;
				promises.push(new Promise(resolve => image.onload = () => resolve(true)));
			}
		});
		Promise.all(promises).then(() => {
			if (app.devMode) console.log('All images downloaded.');
			setImageCache(images);
		});
	},[app,id]);

	const downloadFonts = useCallback(data => {
		const promises = [];
		const fonts = {};
		data.items.forEach(item => {
			if (item.type === 1) {
				const fontName = item.font;
				if (!(fontName in fonts)) {
					const font = new FontFace(fontName , FontUrls[fontName]); fonts[fontName] = font;
					promises.push(new Promise(resolve => font.load().then(font => {
						document.fonts.add(font);
						resolve(true);
					})));
				}
			}
		});
		Promise.all(promises).then(() => {
			if (app.devMode) console.log('All fonts downloaded.');
			setFontsCache(fonts);
		});
	},[app]);

	useEffect(() => {
		if (preview) {
			axios.get(app.apiBase + '/course/' + id).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) {
					const course = data.result;
					setCourseName(course.name);
					setIssuedName(app.session.name);
					setIssuedDate(moment());
					setBaseName(app.session.baseName);
					if (course.certTemplateId !== '') {
						const certData = JSON.parse(course.certData);
						setData(certData);
						downloadImages(certData);
						downloadFonts(certData);
					}
				}
			}).catch(error => console.log(error));
		}
		else {
			axios.get(app.apiBase + '/certificate/' + id).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) {
					const certificate = data.result;
					setCourseName(certificate.courseName);
					setIssuedName(certificate.name);
					setIssuedDate(certificate.issuedAt);
					setBaseName(certificate.baseName);
					const certData = JSON.parse(certificate.certData);
					setData(certData);
					downloadImages(certData);
					downloadFonts(certData);
				}
			}).catch(error => console.log(error));
		}
	},[app,preview,id,downloadImages,downloadFonts]);

	const renderImage = useCallback((context, item) => {
		const image = imageCache[item.name];
		const w = image.width  * item.w;
		const h = image.height * item.h;
		context.drawImage(image, item.x, item.y, w, h);
	},[imageCache]);

	const getCertVerification = useCallback(id => {
		const baseSite = app.devMode ?
			'http://localhost:3000/verify/'	+ baseName :
			'http://portal.accredemy.com/'	+ baseName + '/verify';
		return 'Verify this certificate ID:' + id + ' at ' + baseSite;
	},[app,baseName]);

	const renderField = useCallback((context, item) => {
		var text = '';
		switch (item.data) {
			case 0 : text = item.text;											break;
			case 1 : text = courseName;											break;
			case 2 : text = issuedName;											break;
			case 3 : text = moment(issuedDate).format(item.text);				break;
			case 4 : text = getCertVerification(id.substring(2,15));			break;
			default: break;
		}
		context.fillStyle	= item.c;
		context.font		= item.b + ' ' + item.z + 'px ' + item.font;
		context.fillText(text, item.x, parseInt(item.y) + parseInt(item.z));
	},[id,courseName,issuedName,issuedDate,getCertVerification]);

	const renderCertificate = useCallback((canvas, scale, data) => {
		const scaleFactor = scale /100.0;
		canvas.width	= data.w * scaleFactor;
		canvas.height	= data.h * scaleFactor;
		const backgroundImage = imageCache[id];
		const ctx = canvas.getContext('2d');
		ctx.scale(scaleFactor, scaleFactor);
		ctx.drawImage(backgroundImage, 0,0);
		data.items.forEach(item => {
			switch(item.type) {
				case 0 : renderImage(ctx, item); break;
				case 1 : renderField(ctx, item); break;
				default: break;
			}
		});
	}
	,[id,imageCache,renderField,renderImage]);

	useEffect(() => {
		if (data && imageCache && fontsCache) {
			const canvas = document.createElement('canvas');
			renderCertificate(canvas, 100, data);
			const imageBytes = canvas.toDataURL('image/jpeg');
			if (upload) {
				const postData = { id:id, image:imageBytes};
				axios.post(app.apiBase + '/certificate', postData);
			}
			setImageBytes(imageBytes);
		}
	},[app,id,upload,data,imageCache,fontsCache,renderCertificate]);

	const downloadCertificate = () => {
		setDownloaded(1);
		const imageURI = imageBytes.replace('image/jpeg','image/octet-stream');
		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: false,
			cancelable: true
		});
		var a = document.createElement('a');
		a.setAttribute('download', 'certificate.jpg');
		a.setAttribute('target', '_blank');
		a.setAttribute('href', imageURI);
		a.dispatchEvent(clickEvt);
		setDownloaded(2);
	};

	const CertificateImage = props => {
		return <ReactImage src={imageBytes} />
	};

	if (!data	) return <></>;
	if (inline	) return <CertificateImage />;

	return (
		<Grid centered columns={1}>
			<Grid.Row>
				<Grid.Column width={4}>
					<CertificateImage />
				</Grid.Column>
			</Grid.Row>
			{downloadText &&
			<Grid.Row>
				<Grid.Column width={12}>
					<div style={{textAlign:'center'}}>
						{downloaded===0&&<Button size={buttonSize} style={buttonStyle} onClick={downloadCertificate}>{downloadText}</Button>}
						{downloaded===1&&<Loading message="Currently downloading certificate..." />}
						{downloaded===2&&<Label>Certificate downloaded</Label>}
					</div>
				</Grid.Column>
			</Grid.Row>}
		</Grid>
	)
}