import { Button, Dimmer, Icon, Message, Modal } from 'semantic-ui-react';

export const CommentIcon = <Icon name="circle info"					/>;
export const WarningIcon = <Icon name="circle exclamation"			/>;
export const SuccessIcon = <Icon name="circle check"				/>;
export const FailureIcon = <Icon name="circle times"				/>;
export const LoadingIcon = <Icon name="circle notched"		loading	/>;

export const Comment = props => props.message && <Message color="blue"		size={props.size} icon={props.icon && CommentIcon} header={props.caption} content={props.message} />;
export const Warning = props => props.message && <Message color="orange"	size={props.size} icon={props.icon && WarningIcon} header={props.caption} content={props.message} />;
export const Success = props => props.message && <Message color="green"		size={props.size} icon={props.icon && SuccessIcon} header={props.caption} content={props.message} />;
export const Failure = props => props.message && <Message color="red"		size={props.size} icon={props.icon && FailureIcon} header={props.caption} content={props.message} />;
export const Loading = props => props.message && <Message color="yellow"	size={props.size} icon={props.icon && LoadingIcon} header={props.caption} content={props.message} />;
export const Posting = props => props.message && <Dimmer active page><Loading size={props.size} icon={props.icon} caption={props.caption} message={props.message}/></Dimmer>;

export function Confirm(props) {
	const { open , setOpen, onConfirm} = props;
	const handleClose 	= () => setOpen(false);
	const handleConfirm = () => {
		handleClose	();
		onConfirm	(); 
	};
	return (
		<Modal
			size="small"
			open={open}
			onClose={handleClose}
			closeOnDimmerClick={false}
			centered>
			<Modal.Header >{props.caption}</Modal.Header >
			<Modal.Content>{props.message}</Modal.Content>
			<Modal.Actions>
				<Button positive onClick={handleConfirm	}>Confirm</Button>
				<Button negative onClick={handleClose	}>Cancel </Button>
			</Modal.Actions>
		</Modal>
	);
}