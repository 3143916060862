import { Grid, Header, Image, Menu } from 'semantic-ui-react';
import { Comment } from '../lib/alerts';
import { BrandSettingSection } from './settings/Brand';
import { apiSignOut } from '../lib/api';
import SiteLogo from '../lib/images/main-logo.png';
import { saveToStore } from '../lib/hooks';

export default function BrandingPage(props) {
	const app = props.app;
	const handleSignOut = () => apiSignOut(app);
	const handleUpdated = () => {
		saveToStore('guest/settings/section','branding');
		const session = {...app.session};
		app.setSession(session);
	};
	return (
		<div className="cf-guest-page">
			<div className="cf-guest-pane-side">
				<Image className="cf-guest-page-logo" src={SiteLogo} size="small" centered />
					<Menu className="cf-guest-page-menu" secondary vertical>
						<Menu.Item active={true} link><Header>Branding</Header></Menu.Item>
						<Menu.Item onClick={handleSignOut}><Header size="medium">Sign Out</Header></Menu.Item>
				</Menu>
			</div>
			<div className="cf-guest-pane-main">
				<Grid centered padded>
					<Grid.Row>
						<Grid.Column width={12}>
							<div>
								<Header size="huge" className="cf-center">WELCOME TO CERTFUNNEL</Header>
								<Comment icon="pencil" message={<span>We're excited you've chosen to use Certfunnel.<br />You can begin by setting up your brand and other details below.<br />Provide at least your brand name and ID, the other details can be setup later.</span>} />
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<BrandSettingSection app={app} onUpdated={handleUpdated} />
			</div>
		</div>
	);
}