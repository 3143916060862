import moment from 'moment';
import { useEffect, useState } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { DateFormat, DatePickerFormat, DateModeOptions } from './dates';
import 'react-datepicker/dist/react-datepicker.css';

export function SearchData() {
	return {
		courseId:''	,
		dateMode:0	,
		dateFrom:moment().format(DateFormat),
		dateTo	:moment().format(DateFormat)
	};
}

export function SearchBar(props) {
	const {data,setData,courseList} = props;
	const [courseId ,setCourseId] = useState('');
	const [dateMode	,setDateMode] = useState(0 );
	const [dateFrom	,setDateFrom] = useState('');
	const [dateTo	,setDateTo	] = useState('');

	useEffect(() => {
		setCourseId(data.courseId);
		setDateMode(data.dateMode);
		setDateFrom(data.dateFrom);
		setDateTo(data.dateTo);
	},[data]);

	const updateCourseId = value => {
		const courseId = value ? value : '';
		setCourseId(courseId); setData({...data, courseId:courseId});
	};

	const updateDateMode = value => {
		const dateMode = parseInt(value);
		setDateMode(dateMode); setData({ ...data, dateMode:dateMode });
	};

	const updateDateFrom = value => {
		const dateFrom = moment(value).format(DateFormat);
		setDateFrom(dateFrom); setData({ ...data, dateFrom:dateFrom });
	};

	const updateDateTo = value => {
		const dateTo = moment(value).format(DateFormat);
		setDateTo(dateTo); setData({ ...data, dateTo:dateTo });
	};
	
	return (
		<Form>
			<Form.Group>
				<Form.Field width={6}>
					<Dropdown placeholder='Select Course' 
						options={courseList} value={courseId}
						onChange={(e,d)=>updateCourseId(d.value)}
						clearable selection fluid />
				</Form.Field>
				<Form.Field width={4}><Dropdown placeholder='Select Date' options={DateModeOptions} value={dateMode + ''} onChange={(e,d)=>updateDateMode(d.value)} selection fluid /></Form.Field>
				{dateMode === 4 && <Form.Field width={3}><DatePicker dateFormat={DatePickerFormat} selected={new Date(dateFrom)} onChange={date => updateDateFrom(date)} /></Form.Field>}
				{dateMode === 4 && <Form.Field width={3}><DatePicker dateFormat={DatePickerFormat} selected={new Date(dateTo)} onChange={date => updateDateTo(date)} /></Form.Field>}
			</Form.Group>
		</Form>
	);
}