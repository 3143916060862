import { createContext } from 'react';

export const AppContext = createContext(null);

export function getAppHost() {
	const hostName = window.location.hostname;
	if (hostName.endsWith('localhost'			)) return 1;
	if (hostName.endsWith('certfunnel.com'		)) return 1;
	if (hostName.endsWith('claimcertificate.com')) return 2;
	if (hostName.endsWith('accredemy.com'		)) return 3;
	return 0;
}

export function getApp(devMode) {
	const appHost = getAppHost();
	switch (appHost) {
		case 1 :
			return {
				version: '6.0.0',
				appName: 'Certificate Funnel',
				appBase: devMode ? 'http://localhost:3000'					: 'https://app.certfunnel.com'			,
				resBase: devMode ? 'http://localhost:3006/public'			: 'https://app.certfunnel.com'			,
				apiBase: devMode ? 'http://localhost:3006/public/api.php'	: 'https://app.certfunnel.com/api.php'	,
				pubBase: devMode ? 'http://localhost:3000'					: 'https://claimcertificate.com'		,
				usrBase: devMode ? 'http://localhost:3000'					: 'https://portal.accredemy.com'		,
				appHost: appHost,
				devMode: devMode,
			};
		case 2 :
			return {
				version: '6.0.0',
				appName: 'Claim Certificate',
				appBase: devMode ? 'http://localhost:3000'					: 'https://claimcertifcate.com'			,
				resBase: devMode ? 'http://localhost:3006/public'			: 'https://app.certfunnel.com'			,
				apiBase: devMode ? 'http://localhost:3006/public/api.php'	: 'https://claimcertificate.com/api.php',
				pubBase: devMode ? 'http://localhost:3000'					: 'https://claimcertificate.com'		,
				usrBase: devMode ? 'http://localhost:3000'					: 'https://portal.accredemy.com'		,
				appHost: appHost,
				devMode: devMode,
			};
		case 3 :
			return {
				version: '6.0.0',
				appName: 'Accredemy Portal',
				appBase: devMode ? 'http://localhost:3000'					: 'https://portal.accredemy.com'		,
				resBase: devMode ? 'http://localhost:3006/public'			: 'https://app.certfunnel.com'			,
				apiBase: devMode ? 'http://localhost:3006/public/api.php'	: 'https://portal.accredemy.com/api.php',
				pubBase: devMode ? 'http://localhost:3000'					: 'https://claimcertificate.com'		,
				usrBase: devMode ? 'http://localhost:3000'					: 'https://portal.accredemy.com'		,
				appHost: appHost,
				devMode: devMode,
			};
		default:
			console.log('Invalid application host.');
			return {
				version: '',
				appName: '',
				appBase: '',
				resBase: '',
				apiBase: '',
				pubBase: '',
				usrBase: '',
				appHost: appHost,
				devMode: devMode,
			};
	}
}