import {useRef, useState} from 'react';
import {Form, Icon, Label, Modal} from 'semantic-ui-react';

export function excludeTags(list1, list2) {
	return list1.filter(tag => !list2.includes(tag));
}

export function TagList(props) {
	if (props.tags === '') return <></>;
	return props.tags.split(',').map(item => <Label key={item}>{item}</Label>);
}

export function TagSelector(props) {
	const [open, setOpen] = useState(false);
	const color = props.color || 'grey';
	const title = props.title || 'Select Tags';
	const remove = props.remove;
	const source = props.source;
	const target = props.target;
	const setSource = props.setSource;
	const setTarget = props.setTarget;

	if (source === null || target === null) return <></>;

	const appendTag = item => {
		const removed = source.filter(tag => tag !== item);
		setTarget([...target, item]); setSource(removed);
		if (removed.length === 0) setOpen(false);
	};
	const removeTag = item => {
		setTarget(target.filter(tag => tag !== item));
		setSource([...source, item].sort());
	};

	const TagItemRenderer = props => {
		const icon = remove ? 'delete' : '';
		const func = remove ? removeTag: appendTag;
		return <Label style={{cursor:'pointer'}} onClick={()=>func(props.value)} color={color}>
			{props.value}<Icon name={icon} size='small'></Icon>
		</Label>;
	};
	const TagListRenderer = props => {
		const list = remove ? target : source;
		return list.map(item => <TagItemRenderer key={item}
			value={item} />);
	};

	if (remove) return <TagListRenderer remove />;
	return (
		<Modal open={open}
			trigger={<Icon color={color} name="plus circle" />}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			size="small" closeIcon centered>
			<Modal.Header>{title}</Modal.Header>
			<Modal.Content>
				<TagListRenderer />
			</Modal.Content>
		</Modal>
	);
}

export function TagEditor(props) {
	const {tags,setTags} = props;
	const [text,setText] = useState('');
	const [valid,setValid] = useState(0);
	const inputRef = useRef(null);

	const validateText = text => {
		setValid(text !== '' && !tags.includes(text));
		setText(text);
	};

	const appendTag = text => {
		if (valid) {
			const items = [text, ...tags].sort();
			validateText('');setTags(items);
		}
		inputRef.current.focus();
	};

	const removeTag = text => {
		if (tags.includes(text)) {
			const items = tags.filter(item => item !== text);
			setTags(items);
		}
		inputRef.current.focus();
	};

	const handleEnter = event => {
		if (event.keyCode === 13) {
			event.preventDefault();
			appendTag(text);
		}
	};

	const TagItemRenderer = props => {
		return <Label style={{margin:'4px'}} as="a" onClick={()=>removeTag(props.value)}>
			{props.value}<Icon name="delete" size="small"></Icon>
		</Label>;
	};

	const TagListRenderer = props => {
		if (tags === null) return <></>;
		return tags.map(item => <TagItemRenderer key={item} value={item} />);
	};

	return (
		<Form.Group>
			<Form.Field width={4}>
				<input className="ui fluid" ref={inputRef} type="text" onKeyDown={handleEnter}
					value={text} onChange={e => validateText(e.target.value)} />
			</Form.Field>
			<Form.Field width={12}>
				<>
					{text !== '' &&
					<Label style={{margin:'4px'}} as="a" onClick={()=>appendTag(text)}
						color={valid?'green':'red'}>{text}
						{valid && <>&nbsp;&nbsp;<Icon name="plus" size="small"></Icon></>}
					</Label>}
					<TagListRenderer />
				</>
			</Form.Field>
		</Form.Group>
	);
}

export function MailerTagEditor(props) {
	const {tags,setTags,list,setList} = props;

	const removeTag = text => {
		setTags(tags.filter(item => item !== text));
		setList([text, ...list].sort());
	};

	const TagItemRenderer = props => {
		return <Label style={{marginRight:'4px',marginTop:'-2px'}} as="a" onClick={()=>removeTag(props.value)}>
			{props.value}<Icon name="delete" size="small"></Icon>
		</Label>;
	};

	const TagListRenderer = props => {
		if (tags === null || list === null) return <></>;
		return tags.map(item => <TagItemRenderer key={item} value={item} />);
	};

	return (
		<Form.Group>
			<TagSelector
				source={list} setSource={setList}
				target={tags} setTarget={setTags} />
			<TagListRenderer />
		</Form.Group>
	);
}