import axios from 'axios';

const apiPostFileConfiguration = {headers:{'Content-Type':'multipart/form-data'}};

export function apiGet(app, request, success, failure) {
	const endpoint = app.apiBase + request;
	if (app.devMode) console.log('GET', endpoint);
	axios.get(endpoint).then(response => {
		const data = response.data;
		if (app.devMode) console.log(request, data);
		if (data.status) success(data.result);
		else if(failure) failure(data.result);
	}).catch(error => console.log(error));
}

export function apiPut(app, request, payload, success, failure) {
	const endpoint = app.apiBase + request;
	if (app.devMode) console.log('PUT', endpoint, payload);
	axios.put(endpoint, payload).then(response => {
		const data = response.data;
		if (app.devMode) console.log(request, data);
		if (data.status) success(data.result);
		else if(failure) failure(data.result);
	}).catch(error => console.log(error));
}

export function apiPost(app, request, payload, success, failure) {
	const endpoint = app.apiBase + request;
	if (app.devMode) console.log('POST', endpoint, payload);
	axios.post(endpoint, payload).then(response => {
		const data = response.data;
		if (app.devMode) console.log(request, data);
		if (data.status) success(data.result);
		else if(failure) failure(data.result);
	}).catch(error => console.log(error));
}

export function apiPostFile(app, request, payload, success, failure) {
	const endpoint = app.apiBase + request;
	if (app.devMode) console.log('POST', endpoint, payload);
	axios.post(endpoint, payload, apiPostFileConfiguration).then(response => {
		const data = response.data;
		if (app.devMode) console.log(request, data);
		if (data.status) success(data.result);
		else if(failure) failure(data.result);
	}).catch(error => console.log(error));
}

export function apiPatch(app, request, payload, success, failure) {
	const endpoint = app.apiBase + request;
	if (app.devMode) console.log('PATCH', endpoint, payload);
	axios.patch(endpoint, payload).then(response => {
		const data = response.data;
		if (app.devMode) console.log(request, data);
		if (data.status) success(data.result);
		else if(failure) failure(data.result);
	}).catch(error => console.log(error));
}

export function apiDelete(app, request, success, failure) {
	const endpoint = app.apiBase + request;
	if (app.devMode) console.log('DELETE', endpoint);
	axios.delete(endpoint).then(response => {
		const data = response.data;
		if (app.devMode) console.log(request, data);
		if (data.status) success(data.result);
		else if(failure) failure(data.result);
	}).catch(error => console.log(error));
}

export const apiGetSession = (app, success, failure) => apiGet(app, '/session', success, failure);
export const apiSignUp = (app, data, success, failure) => apiPost(app ,'/session/signup', data, success, failure);
export const apiSignIn = (app, data, success, failure) => apiPost(app ,'/session/signin', data, success, failure);
export const apiSignOut = (app, success, failure) => apiGet(app, '/session/signout', success || ((result) => app.setSession(result)), failure);
export const apiActivateAccount = (app, key, success, failure) => apiGet(app, '/session/activate/' + key, success, failure);
export const apiRequestResetPassword = (app, mail, success, failure) => apiGet(app, '/session/resetpassword/' + mail, success, failure);
export const apiResetPassword = (app, mail, data, success, failure) => apiPost(app, '/session/resetpassword/' + mail, data, success, failure);
export const apiImpersonateUser = (app, id, success, failure) => apiGet(app, '/session/impersonate/' + id, success, failure);
export const apiSearchUsers = (app, data, success, failure) => apiPost(app, '/search/users', data, success, failure);
export const apiInsertUser = (app, data, success, failure) => apiPost(app, '/users', data, success, failure);
export const apiUpdateUserStatus = (app, data, success, failure) => apiPatch(app, '/users/status', data, success, failure);
export const apiUpdateUserBranding = (app, data, success, failure) => apiPatch(app, '/users/branding', data, success, failure);
export const apiDeleteUser	= (app, id, success, failure) => apiDelete(app, '/users/' + id, success, failure);
export const apiGetCourses = (app, success, failure) => apiGet(app, '/courses', success, failure);
export const apiGetBitmaps	= (app, success, failure) => apiGet(app, '/bitmaps', success, failure);
export const apiGetCertTemplates = (app, success, failure) => apiGet(app, '/certtemplates', success, failure);
export const apiGetSiteTemplates = (app, success, failure) => apiGet(app, '/sitetemplates', success, failure);
export const apiGetMailingGroups = (app, success, failure) => apiGet(app, '/mailer/groups', success, failure);
export const apiGetMailingTags = (app, success, failure) => apiGet(app, '/mailer/tags', success, failure);
export const apiUploadBitmap = (app, data, success, failure) => apiPostFile(app, '/bitmaps', data, success, failure);
export const apiDeleteBitmap = (app, id, success, failure) => apiDelete(app, '/bitmaps/' + id, success, failure);
export const apiGetCourseList = (app, success, failure) => apiGet(app, '/courses/list', success, failure);
export const apiSearchGuestCounters = (app, data, success, failure) => apiPost(app, '/search/guestcounters', data, success, failure);
export const apiSearchAdminCounters = (app, data, success, failure) => apiPost(app, '/search/admincounters', data, success, failure);
export const apiGetPaymentLinks = (app, success, failure) => apiGet(app, '/payment/links', success, failure);
export const apiGetPaymentPortal = (app, success, failure) => apiGet(app, '/payment/portal', success, failure);
export const apiGetPaymentDetails = (app, data, success, failure) => apiGet(app, '/payment/details/' + data, success, failure);
export const apiGetPaymentList = (app, id, success, failure) => apiGet(app, '/payment/list/' + id, success, failure);
export const apiGetCourseByCustomLink = (app, baseName, linkPath, success, failure) => apiGet(app, `/course?linkBase=${baseName}&linkPath=${linkPath}`,success,failure);
export const apiGetCourse = (app, id, success, failure) => apiGet(app, '/course/' + id, success, failure);
export const apiUpdateCourseStatus = (app, id, status, success, failure) => apiPatch(app, '/course/status/' + id,{status:status}, success, failure);
export const apiDuplicateCourse = (app, id, success, failure) => apiGet(app, '/course/duplicate/' + id, success, failure);
export const apiDeleteCourse = (app, id, success, failure) => apiDelete(app, '/course/' + id, success, failure);
export const apiSearchLogs = (app, data, success, failure) => apiPost(app, '/search/logs', data, success, failure);
export const apiDeleteLogs = (app, success, failure) => apiDelete(app, '/logs', success, failure);
export const apiDeleteLog = (app, id, success, failure) => apiDelete(app, '/logs/' + id, success, failure);
export const apiGetLatestLog = (app, success, failure) => apiGet(app, '/logs/latest', success, failure);