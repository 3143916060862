import uuid from 'react-uuid';
import {Helmet} from 'react-helmet';
import {useContext, useEffect, useState} from 'react';
import {Button, Checkbox,Form, Grid, Header, Icon, Input, Label, Image, Menu, Message, Popup, Radio, Rating, Segment, Select, Modal, Table} from 'semantic-ui-react';
import {FacebookShareButton,LinkedinShareButton,TwitterShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";
import {SiteTemplateSelector} from './SiteTemplateSelector';
import {ImageSelector} from '../../lib/images';
import {ColorPicker} from '../../lib/colors';
import {FontSelector} from '../../lib/fonts';
import {CourseActionBar} from './CourseActionBar';
import {AppContext} from '../../app/AppContext';

const NoQuestionWarningText = `
	You do not have any questions in your survey.
	If your survey is enabled, you should have at
	least one question.`;

const ExamplePrivacyPolicyText = `
[header]Generic Privacy Policy Template[/header]
[p]
This privacy policy ("policy") will help you understand how [name] ("us", "we", "our") uses and protects the data you provide to us when you visit and use [website] ("website", "service").
We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.
[/p]
[header]What User Data We Collect[/header]
[p]
When you visit the website, we may collect the following data:
•	Your IP address.
•	Your contact information and email address.
•	Other information such as interests and preferences.
•	Data profile regarding your online behavior on our website.
[/p]
[header]Why We Collect Your Data[/header]
[p]
We are collecting your data for several reasons:
•	To better understand your needs.
•	To improve our services and products.
•	To send you promotional emails containing the information we think you will find interesting.
•	To contact you to fill out surveys and participate in other types of market research.
•	To customize our website according to your online behavior and personal preferences.
[/p]
[header]Safeguarding and Securing the Data[/header]
[p][name] is committed to securing your data and keeping it confidential. [name] has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.[p]
[header]Our Cookie Policy[/header]
[p]
Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you spend the most time on, and websites you visit).
The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.
Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.
If you want to disable cookies, you can do it by accessing the settings of your internet browser. (Provide links for cookie settings for major internet browsers).
[/p]
[header]Links to Other Websites[/header]
[p]
Our website contains links that lead to other websites. If you click on these links [name] is not held responsible for your data and privacy protection. Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website.
[/p]
[header]Restricting the Collection of your Personal Data[/header]
[p]
At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following:
When you are filling the forms on the website, make sure to check if there is a box which you can leave unchecked, if you don't want to disclose your personal information.
If you have already agreed to share your information with us, feel free to contact us via email and we will be more than happy to change this for you.
[name] will not lease, sell or distribute your personal information to any third parties, unless we have your permission. We might do so if the law forces us. Your personal information will be used when we need to send you promotional materials if you agree to this privacy policy.
Create your own professional privacy policy tailored to your website or app.
[/p]
`;

const ExamplePrivacyPolicyLink = "https://jellyhound.co.uk/privacy-policy/";

const DefaultData = [
[
	{type:90,name:'Welcome Intro',intro_head:'@course_name',intro_body:'Congratulations for completing the @course_name course. We need to gather some information to generate a personalized certificate for you. Click the button below to begin.',intro_next:'Start',visible:2},
	{type:0,name:'First Name',prompt:'What is your first name?',value:'',length:'l',required:2,visible:2},
	{type:0,name:'Last Name',prompt:'What is your last name?',value:'', length:'l',required:2,visible:2},
	{type:0,name:'Email',prompt:'What is your email address?',value:'',length:'l',required:2,visible:2},
	{type:1,name:'OptIn',prompt:'Can we subscribe you to our newsletter?',value:0,required:2,visible:2},
	{type:91,name:'Privacy Policy',policy_head:'Please confirm that you have read and agree to our [privacy_policy].',policy_next:'Confirm',mode:0,text:ExamplePrivacyPolicyText,link:ExamplePrivacyPolicyLink,required:2,visible:2},
],[],
[
	{type:98,name:'Content & Buttons',
		final_head:'Your Certificate Is Ready',
		final_body:'We are delighted that you have completed one of our courses. We aim to help professionals get ahead in their careers. If you have the aspiration, please check out all our other courses. Are you ready to go further?',
		buttonText1:'Download Certificate',
		buttonText2:'Sign Me Up',
		buttonLink2:'https://www.youtube.com/watch?v=dQw4w9WgXcQ',visible:2},
	{type:99,name:'Social Share Buttons',
		text0:'Share your achievement on',
		text1:'I\'ve completed the @course_name course.',
		text2:'I\'ve completed the @course_name course.',
		text3:'I\'ve completed the @course_name course.',
		enabled1:1,
		enabled2:1,
		enabled3:1,
		visible:2},
	],
	{
		hasSurvey : 0,
		backColor : '#ffffff',
		textColor : '#000000',
		inputColor: '#000000',
		brandColor: '#000000',
		buttonBackColor : '#cccccc',
		buttonTextColor : '#777777',
		headerFont: 'Lato',
		commonFont: 'Lato',
		buttonFont: 'Lato',
		headerSize: 'medium',
		buttonSize: 'medium',
		backButtonText : 'Back',
		nextButtonText : 'Next',
		submitSurveyText : 'Submit Survey',
		headerImage : '/images/welcome.jpg',
		headerFluid : 0,
	},
];

const SizesToEm = {
	huge: '2em',
	large: '1.71428571em',
	medium:'1.28571429em',
	small:'1.07142857em'
};

const LengthOptions = [
	{ key:'l', value:'l', text:'Short Answer'	},
	{ key:'p', value:'p', text:'Paragraph'		},
];

const QuestionTypeOptions = [
{ key: "2", value:"2", text:"Single line of text"},
{ key: "3", value:"3", text:"Yes or no"},
{ key: "8", value:"8", text:"Multiple choices"},
{ key: "9", value:"9", text:"Rating"}
];

export default function FunnelDesignerPage(props) {
	const ApplyBranding = data => {
		const defaultStyle = data[3];
		defaultStyle.headerFont = branding.headFont;
		defaultStyle.commonFont = branding.bodyFont;
		defaultStyle.buttonFont = branding.bodyFont;
		return data;
	};

	const app = useContext(AppContext);
	const branding = JSON.parse(app.session.brandData);
	const [newQuestion,setNewQuestion] = useState(false);
	const [refreshImage,setRefreshImage] = useState(true );
	const [refreshLinks,setRefreshLinks] = useState(false);
	const [preview,setPreview] = useState(false);
	const [styling,setStyling] = useState(false);
	const [siteTemplateId, setSiteTemplateId] = useState('');
	const [data,setData] = useState(ApplyBranding(DefaultData));
	const [selectedPage,setSelectedPage] = useState(0);
	const [selectedField,setSelectedField] = useState(null);
	const [selectedStyle,setSelectedStyle] = useState(null);
	const [selectedIndex,setSelectedIndex] = useState(-1);
	const [lastIndex,setLastIndex] = useState(-1);
	const [backColor,setBackColor] = useState('#ffffff');
	const [textColor,setTextColor] = useState('#000000');
	const [inputColor,setInputColor] = useState('#000000');
	const [brandColor,setBrandColor] = useState('#000000');
	const [buttonBackColor,setButtonBackColor] = useState('#cccccc');
	const [buttonTextColor,setButtonTextColor] = useState('#777777');
	const [headerFont,setHeaderFont] = useState('Lato');
	const [commonFont,setCommonFont] = useState('Lato');
	const [buttonFont,setButtonFont] = useState('Lato');
	const [headerSize,setHeaderSize] = useState('medium');
	const [buttonSize,setButtonSize] = useState('medium');
	const [backButtonText,setBackButtonText] = useState('Back');
	const [nextButtonText,setNextButtonText] = useState('Next');
	const [hasSurvey,setHasSurvey] = useState(1);
	const [submitSurveyText,setSubmitSurveyText] = useState('Submit Survey');
	const [headerImage, setHeaderImage] = useState('/images/welcome.jpg');
	const [headerFluid, setHeaderFluid] = useState(0);
	const [variables] = useState({});

	const [imageOpen,setImageOpen] = useState(false);

	const getFileExtension = path => {
		return path.split('.').pop();
	}

	const getVarName = name => {
		return name.replace(/(\s|-)/g,"_").toLowerCase();
	}

	const setVar = (name, value) => {
		variables[getVarName(name)] = value;
	}

	const getVar = (name, value) => {
		const var_name = getVarName(name);
		if (var_name in variables) return variables[var_name];
		variables[var_name] = value;
		return value;
	}

	const replaceVars = text => {
		text = text.replace('@course_name'	, getVar('course_name'	,''));
		text = text.replace('@first_name'	, getVar('first_name'	,''));
		text = text.replace('@last_name'	, getVar('last_name'	,''));
		text = text.replace('@email'		, getVar('email'		,''));
		return text;
	};

	useEffect(() => {
		if (app.course !== null) {
			const course = app.course;
			variables['course_name'] = course.name;
			setSiteTemplateId(course.siteTemplateId);
			if (course.siteData && course.siteData !== '{}') {
				const siteData = JSON.parse(course.siteData);
				setData(siteData);
			}
		}
	},[app.course, variables]);

	useEffect(() => {
		setSelectedPage(0);
		setSelectedIndex(0);
		setSelectedField(data[0][0]);
		setLastIndex(data[0].length - 1);
		setSelectedStyle(data[3]);
	},[data]);

	useEffect(() => {
		if (selectedStyle) {
			setHasSurvey(selectedStyle.hasSurvey);
			setBackColor(selectedStyle.backColor);
			setTextColor(selectedStyle.textColor);
			setBrandColor(selectedStyle.brandColor);
			setInputColor(selectedStyle.inputColor);
			setButtonBackColor(selectedStyle.buttonBackColor);
			setButtonTextColor(selectedStyle.buttonTextColor);
			setHeaderFont(selectedStyle.headerFont);
			setCommonFont(selectedStyle.commonFont);
			setButtonFont(selectedStyle.buttonFont);
			setBackButtonText(selectedStyle.backButtonText);
			setNextButtonText(selectedStyle.nextButtonText);
			setHeaderSize(selectedStyle.headerSize);
			setButtonSize(selectedStyle.buttonSize);
			setSubmitSurveyText(selectedStyle.submitSurveyText);
			setHeaderImage(selectedStyle.headerImage);
			setHeaderFluid(selectedStyle.headerFluid);
		}
	},[selectedStyle]);

	const handleUpdate = () => {
		if (newQuestion) {
		//	Removed an incomplete survey question
			data[1] = data[1].filter(x => x.type !== -1);
		}
		const siteData = [
			data[0],
			data[1],
			data[2],
			{
				hasSurvey			: hasSurvey,
				backColor			: backColor,
				textColor			: textColor,
				brandColor			: brandColor,
				inputColor			: inputColor,
				buttonBackColor 	: buttonBackColor,
				buttonTextColor 	: buttonTextColor,
				headerFont			: headerFont,
				commonFont			: commonFont,
				buttonFont			: buttonFont,
				headerSize			: headerSize,
				buttonSize			: buttonSize,
				backButtonText		: backButtonText,
				nextButtonText		: nextButtonText,
				submitSurveyText	: submitSurveyText,
				headerImage			: headerImage,
				headerFluid			: headerFluid
			}
		];
		const course = Object.assign({}, app.course);
		course.siteData = JSON.stringify(siteData);
		course.save = true;
		app.setCourse(course);
		if (app.devMode) {
			console.log(course);
			console.log('Course funnel updated.');
		}
		return course;
	};

	const selectPage = page => {
		setStyling(false);
		if (page !== selectedPage) {
			const lastIndex = data[page].length - 1;
			const index = (lastIndex >= 0 ? 0 : -1);
			setSelectedPage(page);
			setLastIndex(lastIndex);
			setSelectedIndex(index);
			setSelectedField(index < 0 ? null: data[page][index]);
		}
	};

	const selectItem = item => {
		const items = data[selectedPage];
		const index = items.indexOf(item);
		setSelectedIndex(index);
		setLastIndex(data[selectedPage].length - 1);
		setSelectedField(item);
	};

	const selectLastItem = () => {
		const items = data[selectedPage];
		const index = items.length - 1;
		selectItem(items[index]);
	}

	const gotoNextItem = () => {
		const items = data[selectedPage];
		var index = selectedIndex;
		while (index < lastIndex) {
			var next_item = items[++index];
			if (next_item.visible !== 0) {
				selectItem(next_item);
				break;
			}
		}
	};

	const gotoPrevItem = () => {
		const items = data[selectedPage];
		var index = selectedIndex;
		while (index > 0) {
			var next_item = items[--index];
			if (next_item.visible !== 0) {
				selectItem(next_item);
				break;
			}
		}
	};

	const RenderBranding = () => {
		if (branding === null) return <></>;
		return (
			<>
			<Helmet>
				{branding.brandName !== '' && <title>{branding.brandName}</title>}
				{branding.siteIcon	!== '' && <link rel="icon" type={'image/' + getFileExtension(branding.siteIcon)} href={app.resBase + branding.siteIcon} />}
			</Helmet>
			</>
		);
	}

	const RenderImage = () => {
		return (
			<>
			{!preview && <RenderBranding />}
			{headerFluid===0&&<div style={{height:'1rem'}}></div>}
			{headerImage !== null && <><Image src={headerImage} centered={headerFluid===0} fluid={headerFluid===1} /><br /></>}
			</>
		);
	};

	const RenderButton = props => {
		return <Button disabled={props.disabled} size={buttonSize} style={{backgroundColor:buttonBackColor,color:buttonTextColor,fontFamily:buttonFont}} onClick={props.onClick}>{props.text}</Button>
	};

	const RenderBackButton = props => {
		const text = props.text || backButtonText;
		return <RenderButton disabled={props.disabled} onClick={gotoPrevItem} text={text} />
	};

	const RenderNextButton = props => {
		const text = props.text || nextButtonText;
		return <RenderButton disabled={props.disabled} onClick={gotoNextItem} text={text} />
	}

	const RenderSurveyButtons = props => {
		const condition = props.condition !== undefined?props.condition:true;
		return (
			<>
				{selectedIndex > 0 && <RenderBackButton /> }
				{selectedIndex < lastIndex && <RenderNextButton disabled={!condition} /> }
				{selectedIndex === lastIndex && <RenderButton disabled={!condition} onClick={()=>selectPage(2)} text={submitSurveyText} />}
			</>
		)
	};

	const RenderWelcomeIntro = props => {
		return (
			<>
				<RenderImage />
				<Grid columns={1} padded centered>
					<Grid.Column width={12}>
						<Header size={headerSize} textAlign="center" style={{padding:'1rem',color:textColor,fontFamily:headerFont}}>{replaceVars(selectedField.intro_head)}</Header>
						<p textAlign="center" style={{padding:'1rem',color:textColor,fontFamily:commonFont,fontSize:SizesToEm[buttonSize]}}>{replaceVars(selectedField.intro_body)}</p>
						<div style={{textAlign:'center'}}>
							<RenderButton disabled={false} onClick={gotoNextItem} text={selectedField.intro_next} />
						</div>
						<br />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const RenderPrivacyPolicy = props => {
		const [open,setOpen] = useState(false);
		const [promptBefore,setPromptBefore] = useState('');
		const [promptAfter, setPromptAfter] = useState('');
		useEffect(() => {
			var prompt = selectedField.policy_head;
			var fields = prompt.split('[privacy_policy]');
			if (fields.length > 1) setPromptAfter (replaceVars(fields[1]));
			if (fields.length > 0) setPromptBefore(replaceVars(fields[0]));
			setVar(selectedField.name, false);
		},[]);
		const handleConfirm = () => {
			selectedField.value = true;
			setVar(selectedField.name, true);
			if (selectedIndex < lastIndex) gotoNextItem();
			else selectPage(hasSurvey?1:2);
		};
		if (selectedField.visible === 0) return <RenderDisabled />;
		return (
			<>
				<RenderImage />
				<Modal size="large" open={open}
					onClose={()=>setOpen(false)}>
					<Modal.Header>Privacy Policy</Modal.Header>
					<Modal.Content scrolling>{replaceVars(selectedField.text)}</Modal.Content>
					<Modal.Actions><Button onClick={() => setOpen(false)}>Close</Button></Modal.Actions>
				</Modal>
				<Grid padded centered columns={1}>
					<Grid.Column width={12} textAlign="center">
						<Header size={buttonSize}>
							<span style={{color:textColor,fontFamily:headerFont}}>{promptBefore}</span>
							<span>
								{selectedField.mode === 0 && <a style={{textDecoration:'underline',color:textColor,fontFamily:headerFont}} href={selectedField.link} rel="noreferrer" target="_blank">Privacy Policy</a>}
								{selectedField.mode === 1 && <a style={{textDecoration:'underline',color:textColor,fontFamily:headerFont}} href='/' onClick={e=>{setOpen(true)}}>Privacy Policy</a>}
							</span>
							<span style={{color:textColor,fontFamily:headerFont}}>{promptAfter}</span>
						</Header>
					</Grid.Column>
					<Grid.Column width={12} textAlign="center">
						<RenderBackButton />
						<RenderButton onClick={handleConfirm} text={selectedField.policy_next} />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const GetCertificateURL = () => {
		const cid = app.course.id;
		return app.pubBase + '/certificate?preview=true&id='+cid;
	};

	const RenderFinalPage = props => {
		const item1 = data[selectedPage][0];
		const item2 = data[selectedPage][1];
		const text1 = replaceVars(item1.final_head);
		const text2 = replaceVars(item1.final_body);
		const social_text1 = replaceVars(item2.text1);
		const social_text2 = replaceVars(item2.text2);
		const social_text3 = replaceVars(item2.text3);
		return (
			<>
				<RenderImage />
				<Header size={headerSize} textAlign="center" style={{padding:'1rem',color:textColor,fontFamily: headerFont}}>{text1}</Header>
				<Grid columns={1} padded centered><Grid.Column width={4}><Image src="/images/blank-image.png" fluid /></Grid.Column></Grid>
				<div style={{textAlign:'center'}}><Button size={buttonSize} style={{backgroundColor:buttonBackColor,color:buttonTextColor,fontFamily:buttonFont}} onClick={()=>{}}>{item1.buttonText1}</Button></div><br />
				{(item2.enabled1===1||item2.enabled2===1||item2.enabled3===1)&&<Header size={headerSize} textAlign="centered" style={{color:textColor,fontFamily:headerFont}}>{item2.text0}</Header>}
				<div style={{textAlign:'center'}}>
					{item2.enabled1===1&& <FacebookShareButton className="ui facebook button" url={GetCertificateURL()} quote={social_text1}><FacebookIcon size="64" round /></FacebookShareButton>}
					{item2.enabled2===1&& <TwitterShareButton className="ui twitter button" url={GetCertificateURL()} title={social_text2}><TwitterIcon size="64" round /></TwitterShareButton>}
					{item2.enabled3===1&& <LinkedinShareButton className="ui linkedin button" url={GetCertificateURL()} title={social_text3} summary={social_text3} source="http://claimcertificate.com"><LinkedinIcon size="64" round /></LinkedinShareButton>}
				</div>
				<Grid columns={1} centered>
					<Grid.Column width={12}>
						<br />
						<p textAlign="center" style={{padding:'1rem',color:textColor,fontFamily: commonFont,fontSize:SizesToEm[buttonSize]}}>{text2}</p>
						<div style={{textAlign:'center'}}>
							<RenderButton onClick={()=>window.open(item1.buttonLink2,'_blank')} text={item1.buttonText2} />
						</div>
						<br />
					</Grid.Column>
				</Grid>
			</>
		);
	};

	const RenderTextField = props => {
		const [value,setValue] = useState('');
		const handleSetValue = e => {
			const value = e.target.value;
			setVar(selectedField.name, value);
			setValue(value);
		};
		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			setValue(value);
		},[]);
		if (selectedField.visible === 0) return <RenderDisabled />;
		const condition = selectedField.required === 0 || value.length > 0;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily: commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
					</Grid.Column>
					<Grid.Column width={12}>
						<Form>
							<Form.Field>
								<div class="ui small input">
									{selectedField.length==='l'&&
										<input type="text"
											style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
											maxLength={200} value={value} onChange={handleSetValue} autoFocus />}
									{selectedField.length==='p'&&
										<textarea rows={4}
											style={{color:inputColor,width:'100%',fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
											maxLength={1000} onChange={handleSetValue} autoFocus>{value}</textarea>}
								</div>
							</Form.Field>
						</Form>
						<br />
						<RenderSurveyButtons condition={condition} />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const RenderCheckField = props => {
		const [value,setValue] = useState(0);
		const handleSetValue = (e,d) => {
			const value = d.checked ? 1 : 0;
			setVar(selectedField.name,value);
			setValue(value);
		};
		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			setValue(value);
		},[]);
		if (selectedField.visible === 0) return <RenderDisabled />;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily:commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
					</Grid.Column>
					<Grid.Column width={12} textAlign="left">
						<Form>
							<Form.Group>
							<Form.Field>
								<Checkbox
									style={{color:inputColor}}
									checked={value === 1}
									onChange={handleSetValue}
									size={buttonSize}
									toggle />
							</Form.Field>
							<Form.Field>
								<span style={{backgroundColor:backColor,color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}>
									{value === 1 ? 'Yes' : 'No' }
								</span>
							</Form.Field>
							</Form.Group>
						</Form>
						<br />
						<RenderSurveyButtons />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const RenderCheckList = props => {
		const [value,setValue] = useState('');
		const [values, setValues] = useState([]);

		const handleSetValue = (item, checked) => {
			if (checked) {
				const value = item.value;
				setVar(selectedField.name, value);
				setValue(value);
			}
		};

		const handleToggleValue = (item, checked) => {
			const new_items = checked?[...values, item.value]:values.filter(x => x !== item.value);
			const new_value = new_items.join(',');
			setVar(selectedField.name, new_value);
			setValue (new_value);
			setValues(new_items);
		}

		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			if (selectedField.multiple)
				setValues(value.split(/(\s*,\s*)/));
			else setValue(value);
		},[]);

		const RenderMultiple = props => {
			const item = props.item;
			return (
				<Form.Field>
					<div>
						<Checkbox checked={values.indexOf(item.value) >= 0} style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
							size={buttonSize} onChange={(e,d) => handleToggleValue(item, d.checked)} />
						<span style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}>&nbsp;{item.text}</span>
					</div>
				</Form.Field>
			);
		}

		const RenderSingular = props => {
			const item = props.item;
			return (
				<Form.Field>
					<div>
					<Radio checked={item.value === value} style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
						size={buttonSize} onChange={(e,d) => handleSetValue(item, d.checked)} />
						<span style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}>&nbsp;{item.text}</span>
					</div>
				</Form.Field>
			);
		}

		if (selectedField.visible === 0) return <RenderDisabled />;
		const condition = selectedField.required === 0 || value.length > 0;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily:commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
						{selectedField.multiple === 1 && selectedField.required !== 0 && <Label size={buttonSize}>You must select at least one item</Label>}
					</Grid.Column>
					<Grid.Column width={12} textAlign="left">
						<Form>
						{selectedField.multiple === 0
							?selectedField.items.map(item => <RenderSingular key={item.key} item={item} />)
							:selectedField.items.map(item => <RenderMultiple key={item.key} item={item} />)}
						</Form>
						<br />
						<RenderSurveyButtons condition={condition} />
					</Grid.Column>
				</Grid>
			</>
		);
	};

	const RenderRating = props => {
		const [value,setValue] = useState(0);
		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			setValue(value);
		},[]);
		const handleSetValue = (e, d) => {
			const value = d.rating;
			setVar(selectedField.name, value);
			setValue(value);
		};
		if (selectedField.visible === 0) return <RenderDisabled />;
		const condition = selectedField.required === 0 || value !== 0;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily:commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
					</Grid.Column>
					<Grid.Column width={12} textAlign="left">
						<Form>
							<Form.Field>
								<label style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}><i>{selectedField.label}</i></label>
								<Rating icon='star' color='yellow' size={buttonSize} rating={value} maxRating={5} onRate={handleSetValue} clearable={true} />
							</Form.Field>
						</Form>
						<br />
						<RenderSurveyButtons condition={condition} />
					</Grid.Column>
				</Grid>
			</>
		);
	};

	const RenderVarName = props => {
		const item = props.item || selectedField;
		return (
			<Form.Field width={10}>
				<label>Question for</label>
				<Label>{item.name}</Label>
			</Form.Field>
		);
	};


	const RenderDisabled = props => {
		return (
			<Grid columns={1} padded>
				<Grid.Column textAlign="center">
					<Label>This field has been disabled</Label>
				</Grid.Column>
			</Grid>
		);
	};

	const MaxImageHeight = 128;

	const getImagePath = path => path !== '' ? path :'/images/blank-image.png';

	const RenderImageSelector = props => {
		return (
			<Form.Field width={16}>
				<label>{props.label}</label>
				<div style={{position:'relative',border:'1px #cccccc solid',borderRadius:'8px',padding:'8px'}}  onClick={()=>setImageOpen(true)}>
				<Image style={{cursor:'pointer',height:MaxImageHeight}} src={getImagePath(headerImage)} centered={headerFluid===0} fluid={headerFluid===1} />
				<ImageSelector app={app} open={imageOpen} setOpen={setImageOpen} vars={[props.caption,headerImage,setHeaderImage]} />
				{props.path!==''&&<div style={{position:'absolute',top:'8px',right:'4px'}}>
					{headerFluid === 0 && <Button icon onClick={e =>{e.preventDefault(); e.stopPropagation(); setHeaderFluid(1);}}><Icon name="arrows alternate horizontal" /></Button>}
					{headerFluid === 1 && <Button icon onClick={e =>{e.preventDefault(); e.stopPropagation(); setHeaderFluid(0);}}><Icon name="align center" /></Button>}
					<Button onClick={e=>{e.preventDefault(); e.stopPropagation(); setImageOpen(true);}} icon><Icon link name="pencil" /></Button>
					<Button disabled={headerImage === ''} onClick={e=>{e.preventDefault(); e.stopPropagation(); setHeaderImage('');}} icon><Icon link name="times" /></Button>
				</div>}
				</div>
			</Form.Field>
		);
	};

	const RenderWelcomeIntroEditor = props => {
		const [introHead	,setIntroHead	] = useState(selectedField.intro_head	);
		const [introBody	,setIntroBody	] = useState(selectedField.intro_body	);
		const [introNext	,setIntroNext	] = useState(selectedField.intro_next	);

		const handleUpdateIntroHead = e => {
			const input = e.target.value;
			selectedField.intro_head = input;
			setIntroHead(input);
		};
		const handleUpdateIntroBody = e => {
			const input = e.target.value;
			selectedField.intro_body = input;
			setIntroBody(input);
		};
		const handleUpdateIntroNext = e => {
			const input = e.target.value;
			selectedField.intro_next = input;
			setIntroNext(input);
		};

		return (
			<>
			<Grid padded centered>
				<Grid.Column width={12}>
					<Form>
						<RenderImageSelector label="Header image" caption="Select Image" />
						<Form.Input label="Welcome header" value={introHead} onChange={handleUpdateIntroHead} />
						<Form.TextArea label="Welcome text" rows={10} onChange={handleUpdateIntroBody}>{introBody}</Form.TextArea>
						<Form.Input label="Button text" value={introNext} onChange={handleUpdateIntroNext} />
					</Form>
				</Grid.Column>
			</Grid>
			</>
		);
	};

	const RenderTextFieldEditor = props => {
		const [prompt,setPrompt] = useState(selectedField.prompt);
		const [value,setValue] = useState(selectedField.value);
		const [length,setLength] = useState(selectedField.length);
		const [required,setRequired] = useState(selectedField.required);

		const handleUpdatePrompt = e => {
			const input = e.target.value;
			selectedField.prompt = input;
			setPrompt(input);
		};
		const handleUpdateValue = e => {
			const input = e.target.value;
			selectedField.value = input;
			setVar(selectedField.name, input);
			setValue(input);
		};
		const handleUpdateRequired = (e,d) => {
			const value = d.checked ? 1 : 0;
			selectedField.required = value;
			setRequired(value);
		}
		const handleUpdateLength = (e,d) => {
			const input = d.value;
			selectedField.length = input;
			setLength(input);
		};
		return (
			<Grid padded centered>
				<Grid.Column width={12}>
					<Form>
						<RenderVarName />
						<Form.Input label="Question" value={prompt}
							onChange={handleUpdatePrompt} />
						<Form.Input label="Default Value" value={value}
							onChange={handleUpdateValue} />
						<Form.Dropdown label="Length of answer" options={LengthOptions} selection
							value={length} onChange={handleUpdateLength} />
						{required !== 2&&
						<Form.Field>
							<label>Required</label>
							<Checkbox
								checked={required === 1}
								label={required===0?'No':'Yes'}
								onClick={handleUpdateRequired}
								toggle />
						</Form.Field>}
					</Form>
				</Grid.Column>
			</Grid>
		);
	}

	const RenderCheckFieldEditor = props => {
		const [prompt,setPrompt] = useState(selectedField.prompt);
		const [value,setValue] = useState(selectedField.value);
		const handleUpdatePrompt = e => {
			const input = e.target.value;
			selectedField.prompt = input;
			setPrompt(input);
		};
		const handleUpdateValue = (e,d) => {
			const input = d.checked? 1 : 0;
			selectedField.value = input;
			setVar(selectedField.name, input);
			setValue(input);
		};
		return (
			<Grid padded centered>
				<Grid.Column width={12}>
					<Form>
						<RenderVarName />
						<Form.Input label="Question" value={prompt}
							onChange={handleUpdatePrompt} />
						<Form.Field>
							<label>Default Value</label>
							<Checkbox checked={value === 1}
								label={value === 1?'Yes':'No'}
								onClick={handleUpdateValue}
								toggle />
						</Form.Field>
					</Form>
				</Grid.Column>
			</Grid>
		);
	}

	const RenderPrivacyPolicyEditor = props => {
		const [prompt1,setPrompt1] = useState(selectedField.policy_head);
		const [prompt2,setPrompt2] = useState(selectedField.policy_next);
		const [mode,setMode] = useState(selectedField.mode);
		const [link,setLink] = useState(selectedField.link);
		const [text,setText] = useState(selectedField.text);
		const handleUpdatePrompt1 = e => {
			const input = e.target.value;
			selectedField.policy_head = input;
			setPrompt1(input);
		};
		const handleUpdatePrompt2 = e => {
			const input = e.target.value;
			selectedField.policy_next = input;
			setPrompt2(input);
		};
		const handleUpdateMode = value => {
			selectedField.mode = value;
			setMode(value);
		};
		const handleUpdateLink = e => {
			const input = e.target.value;
			selectedField.link = input;
			setLink(input);
		};
		const handleUpdateText = e => {
			const input = e.target.value;
			selectedField.text = input;
			setText(input);
		};
		return (
			<>
				<Grid padded centered>
					<Grid.Column width={12}>
						<Menu attached="top" tabular>
							<Menu.Item active={mode===0} onClick={e => handleUpdateMode(0)}>Link</Menu.Item>
							<Menu.Item active={mode===1} onClick={e => handleUpdateMode(1)}>Text</Menu.Item>
						</Menu>
						<Segment attached="bottom">
							<Form>
								<RenderVarName />
								<Form.Input label="Prompt Text" value={prompt1} onChange={handleUpdatePrompt1} />
								<Form.Input label="Button Text" value={prompt2} onChange={handleUpdatePrompt2} />
								{mode===0 &&
									<Form.Input label="Privacy Policy Link" value={link}
										onChange={handleUpdateLink} />}
								{mode===1 &&
									<Form.TextArea rows={20} label="Privacy Policy Content" value={text}
										onChange={handleUpdateText} />}
							</Form>
						</Segment>
					</Grid.Column>
				</Grid>
			</>
		);
	};

	const RenderFinalPageTextEditor = props => {
		const [text1,setText1] = useState(selectedField.final_head);
		const [text2,setText2] = useState(selectedField.final_body);
		const [buttonText1,setButtonText1] = useState(selectedField.buttonText1);
		const [buttonText2,setButtonText2] = useState(selectedField.buttonText2);
		const [buttonLink2,setButtonLink2] = useState(selectedField.buttonLink2);
		const handleUpdateText1 = e => {
			const input = e.target.value;
			selectedField.final_head = input;
			setText1(input);
		};
		const handleUpdateText2 = e => {
			const input = e.target.value;
			selectedField.final_body = input;
			setText2(input);
		};
		const handleUpdateButtonText1 = e => {
			const input = e.target.value;
			selectedField.buttonText1 = input;
			setButtonText1(input);
		};
		const handleUpdateButtonText2 = e => {
			const input = e.target.value;
			selectedField.buttonText2 = input;
			setButtonText2(input);
		};
		const handleUpdateButtonLink2 = e => {
			const input = e.target.value;
			selectedField.buttonLink2 = input;
			setButtonLink2(input);
		};
		return (
			<>
			<Grid padded centered>
				<Grid.Column width={12}>
					<Form>
						<Form.Input label="Final page header" value={text1} onChange={handleUpdateText1} />
						<Form.TextArea label="Final text" rows={10} onChange={handleUpdateText2}>{text2}</Form.TextArea>
						<Form.Input label="Download button text" value={buttonText1} onChange={handleUpdateButtonText1} />
						<Form.Input label="Call-to-action button text" value={buttonText2} onChange={handleUpdateButtonText2} />
						<Form.Input label="Call-to-action button link" value={buttonLink2} onChange={handleUpdateButtonLink2} />
					</Form>
				</Grid.Column>
			</Grid>
			</>
		);
	};

	const RenderFinalPageShareEditor = props => {
		const [text0,setText0] = useState(selectedField.text0);
		const [text1,setText1] = useState(selectedField.text1);
		const [text2,setText2] = useState(selectedField.text2);
		const [text3,setText3] = useState(selectedField.text3);
		const [enabled1,setEnabled1] = useState(selectedField.enabled1 === 1);
		const [enabled2,setEnabled2] = useState(selectedField.enabled2 === 1);
		const [enabled3,setEnabled3] = useState(selectedField.enabled3 === 1);
		const handleUpdateText0 = e => {
			const input = e.target.value;
			selectedField.text0 = input;
			setText0(input);
		};
		const handleUpdateText1 = e => {
			const input = e.target.value;
			selectedField.text1 = input;
			setText1(input);
		};
		const handleUpdateText2 = e => {
			const input = e.target.value;
			selectedField.text2 = input;
			setText2(input);
		};
		const handleUpdateText3 = e => {
			const input = e.target.value;
			selectedField.text3 = input;
			setText3(input);
		};
		const handleUpdateEnabled1 = (e,d) => {
			const input = d.checked;
			selectedField.enabled1 = input?1:0;
			setEnabled1(input);
		};
		const handleUpdateEnabled2 = (e,d) => {
			const input = d.checked;
			selectedField.enabled2 = input?1:0;
			setEnabled2(input);
		};
		const handleUpdateEnabled3 = (e,d) => {
			const input = d.checked;
			selectedField.enabled3 = input?1:0;
			setEnabled3(input);
		};
		return (
			<Grid padded centered>
				<Grid.Column width={12}>
					<Form>
						<Form.Input label="Share text" value={text0} onChange={handleUpdateText0} width={14} />
						<br />
						<Form.Group>
							<Form.TextArea label="Share on Facebook" width={14} rows={6} onChange={handleUpdateText1}>{text1}</Form.TextArea>
							<Form.Field width={2}>
								<label>&nbsp;</label>
								<Icon size='huge' name='facebook' />
							</Form.Field>
						</Form.Group>
						<Form.Checkbox width={4} label={enabled1?'Enabled':'Disabled'} checked={enabled1} onChange={handleUpdateEnabled1} toggle />
						<br />
						<Form.Group>
							<Form.TextArea label="Share on Twitter" width={14} rows={6} onChange={handleUpdateText2}>{text2}</Form.TextArea>
							<Form.Field width={2}>
								<label>&nbsp;</label>
								<Icon size='huge' name='twitter' />
							</Form.Field>
						</Form.Group>
						<Form.Checkbox width={4} label={enabled2?'Enabled':'Disabled'} checked={enabled2} onChange={handleUpdateEnabled2} toggle />
						<br />
						<Form.Group>
							<Form.TextArea label="Share on LinkedIn" width={14} rows={6} onChange={handleUpdateText3}>{text3}</Form.TextArea>
							<Form.Field width={2}>
								<label>&nbsp;</label>
								<Icon size='huge' name='linkedin' />
							</Form.Field>
						</Form.Group>
						<Form.Checkbox width={4} label={enabled3?'Enabled':'Disabled'} checked={enabled3} onChange={handleUpdateEnabled3} toggle />
						<br />
					</Form>
				</Grid.Column>
			</Grid>
		);
	};

	const RenderNewQuestion = props => {
		const [type,setType] = useState('0');
		const [name,setName] = useState('');
		const [prompt,setPrompt] = useState('');
		const handleConfirm = () => {
			const field = Object.assign({},selectedField);
			field.type = parseInt(type);
			field.prompt = prompt;
			field.name = name;
			switch (field.type) {
				case 2:
					field.value = '';
					field.length = 'l';
					break;
				case 3:
					field.value = 0;
					break;
				case 8:
					field.required = 2;
					field.multiple = 0;
					field.value = '';
					field.items = [
						{key:uuid(),value:'choice1',text:'Choice 1'},
						{key:uuid(),value:'choice2',text:'Choice 2'},
						{key:uuid(),value:'choice3',text:'Choice 3'},
					];
					break;
				case 9:
					field.required = 2;
					field.label = "Rating from least to most";
					field.value = 0;
					break;
				default:
					return;
			}
			data[selectedPage] = [...data[selectedPage].filter(field => field !== selectedField), field];
			setNewQuestion(false);
			setRefreshLinks(!refreshLinks);
			setSelectedField(field);
		}

		const handleRemove = () => {
			data[selectedPage] = data[selectedPage].filter(field => field !== selectedField);
			setNewQuestion(false);
			setRefreshLinks(!refreshLinks);
			selectLastItem();
		};
	
		return (
			<Grid padded centered>
				<Grid.Column width={12}>
					<Header>New Question</Header>
					<Form>
						<Form.Input label="Question" value={prompt}
							onChange={e=>setPrompt(e.target.value)}
							fluid autoFocus />
						<Form.Group>
							<Form.Input width={10} label="Label to identify answer" value={name}
								onChange={e=>setName(e.target.value)} fluid/>
							{false &&
							<Form.Field width={6}>
								<label>Tag to refer to answer</label>
								<Label>@{getVarName(name)}</Label>
							</Form.Field>}
						</Form.Group>
						<Form.Field>
							<label>Select type of answer</label>
							<Select options={QuestionTypeOptions}
								onChange={(e,d)=>setType(d.value)} value={type} />
						</Form.Field>
						<Form.Group>
							<Form.Field>
								<Button color="blue" disabled={type==='0' || name.length === 0 || prompt.length === 0} onClick={handleConfirm}>Confirm</Button>
							</Form.Field>
							<Form.Field>
								<Button color="red" onClick={handleRemove}>Cancel</Button>
							</Form.Field>
						</Form.Group>
					</Form>
				</Grid.Column>
			</Grid>
		);
	};

	const RenderCheckListEditor = props => {
		const [prompt,setPrompt] = useState(selectedField.prompt);
		const [value,setValue] = useState(selectedField.value);
		const [required,setRequired] = useState(selectedField.required);
		const [multiple,setMultiple] = useState(selectedField.multiple);
		const [items, setItems] = useState(selectedField.items);

		const handleUpdatePrompt = e => {
			const input = e.target.value;
			selectedField.prompt = input;
			setPrompt(input);
		};
		const handleUpdateValue = e => {
			const input = e.target.value;
			selectedField.value = input;
			setVar(selectedField.name, input);
			setValue(input);
		};
		const handleUpdateRequired = (e,d) => {
			const value = d.checked ? 1 : 0;
			selectedField.required = value;
			setRequired(value);
		}
		const handleUpdateMultiple = (e,d) => {
			const value = d.checked ? 1 : 0;
			selectedField.multiple = value;
			setMultiple(value);
		}

		const handleAddItem = () => {
			const index = items.length + 1;
			const new_item = {key:uuid(), text:'Choice ' + index, value:'choice' + index};
			const new_list = [...items, new_item];
			selectedField.items = new_list;
			setItems(new_list);
		};

		const RenderListItem = props => {
			const item = props.item;
			const index = items.indexOf(item) + 1;
			const [text	, setText	] = useState(item.text	);
			const [value, setValue	] = useState(item.value	);
			const handleUpdateText = (e, d) => {
				const value = d.value;
				item.text = value; setText(value);
			}
			const handleUpdateValue = (e, d) => {
				const value = d.value === '' ? getVarName(text) : d.value;
				item.value = value; setValue(value);
			}
			const handleDeleteItem = () => {
				const new_list = items.filter(x => x.key !== item.key);
				selectedField.items = new_list;
				setItems(new_list);
			};
			const handleTextLostFocus = (e, d) => {
				if (text === '') {
					setText("Option #" + index);
					if (value === '') setValue('option_' + index);
				}
			}
			return (
				<Table.Row>
					<Table.Cell collapsing>{index}.</Table.Cell>
					<Table.Cell><Input value={text	} onChange={handleUpdateText} onBlur={handleTextLostFocus} /></Table.Cell>
					<Table.Cell><Input value={value	} onChange={handleUpdateValue} /></Table.Cell>
					<Table.Cell><Icon name="trash" link onClick={handleDeleteItem} /></Table.Cell>
				</Table.Row>
			);
		};

		return (
			<Grid padded centered>
				<Grid.Column width={12}>
					<Form>
						<RenderVarName />
						<Form.Input label="Question" value={prompt}
							onChange={handleUpdatePrompt} />
						{false &&
						<Form.Input label="Default Value" value={value}
							onChange={handleUpdateValue} />}
						<Form.Group>
							<Form.Field width={8}>
								<label>Multiple choices</label>
								<Checkbox
									checked={multiple === 1}
									label={multiple===0?'No':'Yes'}
									onClick={handleUpdateMultiple}
									toggle />
							</Form.Field>							
							{required !== 2 &&
							<Form.Field width={8}>
								<label>Required</label>
								<Checkbox
									checked={required === 1}
									label={required===0?'No':'Yes'}
									onClick={handleUpdateRequired}
									toggle />
							</Form.Field>}
						</Form.Group>
						<Form.Field>
							<label>Items</label>
							<Table basic celled>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>#</Table.HeaderCell>
										<Table.HeaderCell>Text</Table.HeaderCell>
										<Table.HeaderCell>Value</Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{items.map(item => <RenderListItem key={item.key} item={item} />)}
								</Table.Body>
								<Table.Footer>
									<Table.Row>
										<Table.HeaderCell colspan={4}>
											<Button onClick={handleAddItem}>Add Item</Button>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Footer>
							</Table>
						</Form.Field>
					</Form>
				</Grid.Column>
			</Grid>
		);
	};

	const RenderRatingEditor = props => {
		const [prompt1,setPrompt1] = useState(selectedField.prompt);
		const [prompt2,setPrompt2] = useState(selectedField.label);
		const [required,setRequired] = useState(selectedField.required);
		const [value,setValue] = useState(selectedField.value);
		const handleUpdateRequired = (e,d) => {
			const value = d.checked ? 1 : 0;
			selectedField.required = value;
			setRequired(value);
		}
		const handleUpdatePrompt1 = e => {
			const input = e.target.value;
			selectedField.prompt = input;
			setPrompt1(input);
		};
		const handleUpdatePrompt2 = e => {
			const input = e.target.value;
			selectedField.label = input;
			setPrompt2(input);
		};
		const handleUpdateValue = (e,d) => {
			const input = d.rating;
			selectedField.value = input;
			setVar(selectedField.name, input);
			setValue(input);
		};
		return (
			<Grid padded centered>
				<Grid.Column width={12}>
					<Form>
						<RenderVarName />
						<Form.Input label="Question" value={prompt1}
							onChange={handleUpdatePrompt1} />
						<Form.Input label="Rating Description" value={prompt2}
							onChange={handleUpdatePrompt2} />
						{required !== 2 &&
						<Form.Field>
							<label>Default Rating</label>
							<Label>{value}</Label>
        					<Rating icon="star" color="yellow"
								rating={value} maxRating={5} onRate={handleUpdateValue}
								clearable={true} />
						</Form.Field>}
						{required !== 2 &&
						<Form.Field width={8}>
								<label>Required</label>
								<Checkbox
									checked={required === 1}
									label={required===0?'No':'Yes'}
									onClick={handleUpdateRequired}
									toggle />
						</Form.Field>}
					</Form>
				</Grid.Column>
			</Grid>
		);
	}

	const RenderSelectedField = props => {
		if (selectedField === null) return <></>;
		if (selectedPage === 1 && hasSurvey === 0) {
			return (
				<Grid columns={1} padded>
					<Grid.Column textAlign="center">
						<br /><br />
						<Label>Survey has been disabled</Label>
					</Grid.Column>
				</Grid>
			);
		}
		const fieldKey = preview? props.key + '_preview' : props.key;
		switch(selectedField.type) {
			case -1: return <RenderNewQuestion keys="new_question" />;
			case  0: return preview?<RenderTextField key={fieldKey} />:<RenderTextFieldEditor key={fieldKey} />;
			case  1: return preview?<RenderCheckField key={fieldKey} />:<RenderCheckFieldEditor key={fieldKey} />;
			case  2: return preview?<RenderCheckList key={fieldKey} />:<RenderCheckListEditor key={fieldKey} />;
			case  3: return preview?<RenderRating key={fieldKey} />:<RenderRatingEditor key={fieldKey} />;
			case  90: return preview?<RenderWelcomeIntro key={fieldKey} />:<RenderWelcomeIntroEditor key={fieldKey} />;
			case  91: return preview?<RenderPrivacyPolicy key={fieldKey} />:<RenderPrivacyPolicyEditor key={fieldKey} />;
			case  98: return preview?<RenderFinalPage key={fieldKey} />:<RenderFinalPageTextEditor key={fieldKey} />;
			case  99: return preview?<RenderFinalPage key={fieldKey} />:<RenderFinalPageShareEditor key={fieldKey} />;
			default: return <></>;
		}
	}

	const FieldArrangerPopup = props => {
		const item = props.item;
		const [open,setOpen] = useState(false);
		const [list,setList] = useState(null);
		const [target,setTarget] = useState(null);
		useEffect(()=>{
			setTarget(null);
			const options = [];
			const items = data[selectedPage];
			for (var index = 1; index < items.length; index++) {
				const current_item = items[index];
				if (current_item !== item) options.push({
					key		:current_item.name,
					value	:current_item,
					text	:current_item.name
				});
				setList(options);
			}
		},[open,item]);
		const handleMove = before => {
			const new_items = [];
			const items = data[selectedPage];
			for (var index = 0; index < items.length; index++) {
				const current_item = items[index];
				const match = current_item === target;
				if (match && before) new_items.push(item);
				if (current_item !== item) new_items.push(current_item);
				if (match && !before) new_items.push(item);
			}
			data[selectedPage] = new_items;
			setRefreshLinks(!refreshLinks);
		};
		return <>
			<Modal key={item.name} open={open} size="tiny">
				<Modal.Header>Move Field</Modal.Header>
				<Modal.Content>
					<Form>
						<Form.Field>
							<label>Move</label>
							<Input value={item.name} readOnly fluid />
						</Form.Field>
						<Form.Field>
						<label>To</label>
						<Select placeholder='Select where to move to' options={list}
							onChange={(e,d)=>setTarget(d.value)} fluid />
						</Form.Field>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button disabled={target===null} onClick={()=>handleMove(true )} positive>Move Before</Button>
					<Button disabled={target===null} onClick={()=>handleMove(false)} positive>Move After</Button>
					<Button onClick={()=>setOpen(false)} negative>Cancel</Button>
				</Modal.Actions>
			</Modal>
			<Icon name="list ol" onClick={()=>setOpen(true)}></Icon>
		</>
	};

	const RenderFieldLink = props => {
		const item = props.item;
		const page = selectedPage;
		const active = (item === selectedField);
		const [visible,setVisible] = useState(item.visible);
		const handleUpdateDelete = e => {
			e.stopPropagation();
			data[selectedPage] = data[selectedPage].filter(field => field !== item);
			selectItem(data[selectedPage][0]);
		};
		const handleUpdateVisible = e => {
			e.stopPropagation();
			const value = visible === 0 ? 1 : 0;
			item.visible = value;
			setRefreshImage(!refreshImage);
			setVisible(value);
		};
		return (
			<Menu.Item active={active} onClick={e=>selectItem(item)}>
				{item.type === -1
				?<Grid style={{margin:0,padding:0}}>
					<Grid.Column style={{padding:2}} width={10}>
						{active?<b>{item.name}</b>:item.name}
					</Grid.Column>
				</Grid>
				:<Grid style={{margin:0,padding:0}}>
					<Grid.Column style={{padding:2}} width={1} textAlign="right">{props.index + '.'}</Grid.Column>
					<Grid.Column style={{padding:2}} width={9}>{active?<b>{item.name}</b>:item.name}</Grid.Column>
					<Grid.Column style={{padding:2}} width={2} textAlign="right">{page===1&&<FieldArrangerPopup item={item} />}</Grid.Column>
					<Grid.Column style={{padding:2}} width={2} textAlign="right">{item.visible!==2&&<Icon name={item.visible===1?'eye':'eye slash'} onClick={handleUpdateVisible} />}</Grid.Column>
					<Grid.Column style={{padding:2}} width={2} textAlign="right">{page!==0&&item.visible!==2&&<Icon name='trash' onClick={handleUpdateDelete} />}</Grid.Column>
				</Grid>}
			</Menu.Item>
		);
	};

	const RenderFieldLinks = props => {
		var count = 0;
		if (selectedPage === 1 && props.data.length === 0) return <Message color="yellow">{NoQuestionWarningText}</Message>;
		return props.data.map(item => <RenderFieldLink key={item.name} index={++count} item={item} />);
	};

	const CreateNewQuestion = () => {
		return {
			type	: -1,
			name	: 'New Question',
			prompt	: 'Enter your question here',
			required: 1,
			visible	: 1
		};
	}

	const addSurveyQuestion = () => {
		const field = CreateNewQuestion();
		data[selectedPage] = [...data[selectedPage], field];
		setNewQuestion(true);
		if (preview) setPreview(false);
		setRefreshLinks(!refreshLinks);
		setSelectedField(field);
	};

	const handleSetTemplateId = id => {
		const template = app.siteTemplates.find(item => item.id === id);
		const questions = JSON.parse(template.data); data[1] = [...questions];
		setSelectedIndex(0);
		setSelectedField(data[1][0]);
		setRefreshLinks(!refreshLinks);
	};

	return (
		<>
			<CourseActionBar update={handleUpdate} commit={app.saveCourse} />
			<Grid padded>
				<Grid.Row>
					<Grid.Column width={6}>
						<Menu attached="top" tabular>
							<Menu.Item active={!styling && selectedPage===0} onClick={e => selectPage(0)}>Welcome</Menu.Item>
							<Menu.Item active={!styling && selectedPage===1} onClick={e => selectPage(1)}>Survey</Menu.Item>
							<Menu.Item active={!styling && selectedPage===2} onClick={e => selectPage(2)}>Final</Menu.Item>
							<Menu.Menu position="right">
								<Menu.Item active={styling} style={{textAlign:"right"}}>
									<Popup content="style your funnel" trigger={<Icon link name="paint brush" onClick={e => {
										if (!preview) setPreview(true); setStyling(true)}} />} />
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<Segment style={{height:'720px',overflow:'auto'}} attached="bottom">
							{styling
							?<Grid>
								<Grid.Row columns={2}>
									<Grid.Column>
										<label>Brand colour</label>
										<ColorPicker color={brandColor} alpha={1} setColor={setBrandColor} />
									</Grid.Column>
									<Grid.Column>
										<label>Background colour</label>
										<ColorPicker color={backColor} alpha={1} setColor={setBackColor} />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<label>Text colour</label>
										<ColorPicker color={textColor} alpha={1} setColor={setTextColor} />
									</Grid.Column>
									<Grid.Column>
										<label>Input colour</label>
										<ColorPicker color={inputColor} alpha={1} setColor={setInputColor} />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<label>Button back colour</label>
										<ColorPicker color={buttonBackColor} alpha={1} setColor={setButtonBackColor} />
									</Grid.Column>
									<Grid.Column>
										<label>Button text colour</label>
										<ColorPicker color={buttonTextColor} alpha={1} setColor={setButtonTextColor} />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column>
										<label>Heading font</label>
										<FontSelector value={headerFont} setValue={setHeaderFont} />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column>
										<label>Text body font</label>
										<FontSelector value={commonFont} setValue={setCommonFont} />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column>
										<label>Input and Button font</label>
										<FontSelector value={buttonFont} setValue={setButtonFont} />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<label>Back button text</label>
										<Input key="_backButtonText" value={backButtonText} onChange={e=>setBackButtonText(e.target.value)} fluid />
									</Grid.Column>
									<Grid.Column>
										<label>Next button text</label>
										<Input key="_nextButtonText" value={nextButtonText} onChange={e=>setNextButtonText(e.target.value)} fluid />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column>
										<label>Heading size</label>
										<Button.Group fluid>
											<Button active={headerSize==='small'	} onClick={e=>setHeaderSize('small'	)}>Small</Button>
											<Button active={headerSize==='medium'	} onClick={e=>setHeaderSize('medium')}>Medium</Button>
											<Button active={headerSize==='large'	} onClick={e=>setHeaderSize('large'	)}>Large</Button>
											<Button active={headerSize==='huge'		} onClick={e=>setHeaderSize('huge'	)}>Huge</Button>
										</Button.Group>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column>
										<label>Text size</label>
										<Button.Group fluid>
											<Button active={buttonSize==='small'	} onClick={e=>setButtonSize('small'	)}>Small</Button>
											<Button active={buttonSize==='medium'	} onClick={e=>setButtonSize('medium')}>Medium</Button>
											<Button active={buttonSize==='large'	} onClick={e=>setButtonSize('large'	)}>Large</Button>
											<Button active={buttonSize==='huge'		} onClick={e=>setButtonSize('huge'	)}>Huge</Button>
										</Button.Group>
									</Grid.Column>
								</Grid.Row>
							</Grid>
							:<>
							{selectedPage === 0 && <Header size="tiny"><br />Welcome Section Settings</Header>}
							{selectedPage === 2 && <Header size="tiny"><br />Final Section Settings</Header>}
							{selectedPage === 1 &&
							<>
							<Header size="small"><br />Survey Section Settings</Header>
							<Form>
								<Form.Group>
									<Form.Field width={4}>
										<div>Enable Survey</div>
									</Form.Field>
									<Form.Field width={8}>
										<Checkbox toggle checked={hasSurvey} onChange={(e,d) => setHasSurvey(d.checked?1:0)} label={hasSurvey?'On':'Off'} />
									</Form.Field>
								</Form.Group>
								{hasSurvey === 1 && <>
									<Form.Field>
										<Input value={submitSurveyText} label="Submit Text"
											onChange={e=>setSubmitSurveyText(e.target.value)}
											fluid />
									</Form.Field>
									<Form.Field>
										<SiteTemplateSelector app={app}
											siteTemplateId={siteTemplateId} 
											setSiteTemplateId={handleSetTemplateId}  />
									</Form.Field>
								<Header size="small">Survey Questions</Header>
								</>}
							</Form>
							</>}
							{(selectedPage !== 1 || hasSurvey === 1) && <Menu fluid vertical><RenderFieldLinks key={'links' + refreshLinks} data={data[selectedPage]} /></Menu>}
							{(selectedPage === 1 && hasSurvey === 1) && <Button color="blue" onClick={addSurveyQuestion} fluid>Add New Question</Button>}
						</>}
					</Segment>
				</Grid.Column>
				<Grid.Column width={10}>
						<Segment.Group>
							<Segment>
								<Menu secondary>
									<Menu.Item>
										<Button.Group>
											<Button disabled={styling} color={!preview?"primary":null} onClick={e=>setPreview(false)}>Edit</Button>
											<Button color={preview?"primary":null} onClick={e=>setPreview(true)}>Preview</Button>
										</Button.Group>
									</Menu.Item>
								</Menu>
							</Segment>
							<Segment style={{minHeight:'720px',padding:0,margin:0,backgroundColor:(preview?backColor:'white'),color:(preview?textColor:'black')}}>
								{selectedField&&<RenderSelectedField key={selectedField?selectedField.name:'_nullfield_'} />}
							</Segment>
						</Segment.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
}