import { Modal } from 'semantic-ui-react';
import { PaymentList } from '../../app/Payment';

export default function PaymentListView(props) {
	const {app, open, setOpen, item} = props;
	if (!item) return <></>;
	const handleClose = () => setOpen(false);
	return (
		<Modal
			open={open}
			onClose={handleClose}
			closeOnDimmerClick={false}
			closeIcon centered>
			<Modal.Header>Payments for {item.name}</Modal.Header>
			<Modal.Content>
				<PaymentList app={app} userId={item.id} pageSize={5} />
			</Modal.Content>
		</Modal>
	);
}