import { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet , Route , Routes, useLocation, useNavigate } from 'react-router-dom';
import { Header, Image, Menu } from 'semantic-ui-react';
import { AppContext } from '../app/AppContext';
import BrandingPage from './Branding';
import DashboardPage from './Dashboard';
import CoursesPage from './Courses';
import ExportPage from './Export';
import SettingsPage from './Settings';
import CoursePage from './Course';
import EditCoursePage from './course/EditCourse';
import EditCertificatePage from './course/EditCertificate';
import EditFunnelPage from './course/EditFunnel';
import ReviewCoursePage from './course/ReviewCourse';
import CertificatePage from '../app/Certificate';
import ProfilePage from './Profile';
import PaymentPage from '../app/Payment';
import VerifyLink from '../app/Verify';
import FunnelLink from '../app/Funnel';
import { SubscriptionAlerts } from './dashboard/Subscription';
import { saveToStore } from '../lib/hooks';
import { apiGetCourses, apiGetBitmaps, apiGetCertTemplates, apiGetSiteTemplates, apiSignOut, apiGetMailingGroups, apiGetMailingTags } from '../lib/api';
import { sortByName } from '../lib/utils';
import SiteLogo from '../lib/images/main-logo.png';
import './Site.css';

function GuestSitePage(props) {
	const app = props.app;
	const navigate	= useNavigate();
	const location	= useLocation().pathname;
	const navigateToSubscriptionSetting = () => {
		saveToStore('guest/settings/section','subscription');
		navigate('/settings');
	}
	const handleSignOut = () => apiSignOut(app, result => { app.setSession(result); navigate('/signin'); });
	const RouteItem = props => 
	<Menu.Item onClick={()=>navigate(props.path)}
		 active={location.startsWith(props.path)} link>
		<Header>{props.text}</Header>
	</Menu.Item>;
	return (
		<div className="cf-guest-page">
			<div className="cf-guest-pane-side">
				<Image className="cf-guest-page-logo" src={SiteLogo} size="small" centered />
					<Menu className="cf-guest-page-menu" secondary vertical>
						<RouteItem path="/dashboard"	text="Dashboard"	/>
						<RouteItem path="/courses"		text="Courses"		/>
						<RouteItem path="/export"		text="Export"		/>
						<RouteItem path="/settings"		text="Settings"		/>
						<RouteItem path="/profile"		text="Profile"		/>
						<Menu.Item onClick={handleSignOut}>
						<Header size="medium">Sign Out</Header>
					</Menu.Item>
				</Menu>
			</div>
			<div className="cf-guest-pane-main">
				<SubscriptionAlerts session={app.session} onSettings={navigateToSubscriptionSetting} />
				<Outlet />
			</div>
		</div>
	)
}

export default function GuestSite(props) {
	const app = useContext(AppContext);
	const [courses, setCourses] = useState([]);
	const [bitmaps, setBitmaps] = useState([]);
	const [certTemplates, setCertTemplates] = useState([]);
	const [siteTemplates, setSiteTemplates] = useState([]);
	const [mailingGroups, setMailingGroups] = useState([]);
	const [mailingTags, setMailingTags] = useState([]);
	app.courses = courses; app.setCourses = setCourses;
	app.bitmaps = bitmaps; app.setBitmaps = setBitmaps;
	app.certTemplates = certTemplates; app.setCertTemplates = setCertTemplates;
	app.siteTemplates = siteTemplates; app.setSiteTemplates = setSiteTemplates;
	app.mailingGroups = mailingGroups; app.setMailingGroups = setMailingGroups;
	app.mailingTags = mailingTags; app.setMailingTags = setMailingTags;
	useEffect(() => {
		apiGetCourses(app, result => setCourses(result), result => console.log(result));
		apiGetBitmaps(app, result => setBitmaps(result), result => console.log(result));
		apiGetCertTemplates(app, result => setCertTemplates(result), result => console.log(result));
		apiGetSiteTemplates(app, result => setSiteTemplates(result), result => console.log(result));
		apiGetMailingGroups(app, result => setMailingGroups(result), result => console.log(result));
		apiGetMailingTags(app, result => setMailingTags(result), result => console.log(result));
	},[app]);
	app.selectCourse = id => courses.find(item => item.id === id);
	app.selectBitmap = id => bitmaps.find(item => item.id === id);
	app.selectCertTemplate = id => certTemplates.find(item => item.id === id);
	app.selectSiteTemplate = id => siteTemplates.find(item => item.id === id);
	app.insertCourse = course => setCourses([course, ...courses].sort(sortByName));
	app.insertBitmap = bitmap => setBitmaps([bitmap, ...bitmaps].sort(sortByName));
	app.updateCourse = course => setCourses([course, ...courses.filter(item => item.id !== course.id)].sort(sortByName));
	app.updateBitmap = bitmap => setBitmaps([bitmap, ...bitmaps.filter(item => item.id !== bitmap.id)].sort(sortByName));
	app.deleteCourse = course => setCourses(courses.filter(item => item.id !== course.id));
	app.deleteBitmap = bitmap => setBitmaps(bitmaps.filter(item => item.id !== bitmap.id));
	if (app.session.brandData === '' )
		return <BrandingPage app={app} />
	return (
		<Routes>
			<Route path="/course/:cid"	element={<CoursePage				/>}>
				<Route path="details"		element={<EditCoursePage			/>} />
				<Route path="certificate"	element={<EditCertificatePage		/>} />
				<Route path="funnel"		element={<EditFunnelPage			/>} />
				<Route path="review"		element={<ReviewCoursePage			/>} />
			</Route>
			<Route path="/" element={<GuestSitePage app={app} />}>
				<Route path=""						element={<Navigate to="/dashboard"	/>}	/>
				<Route path="users"					element={<Navigate to="/dashboard"	/>}	/>
				<Route path="signin"				element={<Navigate to="/dashboard"	/>}	/>
				<Route path="dashboard"				element={<DashboardPage				/>} />
				<Route path="courses"				element={<CoursesPage				/>}	/>
				<Route path="export"				element={<ExportPage				/>} />
				<Route path="settings"				element={<SettingsPage				/>} />
				<Route path="profile"				element={<ProfilePage				/>} />
				<Route path="payment/:mode/:data"	element={<PaymentPage				/>} />
			</Route>
			<Route path="/certificate"	element={<CertificatePage	preview={true} scale={25}	/>} />
			<Route path="/verify/*"		element={<VerifyLink		preview={true}				/>} />
			<Route path="/*"			element={<FunnelLink		preview={true}				/>} />
		</Routes>
	);
}