import React,{useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Divider, Button, Grid,Header} from 'semantic-ui-react';
import {AppContext} from '../../app/AppContext';
import {Failure} from '../../lib/alerts';
import {CourseActionBar} from './CourseActionBar';
import {CertificateCanvas} from '../../lib/CertificateLib';
import FunnelPage from '../../app/Funnel';

export default function ReviewCoursePage(props) {
	const app = useContext(AppContext);
	const navigate = useNavigate();
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (app.course !== null) {
			const course = app.course;
			if (course.save) app.saveCourse(course, false, false); else {
				setReady(
					course.certData !== '{}' &&
					course.siteData !== '{}'
				);
			}
		}
	},	[app, app.course]);

	const handleGoLive = () => {
		const course = Object.assign({},app.course);
		course.status = 1;
		app.saveCourse(course, true, true);
	};

	function CourseStatusRenderer(props) {
		return (ready
			?
			<React.Fragment>
				<Header>READY</Header><Divider />
				<Header size="medium">Your funnel is ready to go live.<br /></Header>
				<Button type="button" color="blue" onClick={handleGoLive}>Go Live</Button>
			</React.Fragment>
			:
			<React.Fragment>
				<Header>NOT READY</Header><Divider />
				<Header size="medium">
					Your funnel is not ready to go live.
					Make sure that you have setup both a certificate and
					a funnel for your course.</Header>
			</React.Fragment>
		);
	}

	const handleUpdate = () => app.course;

	if (app.course === null || app.course.save) return <></>;

	return (
		<>
		<CourseActionBar update={handleUpdate} commit={app.saveCourse} />
		<Grid columns={3} centered padded>
			<Grid.Column width={4} textAlign="center">
				<Header>CERTIFICATE</Header>
				<Divider />
				{(app.course !== null && app.course.certData !== '{}') ?
				<div style={{border:'1px #cecece solid'}}>
					<CertificateCanvas id={app.course.id} key="certificate-view" preview={true} inline={true} />
				</div>:
				<React.Fragment>
					<Failure caption="Incomplete" message="You have not setup a certificate for the course." />
						<Button color="blue" fluid onClick={()=>navigate('/course/' + app.course.id + '/certificate')}>SETUP CERTIFICATE</Button>
				</React.Fragment>}
			</Grid.Column>
			<Grid.Column width={8} textAlign="center">
				<Header>FUNNEL</Header>
				<Divider />
				{(app.course !== null && app.course.siteData !== '{}') ?
				<div className="funnel-preview-frame">
					<FunnelPage id={app.course.id} key="funnel-view" preview={true} indicator={false} />
				</div>:
				<React.Fragment>
					<Failure caption="Incomplete" message="You have not setup a funnel for the course." />
					<Button color="blue" fluid onClick={()=>navigate('/course/' + app.course.id + '/funnel')}>SETUP FUNNEL</Button>
				</React.Fragment>}
			</Grid.Column>
			<Grid.Column width={4}>
				<CourseStatusRenderer course={app.course} />
			</Grid.Column>
		</Grid>
		</>
	);
}