import { useContext, useEffect, useState } from 'react';
import { Button, Form, Grid, Icon, Pagination, Table } from 'semantic-ui-react';
import InsertUserPopup from './users/InsertUser';
import DeleteUserPopup from './users/DeleteUser';
import BrowseUserPopup from './users/BrowseUser';
import PaymentListView from './users/Payments';
import { AppContext } from '../app/AppContext';
import { Failure } from '../lib/alerts';
import { createSearchData, BasicSearchBar } from '../lib/dates';
import { apiSearchUsers } from '../lib/api';
import { useStore } from '../lib/hooks';

const PageSize			= 5;
const UserTypeIcons		= ['user','spy','gavel'];
const UserPlanNames		= ['Free','Monthly','Annual'];
const UserStatNames		= ['Unactivated','Active','Locked'];

export default function UsersPage(props) {
	const app = useContext(AppContext);
	const [searchData	, setSearchData	] = useStore('admin/users/search',createSearchData());
	const [list			, setList		] = useState(null);
	const [page			, setPage		] = useState([]);
	const [pageCount	, setPageCount	] = useState(0);
	const [pageIndex	, setPageIndex	] = useStore('admin/users/index',1);
	const [failure		, setFailure	] = useState('');
	const [activeItem	, setActiveItem	] = useState(null);
	const [insertOpen	, setInsertOpen	] = useState(false);
	const [browseOpen	, setBrowseOpen ] = useState(false);
	const [deleteOpen	, setDeleteOpen	] = useState(false);
	const [paymntOpen	, setPaymntOpen ] = useState(false);

	useEffect(() => {
		setFailure('');
		apiSearchUsers(app, searchData,
			result => {
				setList(result);
				const count = result.length;
				if (count === 0) setFailure('No users found.');
				setPageIndex(index => Math.min(index , count));
				setPageCount(Math.ceil(count / PageSize));
				setPage(result.slice(0, PageSize));
			},
			result => setFailure(result));
	},	[app,searchData,setPageIndex]);

	const updatePageIndex = pageIndex => {
		const itemIndex = (pageIndex - 1) * PageSize;
		setPage(list.slice(itemIndex, itemIndex + PageSize));
		setPageIndex(pageIndex);
	};

	const openBrowsePopup = item => { setActiveItem(item); setBrowseOpen(true); }
	const openDeletePopup = item => { setActiveItem(item); setDeleteOpen(true); }
	const openPaymentView = item => { setActiveItem(item); setPaymntOpen(true); }

	const RenderUser = props => {
		const item = props.item;
		const self = item.id === app.session.id;
		return (
			<Table.Row>
				<Table.Cell collapsing><Icon name={UserTypeIcons[item.type - 1]} onClick={()=>openBrowsePopup(item)} link /></Table.Cell>
				<Table.Cell>{item.name}</Table.Cell>
				<Table.Cell>{item.mail}</Table.Cell>
				<Table.Cell collapsing>{item.type === 1?UserPlanNames[item.plan]:''}</Table.Cell>
				<Table.Cell collapsing>{UserStatNames[item.status]}</Table.Cell>
				<Table.Cell collapsing>{item.createdAt}</Table.Cell>
				<Table.Cell collapsing>{item.plan > 0&& <Icon name="payment" disabled={self} onClick={()=>openPaymentView(item)} link />}</Table.Cell>
				<Table.Cell collapsing><Icon name="trash" disabled={self} onClick={()=>openDeletePopup(item)} link /></Table.Cell>
			</Table.Row>
		);
	};

	const handleInsertSuccess = result => { setSearchData({...searchData,text:'',dateMode:0}); }
	const handleUpdateSuccess = result => { setSearchData({...searchData}); setActiveItem(result); }
	const handleDeleteSuccess = result => { setSearchData({...searchData}); }

	return (
		<>
		<Grid padded>
			<Grid.Column width={16}>
				<BasicSearchBar data={searchData} setData={setSearchData} />
				{failure === '' &&
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
							<Table.HeaderCell>Email</Table.HeaderCell>
							<Table.HeaderCell>Subscription</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							<Table.HeaderCell colSpan={3}>Created at</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
					{page.map(item => <RenderUser key={item.id} item={item} />)}
					</Table.Body>
				</Table>}
				<Failure icon="times circle" message={failure} />
				<Form>
					<Form.Group>
						<Form.Field width={8}>
							<Button color="blue" onClick={()=>setInsertOpen(true)}>Add User</Button>
						</Form.Field>
						<Form.Field width={8} className="cf-right">
						{pageCount > 1 &&
							<Pagination
								size="tiny"
								activePage={pageIndex}
								totalPages={pageCount}
								boundaryRange={1} siblingRange={4}
								showEllipses={true} firstItem={null} lastItem={null}
								onPageChange={(e,d)=> updatePageIndex(d.activePage)}
							/>}
						</Form.Field>
					</Form.Group>
				</Form>
			</Grid.Column>
		</Grid>
		<InsertUserPopup app={app} open={insertOpen} setOpen={setInsertOpen} onUpdated={handleInsertSuccess} />
		<BrowseUserPopup app={app} open={browseOpen} setOpen={setBrowseOpen} item={activeItem} onUpdated={handleUpdateSuccess} />
		<DeleteUserPopup app={app} open={deleteOpen} setOpen={setDeleteOpen} item={activeItem} onUpdated={handleDeleteSuccess} />
		<PaymentListView app={app} open={paymntOpen} setOpen={setPaymntOpen} item={activeItem} />
		</>
	);
}