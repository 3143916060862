import axios from 'axios';
import {Helmet} from 'react-helmet';
import {Button, Icon, Image, Input, Segment, Header} from 'semantic-ui-react';
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useQuery} from '../lib/hooks';
import NotFoundPage from './NotFound';
import {Failure,Success} from '../lib/alerts';
import { PreviewIndicator } from '../lib/utils';
import {AppContext} from './AppContext';
import { Grid } from 'semantic-ui-react';
import SampleCertificate from '../lib/images/sample_certificate.jpg';
import {CertificateCanvas} from '../lib/CertificateLib';

function VerifyPageContent(props) {
	const {app,branding,preview,indicator} = props;
	const [certificate, setCertificate] = useState(null);
	const [cid,setCid] = useState('');
	const [failure,setFailure] = useState('');

	const getFileExtension = path => {
		return path.split('.').pop();
	}

	const RenderBranding = () => {
		if (branding === null) return <></>;
		return (
			<>
			<Helmet>
				{branding.brandName !== '' && <title>{branding.brandName}</title>}
				{branding.siteIcon	!== '' && <link rel="icon" type={'image/' + getFileExtension(branding.siteIcon)} href={app.resBase + branding.siteIcon} />}
			</Helmet>
			</>
		);
	}

	const handleSearch = () => {
		setFailure('');
		axios.get(app.apiBase + '/certificate/IC' + cid).then(response => {
			const data = response.data;
			if (app.devMode) console.log(data);
			if (data.status) setCertificate(data.result); else setFailure(data.result);
		}).catch(error => {setFailure('Error searching for certificate'); console.log(error)});
	};

	const handleClearSearch = () => {
		setCid('');
		setCertificate(null);
	}

	return (
		<>
		{(!preview || app.devMode) && <RenderBranding />}
		<PreviewIndicator preview={preview && indicator} />
		<div style={{backgroundColor:'#eeeeee',minHeight:'100vw'}}>
		<Grid padded>
			<Grid.Column width={10}>
				<div style={{textAlign:'center'}}>
					{certificate === null && <Image src={SampleCertificate} />}
					{certificate !== null && <CertificateCanvas id={'IC'+cid} inline />}
				</div>
			</Grid.Column>
			<Grid.Column width={6}>
				<Segment>
					<Image src={app.resBase + branding.siteLogo} crossorigin='anonymous'  />
					<br />
					<div style={{textAlign:'center'}}>
						<Header size="large">
							{certificate===null && 'Certificate Verification'	}
							{certificate!==null && 'Certificate Details'		}
						</Header>
					</div>
					<br />
					{certificate === null &&
					<Input icon={<Icon name='search' circular inverted link onClick={handleSearch} />}
						placeholder='Enter Certificate ID' value={cid} onChange={(e,d)=>setCid(d.value)} autoFocus fluid />}
					<Failure message={failure} />
					{certificate !== null &&
					<>
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<label><strong>Certificate ID</strong></label><br />
									{certificate.id.substring(2)}
								</Grid.Column>								
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<label><strong>Course Name</strong></label><br />
									{certificate.courseName}
								</Grid.Column>								
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={8}>
									<label><strong>Issued To</strong></label><br />
									{certificate.name}<br />
									{certificate.email}
								</Grid.Column>								
								<Grid.Column width={8}>
									<label><strong>Issued On</strong></label><br />
									{certificate.createdAt}
								</Grid.Column>								
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16}>
									<label><strong>Issued By</strong></label><br />
									{branding.brandName}<br />
									{branding.brandLink &&
										<a href={branding.brandLink}>{branding.brandLink}</a>}
								</Grid.Column>								
							</Grid.Row>
						</Grid>	
						<br />
						<Success message='Certificate verified' />
						<div style={{textAlign:'center'}}>
							<Button color="blue" onClick={handleClearSearch}>Verify another Certificate</Button>
						</div>
					</>}
				</Segment>
			</Grid.Column>
		</Grid>
		</div>
		</>
	);
}

function getLinkBase(path, preview) {
	return preview?path.substring('verify'.length):path;
}

export default function VerifyLink(props) {
	const query = useQuery();
	const app = useContext(AppContext);
	const preview = props.preview || query.get("preview") === 'true';
	const indicator = props.indicator === undefined  || props.indicator === true;
	const pathName = useLocation().pathname.substring(1);
	const linkBase = getLinkBase(pathName, preview);
	const [branding,setBranding] = useState(null);

	useEffect(() => {
		if (linkBase === '') setBranding({});
		else {
			axios.get(app.apiBase + '/branding/'+ linkBase).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) setBranding(JSON.parse(data.result)); else setBranding({});
			}).catch(error => {setBranding({}); console.log(error)});
		}
	},[app, linkBase]);

	if (branding === null) return <></>;
	if (Object.keys(branding).length === 0) return <NotFoundPage />;
	return <VerifyPageContent app={app} branding={branding} preview={preview} indicator={indicator} />;
}