import CopyToClipboard from 'react-copy-to-clipboard';
import {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Card, Checkbox, Divider, Form, Dropdown, Grid, Header, Icon,  Image, Input, Label, Menu, Modal, Popup, Segment} from 'semantic-ui-react';
import {AppContext} from '../app/AppContext';
import {TagList, TagSelector} from '../lib/tags';
import {Confirm} from '../lib/alerts';
import PageAddImage from '../lib/images/page-add.png';
import { useStore } from '../lib/hooks';
import './Courses.css';
import { apiDeleteCourse, apiDuplicateCourse, apiUpdateCourseStatus } from '../lib/api';

const CourseStatusProperties = [
	{ color: 'grey'		, text: 'Incomplete', icon: 'edit'		},
	{ color: 'green'	, text: 'Live'		, icon: 'lightning' },
	{ color: 'orange'	, text: 'Offline'	, icon: 'power off' },
];

const SortingOptions = [
	{key:'A',value:'A',text:'A - Z'},
	{key:'Z',value:'Z',text:'Z - A'},
];

function DeleteCoursePopup(props) {
	const input_ref = useRef();
	const {open,setOpen,onConfirm} = props;
	const [flag,setFlag] = useState(false);
	const [text,setText] = useState('');
	
	const handleConfirm = e => {
		onConfirm();
		setOpen(false);
	};

	const handleTextChange = e => {
		const value = e.target.value;
		setFlag(value === 'DELETE');
		setText(value);
	};

	return (
		<Modal size="mini" open={open}>
			<Modal.Header>Delete Course</Modal.Header>
			<Modal.Content>
				<p>Enter DELETE to delete the course</p>
				<Input key='deleteInput' ref={input_ref} value={text} onChange={handleTextChange} fluid autoFocus />
			</Modal.Content>
			<Modal.Actions>
			  <Button color="red" onClick={e=>setOpen(false)}>Cancel</Button>
			  <Button color="green" onClick={handleConfirm} disabled={!flag}>Confirm</Button>
			</Modal.Actions>
		</Modal>
	);
}

function getFunnelLink(app, item) {
	const basePath = app.pubBase;
	if (app.session.baseName === '' || item.linkPath === '')
		return basePath + (app.devMode?'/funnel':'') + ('?id=' + item.id);
	return `${basePath}/${app.session.baseName}/${item.linkPath}`;
}

function getCertificateLink(app, item) {
	return app.appBase + '/certificate?preview=true&id=' + item.id;
}

function CourseMenu(props) {
	const app = props.app;
	const item = props.item;
	const status = parseInt(item.status);
	const popupRef = useRef();
	const disabled = status === 0;
	const [open			,setOpen			] = useState(false);
	const [editOpen		,setEditOpen		] = useState(false);
	const [deleteOpen	,setDeleteOpen		] = useState(false);
	const [duplicateOpen,setDuplicateOpen	] = useState(false);
	const link1 = getCertificateLink(app, item);
	const link2 = getFunnelLink(app, item);

	const closePopup = () => setOpen(false);

	const handlePreviewCertificate = () => {
		window.open(link1, '_blank');
		closePopup();
	};

	const handleViewFunnel = () => {
		window.open(link2, '_blank');
		closePopup();
	};

	const confirmDuplicate = () => {
		setDuplicateOpen(true);
		closePopup();
	};

	const confirmDelete = () => {
		setDeleteOpen(true);
		closePopup();
	};

	const confirmEdit = () => {
		if (parseInt(item.status) === 1) {
			setEditOpen(true);
			closePopup();
		}
		else {
			props.onEdit();
		}
	}


	const toggleStatus = status => {
		const value = status ? 1 : 2;
		apiUpdateCourseStatus(app,item.id,value,
			result => app.updateCourse(result));
		closePopup();
	};

	const handleDuplicate = () => {
		apiDuplicateCourse(app, item.id,
			result => app.insertCourse(result));
		closePopup();
	};

	const handleDelete = () => {
		apiDeleteCourse(app, item.id,
			result => app.deleteCourse(result));
		closePopup();
	}

	return (
		<>
			<Popup ref={popupRef}
				trigger={<Icon style={{cursor:'pointer'}} name="ellipsis vertical" />} open={open}
				onOpen={()=>setOpen(true)} onClose={()=>setOpen(false)}
				on="click" position="bottom center"
				closeOnDocumentClick
				closeOnEscape
				basic>
				<Menu secondary vertical>
					<Menu.Item onClick={confirmEdit}>Edit Course</Menu.Item>
					<Menu.Item onClick={confirmDuplicate}>Duplicate Course</Menu.Item><Divider />
					<Menu.Item onClick={handlePreviewCertificate} disabled={disabled}>Preview Certificate</Menu.Item><Divider />
					<Menu.Item onClick={handleViewFunnel} disabled={disabled}>View Funnel</Menu.Item>
					<Menu.Item onClick={()=>closePopup()}><CopyToClipboard text={link2}><span>Copy Funnel Link</span></CopyToClipboard></Menu.Item><Divider />
					<Menu.Item><Checkbox label="Live" checked={status === 1} disabled={disabled} onChange={(e,d)=>toggleStatus(d.checked)} toggle /></Menu.Item><Divider />
					<Menu.Item onClick={confirmDelete}>Delete Course</Menu.Item>
				</Menu>
			</Popup>
			<Confirm
				caption="Edit Course"
				message="This course will be taken offline for editing. Do you wish to continue?"
				open={editOpen} setOpen={setEditOpen}
				onConfirm={props.onEdit} />
			<DeleteCoursePopup key="deleteCoursePopup"
				open={deleteOpen} setOpen={setDeleteOpen}
				onConfirm={handleDelete} />
			<Confirm
				caption="Duplicate Course"
				message="Make a duplicate copy of this course?"
				open={duplicateOpen} setOpen={setDuplicateOpen}
				onConfirm={handleDuplicate} />
		</>
	);
}

function AddCourseCard(props) {
	return (
		<Card className="add-course-card">
			<Card.Content textAlign="center">
				<br /><br />
				<br /><br />
				<Image src={PageAddImage} size="small" />
			</Card.Content>
			<Card.Content extra>
				<Button color="blue" onClick={props.add} fluid>Add a Course</Button>
			</Card.Content>
		</Card>
	);
}

function CourseCard(props) {
	const app		= props.app;
	const item		= props.item;
	const navigate	= props.navigate;
	const [updateOpen,setUpdateOpen] = useState(false);
	const [updateText,setUpdateText] = useState(''	 );
	const statusProps = CourseStatusProperties[item.status];
	const handleUpdate = () => navigate('/course/' + item.id + '/details/');
	const confirmUpdate = () => {
		if (parseInt(item.status) !== 1) handleUpdate(); else {
			setUpdateText('A course has to be taken offline for editing. Do you wish to continue?');
			setUpdateOpen(true);
		}
	}

	return (
		<Card raised>
			<Card.Content>
				<Form>
					<Form.Group>
						<Form.Field width={10}>
							<Label color={statusProps.color}>
								<Icon name={statusProps.icon} />
								{statusProps.text}
							</Label>
						</Form.Field>
						<Form.Field width={6}>
							<div style={{textAlign:'right'}}>
								<CourseMenu app={app} item={item}
									setCourseTags={props.setCourseTags}
									setCourses={props.setCourses}
									onEdit={handleUpdate} />
							</div>
						</Form.Field>
					</Form.Group>
					<Header size="small">Course Name</Header>
					<Header style={{minHeight:'64px'}}  size="large">{item.name}</Header>
					<Form.Group>
						<Form.Field width={4}><label>&nbsp;&nbsp;Visitors</label><Label className="ui fluid"><strong>{item.daily1}<br />Daily</strong></Label></Form.Field>
						<Form.Field width={4}><label>&nbsp;&nbsp;</label><Label className="ui fluid"><strong>{item.total1}<br />Total</strong></Label></Form.Field>
						<Form.Field width={4}><label>&nbsp;&nbsp;Signups</label><Label className="ui fluid"><strong>{item.daily2}<br />Daily</strong></Label></Form.Field>
						<Form.Field width={4}><label>&nbsp;&nbsp;</label><Label className="ui fluid"><strong>{item.total2}<br />Total</strong></Label></Form.Field>
					</Form.Group>
					<Form.Field>
						<label>Tags</label>
						<TagList tags={item.tags} />
					</Form.Field>
				</Form>
			</Card.Content>
			<Card.Content extra>
				<Button onClick={confirmUpdate} color="blue" fluid>Edit Course</Button>
			</Card.Content>
			<Confirm
				caption={'Edit Course'} message={updateText}
				open={updateOpen} setOpen={setUpdateOpen}
				onConfirm={handleUpdate} />
		</Card>
	)
}

export default function CoursesPage(props) {
	const app = useContext(AppContext);
	const navigate = useNavigate();
	const [courseList, setCourseList] = useState([]	 );
	const [courseTags, setCourseTags] = useState(null);
	const [filterTags, setFilterTags] = useStore('courses/filter_tags',null	);
	const [alphaOrder, setAlphaOrder] = useStore('courses/alpha_order','A'	);
	const [searchText, setSearchText] = useStore('courses/search_text',''	);

	useEffect(() => {
		setCourseTags(null);
	},[app.courses]);

	useEffect(() => {
		if (app.courses.length > 0 && courseTags === null) {
			var sourceTags = [];
			var targetTags = filterTags;
			if (targetTags === null) targetTags = [];
			app.courses.forEach(course => {
				if (course.tags !== '') sourceTags = [
					...new Set([...sourceTags, ...course.tags.split(',')])];})
			targetTags = targetTags.filter(item => sourceTags.includes(item));
			sourceTags = sourceTags.filter(item =>!targetTags.includes(item));
			setCourseTags(sourceTags.sort());
			setFilterTags(targetTags);
		}
	},	[app.courses,courseTags,filterTags,setFilterTags]);

	useEffect(() => {
		function FilterCourseList() {
			var courseList = [];
			var filterText = searchText.toLowerCase();
			app.courses.forEach(course => {
				var selected = true;
				if (searchText !== '') selected = selected && course.name.toLowerCase().includes(filterText);
				if (selected && filterTags.length > 0) {
					var count = filterTags.length;
					const courseTags = course.tags !== '' ? course.tags.split(',') : [];
					selected = selected && courseTags.filter(item => filterTags.includes(item)).length === count; }
				if (selected) courseList.push(course); });
			if (alphaOrder === 'Z')
				courseList = courseList.reverse();
			setCourseList(courseList);
		}
		if (app.courses !== null && filterTags !== null) FilterCourseList();
	}	,[
			app			,
			app.courses	,
			filterTags	,
			searchText	,
			alphaOrder
	]);

	const CoursesFilterBar = props => {
		const [searchEdit, setSearchEdit] = useState(searchText);
		const handleSearch = () => setSearchText(searchEdit);
		return (
			<Segment>
				<Grid>
					<Grid.Column width={8} textAlign="left" verticalAlign="middle">
						<label>Filter by tags: </label>
						<TagSelector
							source={courseTags} setSource={setCourseTags}
							target={filterTags} setTarget={setFilterTags}
							remove />
						<TagSelector
							source={courseTags} setSource={setCourseTags}
							target={filterTags} setTarget={setFilterTags} />
					</Grid.Column>
					<Grid.Column width={2} textAlign="right" verticalAlign="middle">
						<Dropdown options={SortingOptions} value={alphaOrder} onChange={(e,d)=>setAlphaOrder(d.value)} />
					</Grid.Column>
					<Grid.Column width={3}>
						<Input value={searchEdit} onChange={e => setSearchEdit(e.target.value)}
							icon={<Icon name='search' onClick={handleSearch} link />} fluid />
					</Grid.Column>
					<Grid.Column width={3}>
						<Button color="blue" onClick={props.add} fluid>Add a Course</Button>
					</Grid.Column>
				</Grid>
			</Segment>
		)
	};

	const handleAddCourse = () => navigate('/course/new/details');

	return (
		<Grid padded>
			<Grid.Column width={16}>
				<CoursesFilterBar add={handleAddCourse} />
			</Grid.Column>
			<Grid.Column width={16}>
				<Card.Group centered>
					{courseList !== null && courseList.map(item =>
						<CourseCard key={item.id} app={app} item={item}
							setCourseTags={setCourseTags} navigate={navigate}
							setCourses={setCourseList} />)}
					<AddCourseCard add={handleAddCourse} />
				</Card.Group>
			</Grid.Column>
		</Grid>
	);
}
