import { Card, Header } from "semantic-ui-react"

export function VisitorCard(props) {
	const {counters} = props;
	const value = counters.count1;
	return (
		<Card>
			<Card.Content>
				<Header size="small">Visitors</Header>
				<Header size="huge">{value?value:0}</Header>
			</Card.Content>
		</Card>
	);
}

export function SignupCard(props) {
	const {counters} = props;
	const value = counters.count2;
	return (
		<Card>
			<Card.Content>
				<Header size="small">Signups</Header>
				<Header size="huge">{value?value:0}</Header>
			</Card.Content>
		</Card>
	);
}

export function CertificateIssuedCard(props) {
	const {counters} = props;
	const value = counters.count3;
	return (
		<Card>
			<Card.Content>
				<Header size="small">Certificates Issued</Header>
				<Header size="huge">{value?value:0}</Header>
			</Card.Content>
		</Card>
	);
}

export function CallToActionCard(props) {
	const {counters} = props;
	const value = counters.count4;
	return (
		<Card>
			<Card.Content>
				<Header size="small">CTA</Header>
				<Header size="huge">{value?value:0}</Header>
			</Card.Content>
		</Card>
	);
}