import { useEffect, useState } from 'react';
import { Card, Form, Header, Icon, Pagination, Table } from 'semantic-ui-react';
import { createSearchData, BasicSearchBar } from '../lib/dates';
import { apiSearchLogs, apiGetLatestLog } from '../lib/api';
import { useStore } from '../lib/hooks';
import { Failure } from '../lib/alerts';

const LogModes = [
	{name:'info circle'			,color:'blue'	},
	{name:'check circle'		,color:'green'	},
	{name:'exclamation circle'	,color:'orange'	},
	{name:'times circle'		,color:'red'	}
];

export function LogList(props) {
	const { app, pageSize } = props;
	const [searchData	, setSearchData	] = useStore('app/loglist/search',createSearchData());
	const [pageIndex	,setPageIndex	] = useStore('app/loglist/index',0);
	const [pageCount	,setPageCount	] = useState(0);
	const [list			,setList		] = useState([]);
	const [page			,setPage		] = useState([]);
	const [failure		,setFailure		] = useState('');

	useEffect(() =>
		apiSearchLogs(app, searchData, 
			result => {
				setList(result);
				const count = result.length;
				if (count === 0) setFailure('No log entries found.');
				setPageIndex(index => Math.min(index , count));
				setPageCount(Math.ceil(count / pageSize));
				setPage(result.slice(0, pageSize));
			},
			result => {
				setList([]);
				setPageIndex(0);
				setPageCount(0);
				setPage([]);
			}
		),[app,searchData,pageSize,setPageIndex]);
	
	const updatePageIndex = pageIndex => {
		const itemIndex = (pageIndex - 1) * pageSize;
		setPage(list.slice(itemIndex, itemIndex + pageSize));
		setPageIndex(pageIndex);
	};

	/*
	const handleDelete = id => {
		setSuccess('');
		setFailure('');
		apiDeleteLog(app,id,
			result => {
				setSuccess(`Log entry ${result.id} deleted.`);
				setRefresh(!refresh);
			},
			result => {
				setFailure(`Unable to delete log entry. ${result}`);
			}
		);
	}*/

	return (
		<>
			<BasicSearchBar data={searchData} setData={setSearchData} notext={true} />
			<Failure message={failure} />
			{pageCount > 0 &&
			<>
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell collapsing>ID</Table.HeaderCell>
						<Table.HeaderCell collapsing>Type</Table.HeaderCell>
						<Table.HeaderCell>Message</Table.HeaderCell>
						<Table.HeaderCell collapsing>Date</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{page.map(item =>
						<Table.Row>
							<Table.Cell>{item.id}</Table.Cell>
							<Table.Cell><div className='cf-center'><Icon {...LogModes[item.mode]} /></div></Table.Cell>
							<Table.Cell>{item.text}</Table.Cell>
							<Table.Cell>{item.createdAt}</Table.Cell>
						</Table.Row>
					)}
				</Table.Body>
			</Table>
			<Form>
				<Form.Group>
					<Form.Field width={16} className="cf-right">
						{pageCount > 1 &&
						<Pagination
							size="tiny"
							activePage={pageIndex}
							totalPages={pageCount}
							boundaryRange={1} siblingRange={4}
							showEllipses={true} firstItem={null} lastItem={null}
							onPageChange={(e,d)=> updatePageIndex(d.activePage)}
						/>}
					</Form.Field>
				</Form.Group>
			</Form>
			</>}
		</>
	);
}

export function LogCard(props) {
	const app = props.app;
	const [entry,setEntry] = useState(null);
	useEffect(() => apiGetLatestLog(app,result => setEntry(result)),[app]);
	return (
		<Card fluid>
			<Card.Content>
				<Icon className="cf-float-right" name="list ul" onClick={props.onSettings} link />
				<div className="cf-float-left"><Header size="small">Log</Header></div>
				<div className="cf-clear">
					<br />{entry === null?<div><i>none</i></div>:
					<>
						<p>{entry.text}</p>
						<Icon className="cf-float-left" {...LogModes[entry.mode]} />
						<div className="cf-float-right"><small>{entry.createdAt}</small></div>
					</>}
				</div>
			</Card.Content>
		</Card>
	);
}