import {useState} from 'react';
import {Button, Grid, Menu, Modal, Table} from 'semantic-ui-react';

export function SiteTemplateSelector(props) {
	const app = props.app;
	const [templateId, setTemplateId] = useState(props.templateId??'');
	const [open, setOpen] = useState(props.opened);
	const handleTemplateSelect = () => {
		props.setSiteTemplateId(templateId);
		setOpen(false);
	};

	const RenderTemplate = props => {
		var count = 0;
		const template = app.siteTemplates.find(item => item.id === props.id);
		if (!template) return <></>;
		const questions = JSON.parse(template.data);
		return (<Table>
			<Table.Row>
				<Table.HeaderCell collapsed style={{textAlign:'center'}}>#</Table.HeaderCell>
				<Table.HeaderCell collapsed>Label</Table.HeaderCell>
				<Table.HeaderCell>Question</Table.HeaderCell>
			</Table.Row>
			{questions.map(item => <Table.Row>
				<Table.Cell collapsed style={{textAlign:'center'}}>{++count}.</Table.Cell>
				<Table.Cell collapsed>{item.name}</Table.Cell>
				<Table.Cell>{item.prompt}</Table.Cell>
			</Table.Row> )}
		</Table>);
	};

	const closeable = props.siteTemplateId && props.siteTemplateId !== '';

	return (
		<>
		<Modal size="large"
			trigger={<Button color="blue" fluid>Select Survey Template</Button>}
			open={open}
			closeOnEscape={closeable}
			closeOnDimmerClick={closeable}
			onClose={() => setOpen(false)}
		>
		<Modal.Header>Survey Templates</Modal.Header>
		<Modal.Content>
			<Grid padded>
				<Grid.Column width={4}>
				<Menu vertical>
					{app.siteTemplates.map(item =><Menu.Item active={item.id === templateId} onClick={()=>setTemplateId(item.id)}>{item.name}</Menu.Item>)}
				</Menu>
				</Grid.Column>
				<Grid.Column width={12}>
					<RenderTemplate id={templateId} />
				</Grid.Column>
			</Grid>
		</Modal.Content>
		<Modal.Actions>
			<Button disabled={templateId === ''} onClick={handleTemplateSelect} primary>Confirm</Button>
			<Button onClick={() => setOpen(false)} primary>Close</Button>
		</Modal.Actions>
	  </Modal>
		</>
	  );
}