import { useContext } from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { AppContext } from '../app/AppContext';
import { useStore } from '../lib/hooks';
import AccountProfileSection from './profile/Account';
import PaymentProfileSection from './profile/Payments';
import LogProfileSection from './profile/Logs';

export default function ProfilePage(props) {
	const app = useContext(AppContext);
	const [section, setSection] = useStore('guest/profile/section','account');

	const RenderSection = props => {
		const section = props.section;
		if (section === 'account'	) return <AccountProfileSection app={app} />;
		if (section === 'payments'	) return <PaymentProfileSection app={app} />;
		if (section === 'logs'		) return <LogProfileSection		app={app} />;
		return <></>;
	};

	return (
		<>
		<Grid
			columns={1}
			centered padded>
			<Grid.Column width={12}>
				<Menu widths={3}>
					<Menu.Item active={section==='account'	} onClick={()=>setSection('account'	)}>Account</Menu.Item>
					<Menu.Item active={section==='payments'	} onClick={()=>setSection('payments')}>Payments</Menu.Item>
					<Menu.Item active={section==='logs'		} onClick={()=>setSection('logs'	)}>Logs</Menu.Item>
				</Menu>
			</Grid.Column>
		</Grid>
		<RenderSection section={section} />
		</>
	)
}