import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Grid, Header, Icon } from 'semantic-ui-react';
import { AppContext } from '../app/AppContext';
import { Comment, Failure, Loading, Success } from '../lib/alerts';
import { PasswordValidate } from '../lib/passwords';
import { apiSignUp } from '../lib/api';

export default function SignUpPage(props) {
	const app = useContext(AppContext);
	const [name				,setName			] = useState('');
	const [mail				,setMail			] = useState('');
	const [password			,setPassword		] = useState('');
	const [passwordLevel	,setPasswordLevel	] = useState(0);
	const [hidePassword		,setHidePassword	] = useState(true);
	const [valid			,setValid			] = useState(false);
	const [loading			,setLoading			] = useState('');
	const [success			,setSuccess			] = useState('');
	const [failure			,setFailure			] = useState('');

	useEffect(() => setValid(name !== '' && mail !== '' && passwordLevel > 0),[name, mail, password, passwordLevel]);

	const handleSignUp = e => {
		e.preventDefault();
		setFailure('');
		setLoading('Signing you up for a new account ...');
		const data = { name:name, mail:mail, password:password };
		apiSignUp(app, data,
			result => { setLoading(''); setSuccess(result); },
			result => { setLoading(''); setFailure(result); }
		);
		return false;
	};

	const RenderSuccess = props => {
		if (props.message === '') return <></>;
		return <Success icon="check circle" message={<span>{props.message} An email has been sent to you to activate the account before you can sign in&nbsp;<Link className="cf-link" to="/signin">here</Link>.</span>} />;
	};

	const RenderFailure = props => {
		if (props.message === '') return <></>;
		return <Failure icon="times circle" message={props.message} />
	}

	return (
		<Grid columns={1} centered padded>
			<Grid.Column>
				<Header size="large">SIGN UP</Header><br />
				{success === '' &&
				<Form>
					<Form.Input label="Name" value={name} onChange={(e,d) => setName(d.value)} onBlur={() => setName(name.trim())} autoFocus />
					<Form.Input label="Email" value={mail} onChange={(e,d) => setMail(d.value)} onBlur={() => setMail(mail.trim())} />
					<Form.Input label="Password" type={hidePassword?'password':'text'} value={password} onChange={(e,d) => setPassword(d.value)} onBlur={() => setPassword(password.trim())} icon={<Icon name={hidePassword?'eye slash':'eye'} onClick={()=>setHidePassword(!hidePassword)} link />}/>
					<Form.Field className="cf-right"><PasswordValidate password={password} level={passwordLevel} setLevel={setPasswordLevel} /></Form.Field>
					<Loading message={loading} />
					<RenderFailure message={failure} />
					{loading === '' &&<>
					<Form.Button color="blue" disabled={loading || !valid} onClick={handleSignUp}>Sign Up</Form.Button><div />
					<Form.Field><Failure icon="times circle" message={failure} /></Form.Field>
					<Form.Field><Comment icon="info circle" message={<span>Already have an account? Sign in&nbsp;<Link className="cf-link" to="/signin">here</Link>.</span>} /></Form.Field></>}
				</Form>}
				<RenderSuccess message={success} />
			</Grid.Column>
		</Grid>
	);
}