import { useContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AppContext } from './AppContext';
import { CertFunnel } from './hosts/CertFunnel';
import { FunnelSite } from './hosts/FunnelSite';
import { UserPortal } from './hosts/UserPortal';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

const HOST_TESTING			= 0;
const HOST_DEFAULT			= 1;
const HOST_STAGING			= 1;
const HOST_CERTFUNNEL		= 2;
const HOST_CLAIMCERTIFICATE	= 3;
const HOST_ACCREDEMY_PORTAL	= 4;

function getAppHost() {
	const hostName = window.location.hostname;
	if (hostName.endsWith('localhost'					)) return HOST_DEFAULT			;
	if (hostName.endsWith('appstaging.certfunnel.com'	)) return HOST_STAGING			;
	if (hostName.endsWith('app.certfunnel.com'			)) return HOST_CERTFUNNEL		;
	if (hostName.endsWith('claimcertificate.com'		)) return HOST_CLAIMCERTIFICATE	;
	if (hostName.endsWith('portal.accredemy.com'		)) return HOST_ACCREDEMY_PORTAL	;
	return HOST_TESTING;
}

function getApp(version,devMode) {
	const appHost = getAppHost();
	switch (appHost) {
		case HOST_STAGING:
			return {
				appName: 'Certificate Funnel (staging)',
				appBase: devMode? 'http://localhost:3000'					: 'https://appstaging.certfunnel.com'					,
				apiBase: devMode? 'http://localhost:3006/public/api.php'	: 'https://appstaging.certfunnel.com/api.php'			,
				resBase: devMode? 'http://localhost:3006'					: 'https://appstaging.certfunnel.com'					,
				pubBase: devMode? 'http://localhost:3000/claimcertificate'	: 'https://appstaging.certfunnel.com/claimcertificate'	,
				usrBase: devMode? 'http://localhost:3000/accredemy_portal'	: 'https://appstaging.certfunnel.com/accredemy_portal'	,
				appHost,
				version,
				devMode
			};
		case HOST_CERTFUNNEL:
			return {
				appName: 'Certificate Funnel',
				appBase: devMode? 'http://localhost:3000'					: 'https://app.certfunnel.com'							,
				apiBase: devMode? 'http://localhost:3006/public/api.php'	: 'https://app.certfunnel.com/api.php'					,
				resBase: devMode? 'http://localhost:3006'					: 'https://app.certfunnel.com'							,
				pubBase: devMode? 'http://localhost:3000/claimcertificate'	: 'https://claimcertificate.com'						,
				usrBase: devMode? 'http://localhost:3000/accredemy_portal'	: 'https://portal.accredemy.com'						,
				version,
				appHost,
				devMode
			};
		case HOST_CLAIMCERTIFICATE:
			return {
				appName: 'Claim Certificate',
				appBase: devMode? 'http://localhost:3000'					: 'https://claimcertificate.com'						,
				apiBase: devMode? 'http://localhost:3006/public/api.php'	: 'https://claimcertificate.com/api.php'				,
				resBase: devMode? 'http://localhost:3006'					: 'https://app.certfunnel.com'							,
				pubBase: devMode? 'http://localhost:3000'					: 'https://claimcertificate.com'						,
				usrBase: devMode? 'http://localhost:3000/accredemy_portal'	: 'https://portal.accredemy.com'						,
				version,
				appHost,
				devMode
			};
		case HOST_ACCREDEMY_PORTAL:
			return {
				appName: 'Accredemy Portal',
				appBase: devMode? 'http://localhost:3000'					: 'https://portal.accredemy.com'						,
				apiBase: devMode? 'http://localhost:3006/public/api.php'	: 'https://portal.accredemy.com/api.php'				,
				resBase: devMode? 'http://localhost:3006'					: 'https://app.certfunnel.com'							,
				pubBase: devMode? 'http://localhost:3000/claimcertificate'	: 'https://claimcertificate.com'						,
				usrBase: devMode? 'http://localhost:3000'					: 'https://portal.accredemy.com'						,
				version,
				appHost,
				devMode
			};
		default:
			return {
				appName: 'Certificate Funnel (testing)',
				appBase: 'http://localhost:3000'					,
				apiBase: 'http://localhost:3006/public/api.php'		,
				resBase: 'http://localhost:3006/public'				,
				pubBase: 'http://localhost:3000/claimcertificate'	,
				usrBase: 'http://localhost:3000/accredemy_portal'	,
				version,
				appHost,
				devMode
			};
	}
}

function ApplicationName(props) {
	const app = useContext(AppContext);
	return (
		<Helmet>
			<title>{app.appName}</title>
		</Helmet>
	);
}

function ApplicationHost(props) {
	switch (useContext(AppContext).appHost) {
		case HOST_STAGING			: return <CertFunnel />;
		case HOST_CERTFUNNEL		: return <CertFunnel />;
		case HOST_CLAIMCERTIFICATE	: return <FunnelSite />;
		case HOST_ACCREDEMY_PORTAL	: return <UserPortal />;
		default						: return <></>;
	}
}

export default function App(props) {
	const [app] = useState(getApp(props.version, props.devMode));
	return (
		<BrowserRouter>
			<AppContext.Provider value={app}>
				<ApplicationName />
				<ApplicationHost />
			</AppContext.Provider>			
		</BrowserRouter>
	);
}