import { Dropdown } from 'semantic-ui-react';
import './fonts.css';

export const FontList = [
	"Alice",
	"Archivo Black",
	"Big Shoulders Text",
	"Candal",
	"Crete Round",
	"Dancing Script",
	"Grenze Gotisch",
	"Lato",
	"Rozha One",
];

export function FontSelector(props) {
	const value = props.value;
	const setValue = props.setValue;
	const FontItem = props => {
		const item = props.item;
		return (
			<Dropdown.Item 
				onClick={()=>setValue(item)}
				style={{fontFamily:item}}
				active={item === value}
				text={item} />
		);
	};
	return (
		<Dropdown
			className="icon selection" icon="font"
			style={{fontFamily:value}} text={value}
			button fluid labeled>
			<Dropdown.Menu>
				{FontList.map(item =><FontItem key={item} item={item} />)}
			</Dropdown.Menu>
		</Dropdown>
	);
}

const WeightList = [
	"100",
	"200",
	"300",
	"400",
	"500",
	"600",
	"700",
	"800",
	"900"
];

export function FontWeightSelector(props) {
	const value = props.value;
	const setValue = props.setValue;
	const WeightItem = props => {
		const item = props.item;
	return (
		<Dropdown.Item 
			onClick={()=>setValue(item)}
			style={{fontWeight:item}}
			active={item === value}
			text={item} fluid />
		);
	};
	return (
		<Dropdown 
			className="icon selection" icon="bold"
			style={{fontWeight:value}} text={value}
			button fluid labeled>
			<Dropdown.Menu>
				{WeightList.map(item =><WeightItem key={item} item={item} />)}
			</Dropdown.Menu>
		</Dropdown>
	);
}