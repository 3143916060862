import {Grid } from 'semantic-ui-react';
import { LogList } from '../../app/Log';

export default function LogProfileSection(props) {
	const {app} = props;
	return (
		<Grid columns={1}
			padded centered>
			<Grid.Column width={12}>
				<LogList app={app} pageSize={10} />
			</Grid.Column>
		</Grid>
	);
}