import { createRef, useState } from 'react';
import { Button, Card, Header, Icon, Image, Modal, Popup } from 'semantic-ui-react';
import { Comment, Confirm, Failure, Loading, Success } from './alerts';
import { apiUploadBitmap, apiDeleteBitmap } from './api';
import PageAddImage from './images/page-add.png';

function getBaseFileName(filename){
	filename = filename.replace(/^.*[\\/]/, '');
	var lastDotPosition = filename.lastIndexOf('.');
	if (lastDotPosition === -1) return filename;
	else return filename.substr(0, lastDotPosition);
}

function getImageExtension(filename) {
	const extension = filename.split('.').pop().toLowerCase();
	if (extension === 'jpg' || extension === 'jpeg') return 'jpg';
	if (extension === 'png') return 'png';
	if (extension === 'gif') return 'gif';
	return null;
}

export function ImageSelector(props) {
	const { app, vars, open, setOpen } = props;
	const [ header,path ,setPath	] = vars;
	const [ deleteOpen	, setDeleteOpen	] = useState(false);
	const [ deleteItem	, setDeleteItem	] = useState(false);
	const [ loading		, setLoading	] = useState('');
	const [ success		, setSuccess	] = useState('');
	const [ failure		, setFailure	] = useState('');

	const handleClose = () => {
		setLoading('');
		setSuccess('');
		setFailure('');
		setOpen(false);
	};

	const handleDelete = () => {
		setSuccess('');
		setFailure('');
		setLoading('Delete bitmap image ...');
		apiDeleteBitmap(app, deleteItem.id,
			result => {
				setLoading('');
				setSuccess(`Bitmap image '${result.name}' deleted.`);
				app.deleteBitmap(result);
			},
			result => {
				setFailure(result);
				setLoading('');
			}
		)
	};

	const handleUpload = e => {
		if (loading !== '') return;
		setSuccess('');
		setFailure('');
		const fileName = e.target.value;
		const fileType = getImageExtension(fileName);
		if (fileType === null) {
			setFailure('Upload failed. Unsupported image file format.');
			return;
		}
		const formData	= new FormData();
		const fileList = document.querySelector('#file');
		const fileData = fileList.files[0];
		if (fileData.size > 1000000) {
			setFailure('Upload failed. Image file size above 1MB.');
			return;
		}
		formData.append('name',getBaseFileName(fileName));
		formData.append('data',fileData);
		formData.append('type',fileType);
		setLoading('Uploading image file ...');
		apiUploadBitmap(app, formData,
			result => {
				setLoading('');
				app.insertBitmap(result);
				setPath(result.path);
				handleClose();
			},
			result => {
				setLoading('');
				setFailure(result);
			}
		);
	};

	const EmptyCard = props => {
		const fileInputRef = createRef();
		return (
			<Popup position="top center" content="Upload new image" trigger={
			<Card className="cf-empty-card" onClick={e => fileInputRef.current.click()} fluid>
				<input ref={fileInputRef} id="file" type="file" hidden onChange={handleUpload} />
				<div><Image src={PageAddImage} fluid /></div>
			</Card>} />
		);
	};

	const ImageCard = props => {
		const item = props.item;
		const active = item.path === path;
		const handleImageSelect = e =>{
			if (loading === '') {
				e.stopPropagation();
				setPath( item.path);
				handleClose();
			}
		};
		const handleImageDelete = e => {
			if (loading === '') {
				e.stopPropagation();
				setDeleteItem(item);
				setDeleteOpen(true);
			}
		};

		return (
			<Popup position="top center" content={item.name} trigger={
			<Card className="cf-image-card" color={active?'green':null} onClick={handleImageSelect}>
				<div><Image src={item.path} /></div>
				<nav>
				{!item.shared && !active &&
					<Button onClick={handleImageDelete} icon>
						<Icon link name="trash" />
					</Button>}
				</nav>
			</Card>} />
		);
	};

	return (
		<>
		<Modal
			size="large"
			open={open}
			onClose={handleClose}
			closeOnDimmerClick={false}
			centered>
			<Header>Select {header}</Header>
			<Modal.Content>
				<Comment message="Image files must be jpg, png or gif format and under 1MB in size." />
			</Modal.Content>
			<Modal.Content scrolling>
				<Card.Group itemsPerRow={4}>
					<EmptyCard />
					{app.bitmaps.map(item =><ImageCard key={item.id} item={item} />)}
				</Card.Group>
			</Modal.Content>
			<Modal.Actions>
				<div className="cf-action-bar-alert cf-float-left">
					<Loading message={loading} />
					<Success message={success} />
					<Failure message={failure} />
				</div>
				<Button negative onClick={handleClose}>Cancel</Button>
			</Modal.Actions>
		</Modal>
		{deleteItem &&
			<Confirm
				caption="Delete Image"
				message= {`Delete bitmap image ${deleteItem.name}?`}
				open={deleteOpen} setOpen={setDeleteOpen}
				onConfirm={handleDelete} />}
		</>
	);
}