import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../app/AppContext';
import { Failure, Loading, Success } from '../lib/alerts';
import { apiActivateAccount } from '../lib/api';

export default function ActivateAccountPage(props) {
	const key = useParams().key;
	const app = useContext(AppContext);
	const [loading, setLoading] = useState('');
	const [success, setSuccess] = useState('');
	const [failure, setFailure] = useState('');

	useEffect(() => {
		if (!key) setFailure('Cannot activate account without activation key.'); else {
			setLoading('Attempting to activate account. Please wait ...');
			apiActivateAccount(app, key,
				result => { setLoading(''); setSuccess(result); },
				result => { setLoading(''); setFailure(result); }
			);
		}
	},[app,key]);

	const RenderSuccess = props => {
		if (props.message === '') return <></>;
		return <Success icon="check circle" message={<span>{props.message} You can now sign in to your account&nbsp;<Link className="cf-link" to="/signin">here</Link>.</span>} />;
	};

	const RenderFailure = props => {
		if (props.message === '') return <></>;
		return <Failure icon="times circle" message={<span>Account activation failed. {props.message}</span>} />;
	};

	return (
		<Grid columns={1} padded centered>
			<Grid.Column>
				<Header size="large">ACCOUNT ACTIVATION</Header><br />
				<Loading message={loading} />
				<RenderSuccess message={success} />
				<RenderFailure message={failure} />
			</Grid.Column>
		</Grid>
	);
}