import axios from 'axios';
import {Helmet} from 'react-helmet';
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Button, Checkbox,Form, Grid, Header, Image, Label, Radio, Rating, Segment, Modal} from 'semantic-ui-react';
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton} from 'react-share';
import {FacebookIcon, TwitterIcon, LinkedinIcon} from 'react-share';
import {AppContext} from './AppContext';
import {CertificateCanvas} from '../lib/CertificateLib';
import {useQuery} from '../lib/hooks';
import NotFoundPage from './NotFound';
import { PreviewIndicator } from '../lib/utils';
import {Failure} from '../lib/alerts';
import '../lib/fonts.css';
import { apiGetCourse, apiGetCourseByCustomLink } from '../lib/api';

const SizesToEm = {
	huge	: '2em',
	large	: '1.71428571em',
	medium	: '1.28571429em',
	small	: '1.07142857em'
};

export function FunnelPageContent({app, id, preview, indicator}) {
	const [certId, setCertId] = useState(null);
	const [course, setCourse] = useState(null);
	const [data,setData] = useState(null);
	const [branding, setBranding] = useState(null);
	const [selectedPage,setSelectedPage] = useState(0);
	const [selectedField,setSelectedField] = useState(null);
	const [selectedStyle,setSelectedStyle] = useState(null);
	const [selectedIndex,setSelectedIndex] = useState(-1);
	const [lastIndex,setLastIndex] = useState(-1);
	const [backColor,setBackColor] = useState('#ffffff');
	const [textColor,setTextColor] = useState('#000000');
	const [,setBrandColor] = useState('#000000');
	const [inputColor,setInputColor] = useState('#000000');
	const [buttonBackColor,setButtonBackColor] = useState('#cccccc');
	const [buttonTextColor,setButtonTextColor] = useState('#777777');
	const [headerFont,setHeaderFont] = useState('Lato');
	const [commonFont,setCommonFont] = useState('Lato');
	const [buttonFont,setButtonFont] = useState('Lato');
	const [headerSize,setHeaderSize] = useState('medium');
	const [buttonSize,setButtonSize] = useState('medium');
	const [backButtonText,setBackButtonText] = useState('Back');
	const [nextButtonText,setNextButtonText] = useState('Next');
	const [submitSurveyText,setSubmitSurveyText] = useState('Submit Survey');
	const [headerImage, setHeaderImage] = useState('');
	const [headerFluid, setHeaderFluid] = useState('');
	const [hasSurvey,setHasSurvey] = useState(1);
	const [variables] = useState({});

	const getFileExtension = path => {
		return path.split('.').pop();
	}

	const getVarName = name => {
		return name.replace(/(\s|-)/g,"_").toLowerCase();
	}

	const setVar = (name, value) => {
		variables[getVarName(name)] = value;
	}

	const getVar = (name, value) => {
		const var_name = getVarName(name);
		if (var_name in variables) return variables[var_name];
		variables[var_name] = value;
		return value;
	}

	const replaceVars = text => {
		text = text.replace('@course_name'	, getVar('course_name'	,''));
		text = text.replace('@first_name'	, getVar('first_name'	,''));
		text = text.replace('@last_name'	, getVar('last_name'	,''));
		text = text.replace('@email'		, getVar('email'		,''));
		return text;
	};

	useEffect(() => {
		if (course) {
			axios.get(app.apiBase + '/branding/' + course.userId).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) setBranding(JSON.parse(data.result));
			}).catch(error => console.log(error));
		}
	},[app, course]);

	useEffect(() => {
		if (course === null) {
			apiGetCourse(app, id, result => {
				setCourse(result);
				variables['course_name'] = result.name;
				if (result.siteData !== '' && result.siteData !== '{}')
					setData(JSON.parse(result.siteData));
			});
		}
	},[app, id, course, variables]);

	useEffect(() => {
		if (selectedStyle !== null) {
			setBackColor(selectedStyle.backColor);
			setTextColor(selectedStyle.textColor);
			setBrandColor(selectedStyle.brandColor);
			setInputColor(selectedStyle.inputColor);
			setButtonBackColor(selectedStyle.buttonBackColor);
			setButtonTextColor(selectedStyle.buttonTextColor);
			setHeaderFont(selectedStyle.headerFont);
			setCommonFont(selectedStyle.commonFont);
			setButtonFont(selectedStyle.buttonFont);
			setBackButtonText(selectedStyle.backButtonText);
			setNextButtonText(selectedStyle.nextButtonText);
			setHeaderSize(selectedStyle.headerSize);
			setButtonSize(selectedStyle.buttonSize);
			setSubmitSurveyText(selectedStyle.submitSurveyText);
			setHeaderImage(selectedStyle.headerImage);
			setHeaderFluid(selectedStyle.headerFluid);
			setHasSurvey(selectedStyle.hasSurvey);
		}
	},[selectedStyle]);
	
	useEffect(() => {
		if (data !== null) {
			setSelectedStyle(data[3]);
			setLastIndex(data[0].length - 1);
			setSelectedPage(0);
			setSelectedIndex(0);
			setSelectedField(data[0][0]);
		}
	},[data]);

	useEffect(() => {
		if (!preview) {
			axios.post(app.apiBase + '/funnel/visitor/' + id,{}).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
			}).catch(error => console.log(error));
		}
	},[preview,app,id]);

	const updateOptIn = () => {
		const data = {
			id		: id,
			optIn	: variables['optin'],
			email	: variables['email'],
			name	: variables['first_name'] + ' ' +
					  variables['last_name'	],
		};
		if ((!preview) && data.optIn) {
			axios.post(app.apiBase + '/funnel/optin',data).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
			}).catch(error => console.log(error));
		}
	};

	const updateCTA = () => {
		if (!preview) {
			axios.post(app.apiBase + '/funnel/cta/' + id,{}).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
			}).catch(error => console.log(error));
		}
	};

	const updateCertificate = () => {
		const data = {
			id		: '',
			certId	: course.id,
			userId	: course.userId,
			email	: variables['email'],
			optIn	: variables['optin'],
			name	: variables['first_name'] + ' ' + variables['last_name'],
			data	: JSON.stringify(variables)
		};
		if (!preview) {
			axios.post(app.apiBase + '/funnel/certificate', data).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) setCertId(data.result.id);
			}).catch(error => console.log(error));
		}
	};

	const selectPage = page => {
		if (page !== selectedPage) {
			if (page === 2) updateCertificate();
			const lastIndex = data[page].length - 1;
			const index = (lastIndex >= 0 ? 0 : -1);
			setLastIndex(lastIndex);
			setSelectedIndex(index);
			setSelectedField(data[page][index]);
			setSelectedPage(page);
		}
	};

	const selectItem = item => {
		const items = data[selectedPage];
		const index = items.indexOf(item);
		setSelectedIndex(index);
		setLastIndex(data[selectedPage].length - 1);
		setSelectedField(item);
	};

	const gotoNextItem = () => {
		const items = data[selectedPage];
		var index = selectedIndex;
		while (index < lastIndex) {
			var next_item = items[++index];
			if (next_item.visible !== 0) {
				selectItem(next_item);
				break;
			}
		}
	};

	const gotoPrevItem = () => {
		const items = data[selectedPage];
		var index = selectedIndex;
		while (index > 0) {
			var next_item = items[--index];
			if (next_item.visible !== 0) {
				selectItem(next_item);
				break;
			}
		}
	};

	const RenderBranding = () => {
		if (branding === null) return <></>;
		return (
			<>
			<Helmet>
				{branding.brandName !== '' && <title>{branding.brandName}</title>}
				{branding.siteIcon	!== '' && <link rel="icon" type={'image/' + getFileExtension(branding.siteIcon)} href={app.resBase + branding.siteIcon} />}
			</Helmet>
			</>
		);
	}

	const RenderImage = () => {
		return (
			<>
			{!preview && <RenderBranding />}
			{headerFluid===0&&<div style={{height:'1rem'}}></div>}
			{headerImage !== null && <><Image src={app.resBase + headerImage} centered={headerFluid===0} fluid={headerFluid===1} /><br /></>}
			</>
		);
	};

	const RenderButton = props => {
		return <Button disabled={props.disabled} size={buttonSize} style={{backgroundColor:buttonBackColor,color:buttonTextColor,fontFamily:buttonFont}} onClick={props.onClick}>{props.text}</Button>
	};

	const RenderBackButton = props => {
		const text = props.text || backButtonText;
		return <RenderButton disabled={props.disabled} onClick={gotoPrevItem} text={text} />
	};

	const RenderNextButton = props => {
		const text = props.text || nextButtonText;
		return <RenderButton disabled={props.disabled} onClick={gotoNextItem} text={text} />
	}

	const RenderSurveyButtons = props => {
		const condition = props.condition !== undefined?props.condition:true;
		return (
			<>
				{selectedIndex > 0 && <RenderBackButton /> }
				{selectedIndex < lastIndex && <RenderNextButton disabled={!condition} /> }
				{selectedIndex === lastIndex && <RenderButton disabled={!condition} onClick={()=>selectPage(2)} text={submitSurveyText} />}
			</>
		)
	};

	const RenderDisabled = props => {
		return <RenderNextButton />
	};

	const RenderWelcomeIntro = props => {
		return (
			<>
				<RenderImage />
				<Grid columns={1} padded centered>
					<Grid.Column width={12}>
						<Header size={headerSize} textAlign="center" style={{padding:'1rem',color:textColor,fontFamily:headerFont}}>{replaceVars(selectedField.intro_head)}</Header>
						<p textAlign="center" style={{padding:'1rem',color:textColor,fontFamily:commonFont,fontSize:SizesToEm[buttonSize]}}>{replaceVars(selectedField.intro_body)}</p>
						<div style={{textAlign:'center'}}>
							<RenderButton disabled={false} onClick={gotoNextItem} text={selectedField.intro_next} />
						</div>
						<br />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const RenderPrivacyPolicy = props => {
		const [open,setOpen] = useState(false);
		const [promptBefore,setPromptBefore] = useState('');
		const [promptAfter, setPromptAfter] = useState('');
		useEffect(() => {
			var prompt = selectedField.policy_head;
			var fields = prompt.split('[privacy_policy]');
			if (fields.length > 1) setPromptAfter (replaceVars(fields[1]));
			if (fields.length > 0) setPromptBefore(replaceVars(fields[0]));
			setVar(selectedField.name, false);
		},[]);
		const handleConfirm = () => {
			selectedField.value = true;
			setVar(selectedField.name, true);
			if (selectedIndex < lastIndex) gotoNextItem();
			else { updateOptIn(); selectPage(hasSurvey?1:2);}
		};
		if (selectedField.visible === 0) return <RenderDisabled />;
		return (
			<>
				<RenderImage />
				<Modal size="large" open={open}
					onClose={()=>setOpen(false)}>
					<Modal.Header>Privacy Policy</Modal.Header>
					<Modal.Content scrolling>{replaceVars(selectedField.text)}</Modal.Content>
					<Modal.Actions><Button onClick={() => setOpen(false)}>Close</Button></Modal.Actions>
				</Modal>
				<Grid padded centered columns={1}>
					<Grid.Column width={12} textAlign="center">
						<Header size={buttonSize}>
							<span style={{color:textColor,fontFamily:headerFont}}>{promptBefore}</span>
							<span>
								{selectedField.mode === 0 && <a style={{textDecoration:'underline',color:textColor,fontFamily:headerFont}} href={selectedField.link} rel="noreferrer" target="_blank">Privacy Policy</a>}
								{selectedField.mode === 1 && <a style={{textDecoration:'underline',color:textColor,fontFamily:headerFont}} href='/' onClick={e=>{setOpen(true)}}>Privacy Policy</a>}
							</span>
							<span style={{color:textColor,fontFamily:headerFont}}>{promptAfter}</span>
						</Header>
					</Grid.Column>
					<Grid.Column width={12} textAlign="center">
						<RenderBackButton />
						<RenderButton onClick={handleConfirm} text={selectedField.policy_next} />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const GetCertificateURL = () => {
		const cid = preview?id:certId;
		return app.pubBase + '/certificates/' + cid + '.jpg';
	};

	const RenderFinalPage = props => {
		const item1 = data[selectedPage][0];
		const item2 = data[selectedPage][1];
		const text1 = replaceVars(item1.final_head);
		const text2 = replaceVars(item1.final_body);
		const social_text1 = replaceVars(item2.text1);
		const social_text2 = replaceVars(item2.text2);
		const social_text3 = replaceVars(item2.text3);
		return (
			<>
				<RenderImage />
				<Header size={headerSize} textAlign="center" style={{padding:'1rem',color:textColor,fontFamily: headerFont}}>{text1}</Header>
				{(preview || certId !== null) &&<CertificateCanvas id={certId===''?id:certId} preview={preview && !app.devMode} scale={10} downloadText={item1.buttonText1} buttonSize={buttonSize} buttonStyle={{backgroundColor:buttonBackColor,color:buttonTextColor,fontFamily:buttonFont}} upload={!preview} />}
				{(item2.enabled1===1||item2.enabled2===1||item2.enabled3===1)&&<Header size={headerSize} textAlign="centered" style={{color:textColor,fontFamily:headerFont}}>{item2.text0}</Header>}
				<div style={{textAlign:'center'}}>
					{item2.enabled1===1&& <FacebookShareButton className="ui facebook button" url={GetCertificateURL()} quote={social_text1}><FacebookIcon size="64" round /></FacebookShareButton>}
					{item2.enabled2===1&& <TwitterShareButton className="ui twitter button" url={GetCertificateURL()} title={social_text2}><TwitterIcon size="64" round /></TwitterShareButton>}
					{item2.enabled3===1&& <LinkedinShareButton className="ui linkedin button" url={GetCertificateURL()} title={social_text3} summary={social_text3} source="http://claimcertificate.com"><LinkedinIcon size="64" round /></LinkedinShareButton>}
				</div>
				<Grid columns={1} centered>
					<Grid.Column width={12}>
						<br />
						<p textAlign="center" style={{padding:'1rem',color:textColor,fontFamily: commonFont,fontSize:SizesToEm[buttonSize]}}>{text2}</p>
						<div style={{textAlign:'center'}}>
							<RenderButton
								onClick={()=>{updateCTA();window.open(item1.buttonLink2,'_blank')}}
								text={item1.buttonText2} />
						</div>
						<br />
					</Grid.Column>
				</Grid>
			</>
		);
	};

	const RenderTextField = props => {
		const [value,setValue] = useState('');
		const handleSetValue = e => {
			const value = e.target.value;
			setVar(selectedField.name, value);
			setValue(value);
		};
		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			setValue(value);
		},[]);
		if (selectedField.visible === 0) return <RenderDisabled />;
		const condition = selectedField.required === 0 || value.length > 0;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily: commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
					</Grid.Column>
					<Grid.Column width={12}>
						<Form>
							<Form.Field>
								<div class="ui small input">
									{selectedField.length==='l'&&
										<input type="text"
											style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
											maxLength={200} value={value} onChange={handleSetValue} autoFocus />}
									{selectedField.length==='p'&&
										<textarea rows={4}
											style={{color:inputColor,width:'100%',fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
											maxLength={1000} onChange={handleSetValue} autoFocus>{value}</textarea>}
								</div>
							</Form.Field>
						</Form>
						<br />
						<RenderSurveyButtons condition={condition} />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const RenderCheckField = props => {
		const [value,setValue] = useState(0);
		const handleSetValue = (e,d) => {
			const value = d.checked ? 1 : 0;
			setVar(selectedField.name,value);
			setValue(value);
		};
		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			setValue(value);
		},[]);
		if (selectedField.visible === 0) return <RenderDisabled />;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily:commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
					</Grid.Column>
					<Grid.Column width={12} textAlign="left">
						<Form>
							<Form.Group>
							<Form.Field>
								<Checkbox
									style={{color:inputColor}}
									checked={value === 1}
									onChange={handleSetValue}
									size={buttonSize}
									toggle />
							</Form.Field>
							<Form.Field>
								<span style={{backgroundColor:backColor,color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}>
									{value === 1 ? 'Yes' : 'No' }
								</span>
							</Form.Field>
							</Form.Group>
						</Form>
						<br />
						<RenderSurveyButtons />
					</Grid.Column>
				</Grid>
			</>
		);
	}

	const RenderCheckList = props => {
		const [value,setValue] = useState('');
		const [values, setValues] = useState([]);

		const handleSetValue = (item, checked) => {
			if (checked) {
				const value = item.value;
				setVar(selectedField.name, value);
				setValue(value);
			}
		};

		const handleToggleValue = (item, checked) => {
			const new_items = checked?[...values, item.value]:values.filter(x => x !== item.value);
			const new_value = new_items.join(',');
			setVar(selectedField.name, new_value);
			setValue (new_value);
			setValues(new_items);
		}

		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			if (selectedField.multiple)
				setValues(value.split(/(\s*,\s*)/));
			else setValue(value);
		},[]);

		const RenderMultiple = props => {
			const item = props.item;
			return (
				<Form.Field>
					<div>
						<Checkbox checked={values.indexOf(item.value) >= 0} style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
							size={buttonSize} onChange={(e,d) => handleToggleValue(item, d.checked)} />
						<span style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}>&nbsp;{item.text}</span>
					</div>
				</Form.Field>
			);
		}

		const RenderSingular = props => {
			const item = props.item;
			return (
				<Form.Field>
					<div>
					<Radio checked={item.value === value} style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}
						size={buttonSize} onChange={(e,d) => handleSetValue(item, d.checked)} />
						<span style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}>&nbsp;{item.text}</span>
					</div>
				</Form.Field>
			);
		}

		if (selectedField.visible === 0) return <RenderDisabled />;
		const condition = selectedField.required === 0 || value.length > 0;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily:commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
						{selectedField.multiple === 1 && selectedField.required !== 0 && <Label size={buttonSize}>You must select at least one item</Label>}
					</Grid.Column>
					<Grid.Column width={12} textAlign="left">
						<Form>
						{selectedField.multiple === 0
							?selectedField.items.map(item => <RenderSingular key={item.key} item={item} />)
							:selectedField.items.map(item => <RenderMultiple key={item.key} item={item} />)}
						</Form>
						<br />
						<RenderSurveyButtons condition={condition} />
					</Grid.Column>
				</Grid>
			</>
		);
	};

	const RenderRating = props => {
		const [value,setValue] = useState(0);
		useEffect(() => {
			const value = getVar(selectedField.name, selectedField.value);
			setValue(value);
		},[]);
		const handleSetValue = (e, d) => {
			const value = d.rating;
			setVar(selectedField.name, value);
			setValue(value);
		};
		if (selectedField.visible === 0) return <RenderDisabled />;
		const condition = selectedField.required === 0 || value !== 0;
		return (
			<>
				<RenderImage />
				<Grid padded centered columns={1}>
					<Grid.Column width={12}>
						<Header size={buttonSize} style={{color:textColor,fontFamily:commonFont}}>
							{selectedPage===1&&''+(selectedIndex + 1)+'. '}
							{replaceVars(selectedField.prompt)}
						</Header>
					</Grid.Column>
					<Grid.Column width={12} textAlign="left">
						<Form>
							<Form.Field>
								<label style={{color:inputColor,fontFamily:buttonFont,fontSize:SizesToEm[buttonSize]}}><i>{selectedField.label}</i></label>
								<Rating icon='star' color='yellow' size={buttonSize} rating={value} maxRating={5} onRate={handleSetValue} clearable={true} />
							</Form.Field>
						</Form>
						<br />
						<RenderSurveyButtons condition={condition} />
					</Grid.Column>
				</Grid>
			</>
		);
	};

	const RenderSelectedField = props => {
		if (selectedPage === 1 && hasSurvey === 0) {
			selectPage(2);
			return;
		}
		
		switch(selectedField.type) {
			case  0: return <RenderTextField key={props.key} />;
			case   1: return <RenderCheckField key={props.key} />;
			case   2: return <RenderCheckList key={props.key} />;
			case   3: return <RenderRating key={props.key} />;
			case  90: return <RenderWelcomeIntro key={props.key} />;
			case  91: return <RenderPrivacyPolicy key={props.key} />;
			case  98: return <RenderFinalPage key={props.key} />;
			case  99: return <RenderFinalPage key={props.key} />;
			default: return <></>;
		}
	};

	if (course === null) return <></>;
	if (!preview && parseInt(course.status) !== 1) {
		var message = course.offlineReason;
		if (!message) message = "Sorry, this site is currently offline. Please contact the administrator or check back at a later time.";
		return <Failure caption="Not Available" message={message} contained />;
	}

	return (
		<>
		<PreviewIndicator preview={preview && indicator} />
		<Segment style={{minHeight:'720px',padding:0,margin:0,backgroundColor:backColor}}>
			{selectedField !== null&&<RenderSelectedField key={selectedField.name} />}
		</Segment>
		</>
	);
}

function getLinkBase(path) { const index = path.indexOf('/'); return index < 0 ?path: path.substring( 0, index); }
function getLinkPath(path) { const index = path.indexOf('/'); return index < 0 ?''	: path.substring(index + 1);}

export default function FunnelLink(props) {
	const query = useQuery();
	const app = useContext(AppContext);
	const [cid, setCid] = useState(props.id?props.id:query.get('id'));
	const preview = props.preview || query.get("preview") === 'true';
	const indicator = props.indicator === undefined  || props.indicator === true;
	const pathName = useLocation().pathname.substring(1);
	const linkBase = getLinkBase(pathName);
	const linkPath = getLinkPath(pathName);
	useEffect(() => {
		if (!cid)
			apiGetCourseByCustomLink(app,linkBase,linkPath,
				result => setCid(result.id),
				result => setCid(''));
	},[cid, app, linkBase, linkPath]);

	if (cid === null) return <></>;
	if (cid === ''	) return <NotFoundPage />;
//	return <FunnelPageContent app={app} id={cid} preview={false} indicator={indicator} />;
	return <FunnelPageContent app={app} id={cid} preview={preview} indicator={indicator} />;
}