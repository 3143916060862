export const FontUrls = {
	"Alice":"url(https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6GcOrgs.ttf)",
	"Archivo Black":"url(https://fonts.gstatic.com/s/archivoblack/v17/HTxqL289NzCGg4MzN6KJ7eW6CYyF-w.ttf)",
	"Big Shoulders Text":"url(https://fonts.gstatic.com/s/bigshoulderstext/v15/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q7TUFMg.ttf)",
	"Candal":"url(https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8c9BhQN.ttf)",
	"Crete Round":"url(https://fonts.gstatic.com/s/creteround/v14/55xoey1sJNPjPiv1ZZZrxK170bg.ttf)",
	"Dancing Script":"url(https://fonts.gstatic.com/s/dancingscript/v23/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup5.ttf)",
	"Grenze Gotisch":"url(https://fonts.gstatic.com/s/grenzegotisch/v11/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9kd4qZ.ttf)",
	"Lato":"url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wWw.ttf)",
	"Rozha One":"url(https://fonts.gstatic.com/s/rozhaone/v13/AlZy_zVFtYP12Zncg2kRcn38.ttf)",
};
