import moment from 'moment';
import { useEffect, useState } from 'react';
import { Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DateFormat = 'YYYY-MM-DD';
export const DatePickerFormat = 'yyyy-MM-dd';
export const DateModeOptions = [
	{key:'0', value:'0', text:'Today'	},
	{key:'1', value:'1', text:'Week'	},
	{key:'2', value:'2', text:'Month'	},
	{key:'3', value:'3', text:'Year'	},
	{key:'4', value:'4', text:'Range'	},
	{key:'5', value:'5', text:'All'		}
];

export function createSearchData() {
	return {
		text	:''	,
		dateMode:0	,
		dateFrom:moment().format(DateFormat),
		dateTo	:moment().format(DateFormat)
	};
}

export function BasicSearchBar(props) {
	const {data,setData,notext} = props;
	const [text		,setText	] = useState('');
	const [dateMode	,setDateMode] = useState(0 );
	const [dateFrom	,setDateFrom] = useState('');
	const [dateTo	,setDateTo	] = useState('');

	useEffect(() => {
		setText(data.text);
		setDateMode(data.dateMode);
		setDateFrom(data.dateFrom);
		setDateTo(data.dateTo);
	},[data]);

	const updateText = () => setData({ ...data, text:text });

	const updateDateMode = value => {
		const dateMode = parseInt(value);
		setDateMode(dateMode); setData({ ...data, dateMode:dateMode });
	};

	const updateDateFrom = value => {
		const dateFrom = moment(value).format(DateFormat);
		setDateFrom(dateFrom); setData({ ...data, dateFrom:dateFrom });
	};

	const updateDateTo = value => {
		const dateTo = moment(value).format(DateFormat);
		setDateTo(dateTo); setData({ ...data, dateTo:dateTo });
	};
	
	return (
		<Form>
			<Form.Group>
				{!notext && <Form.Field width={6}><Input value={text} onChange={e => setText(e.target.value)} icon={<Icon name='search' onClick={updateText} link />} autoFocus fluid /></Form.Field>}
				<Form.Field width={notext?6:4}><Dropdown placeholder='Select Date' options={DateModeOptions} value={dateMode + ''} onChange={(e,d)=>updateDateMode(d.value)} selection fluid /></Form.Field>
				{dateMode === 4 && <Form.Field width={notext?5:3}><DatePicker dateFormat={DatePickerFormat} selected={new Date(dateFrom)} onChange={date => updateDateFrom(date)} /></Form.Field>}
				{dateMode === 4 && <Form.Field width={notext?5:3}><DatePicker dateFormat={DatePickerFormat} selected={new Date(dateTo)} onChange={date => updateDateTo(date)} /></Form.Field>}
			</Form.Group>
		</Form>
	);
}

export function getExpiredDays(dateExpired) {
	const milliseconds = new Date() - new Date(dateExpired);
	const millisecondsInDays = 1000 * 60 * 60 * 24;
	const days = milliseconds / millisecondsInDays;
	return Math.floor(days);
}


export const DateFormatOptions = [
	MakeDateOption('MMMM Do YYYY'),
	MakeDateOption('MMM Do YYYY'),
	MakeDateOption('MMMM YYYY'),
	MakeDateOption('MMM YYYY'),
	MakeDateOption('YYYY')
];

function MakeDateOption(format) {
	return { key: format, value: format, text: moment().format(format) };
}

export function DateFormatSelector(props) {
	const value = props.value;
	const setValue = props.setValue;
	const DateItem = props => {
		const item = props.item;
		return (
			<Dropdown.Item 
				onClick={()=>setValue(item.value)}
				active={item.value === value}
				text={item.text} />
		);
	};
	const item = DateFormatOptions.find(item => item.value === value);
	if (!item) return <></>;
	return (
		<Dropdown 
			className="icon selection" icon="calendar"
			text={item.text} button fluid labeled>
			<Dropdown.Menu>
				{DateFormatOptions.map(item =><DateItem key={item.key} item={item} />)}
			</Dropdown.Menu>
		</Dropdown>
	);
}