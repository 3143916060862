import axios from 'axios';
import { useEffect, useState} from 'react';
import {Form, Grid } from 'semantic-ui-react';
import { useStore } from '../../lib/hooks';
import {Success,Failure} from '../../lib/alerts';
import Mailer1 from '../../lib/images/mailer_1.png';
import Mailer2 from '../../lib/images/mailer_2.png';
import Mailer3 from '../../lib/images/mailer_3.png';

const MailerOptions = [
	{key:'mailer_0',value:'0',text:'None'			},
	{key:'mailer_1',value:'1',text:'Mailchimp'	, image:{src:Mailer1}},
	{key:'mailer_2',value:'2',text:'MailerLite'	, image:{src:Mailer2}},
	{key:'mailer_3',value:'3',text:'ConvertKit' , image:{src:Mailer3}}
];

const MailerGroupNames = [
	'none',
	'Mailchimp audience',
	'MailerLite group',
	'ConvertKit form'
];

export function MailerSettingSection(props) {
	const app = props.app;
	const [mailer	, setMailer		] = useStore('guest/setting/mailer/mailer'		,app.session.mailer		);
	const [mailerKey, setMailerKey	] = useStore('guest/setting/mailer/mailerKey'	,app.session.mailerKey	);
	const [success	, setSuccess	] = useState('');
	const [failure	, setFailure	] = useState('');

	let groups = null;
	let tags = null;

	useEffect(() => () => {
		if (tags !== null) app.setMailingTags(tags);
		if (groups !== null) app.setMailingGroups(groups);
	},[app,groups,tags]);

	const validateMailerData = () => {
		if (mailer !== 0 && mailerKey.trim().length === 0) setFailure('API Key required.'); else
		return {mailer:mailer,mailerKey:mailerKey.trim()};
		return false;
	};

	const handleUpdateMailer = () => {
		setSuccess('');
		setFailure('');
		const data = validateMailerData();
		if (data) {
			axios.post(app.apiBase + '/settings/mailer' ,data).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) {
					const result = data.result;
					app.session.mailer	= result[0].mailer;
					app.session.mailKey	= result[0].mailerKey;
					groups = result[1]; tags = result[2];
					setSuccess(`Mailer settings saved. Make sure to update the ${MailerGroupNames[mailer]} for each of your courses.`);
				}	else setFailure(data.result);
			}).catch(error => console.log(error));
		}
	};

	const SingleKeyInput = props => {
		const { value, setValue, label } = props;
		return (
			<Form.Input
				label={label + ' API Key'}
				value={value} onChange={(e,d) => setValue(d.value)}
				onBlur={() => setValue(value.trim())}
				autoFocus />
		);
	};

	const DoubleKeyInput = props => {
		const { value, setValue, label } = props; const fields = value.split(';');
		const [publicKey,setPublicKey] = useState(fields.length > 0?fields[0]:'');
		const [secretKey,setSecretKey] = useState(fields.length > 1?fields[1]:'');
		useEffect(() => setValue(publicKey + ';' + secretKey),
			[setValue,publicKey,secretKey]);
		return (
			<>
			<Form.Input
				label={label + ' API Key'}
				value={publicKey} onChange={(e,d) => setPublicKey(d.value)}
				onBlur={() => setPublicKey(publicKey.trim())}
				autoFocus />
			<Form.Input
				label={label + ' API Secret'}
				value={secretKey} onChange={(e,d) => setSecretKey(d.value)}
				onBlur={() => setSecretKey(secretKey.trim())} />
			</>
		);
	};

	const ApiKeyInput = () => {
		const label = MailerOptions[mailer].text;
		switch(mailer) {
			case 1 : return <SingleKeyInput label={label} value={mailerKey} setValue={setMailerKey} />;
			case 2 : return <SingleKeyInput label={label} value={mailerKey} setValue={setMailerKey} />;
			case 3 : return <DoubleKeyInput label={label} value={mailerKey} setValue={setMailerKey} />;
			default: return <></>;
		}
	};

	return (
		<Grid columns={1} padded centered>
			<Grid.Column width={12}>
				<Form>
					<Form.Dropdown label="Select Mailer Application" options={MailerOptions} selection value={''+mailer} onChange={(e,d) => setMailer(parseInt(d.value))} />
					<ApiKeyInput />
					<Form.Button color="blue" onClick={handleUpdateMailer}>Save Mailer Settings</Form.Button>
				</Form>
				<Success message={success} />
				<Failure message={failure} />
			</Grid.Column>
		</Grid>);
}