import { Card, Grid, Header, Icon } from 'semantic-ui-react';
import { getExpiredDays } from '../../lib/dates';
import { LinkButton } from '../../lib/utils';
import { Failure, Warning } from '../../lib/alerts';
import { PlanNames } from '../../app/Payment';
import dateFormat from 'dateformat';



function ExpiredIndicator(props) {
	const { expires } = props;
	if (expires < 0) return <div>Expires in {-expires} days</div>;
	if (expires > 0) return <div>Expired {expires} days ago</div>;
	return <div>Expired today</div>;
};

export function SubscriptionExpiredAlert(props) {
	const { session } = props;
	const expires = getExpiredDays(session.expiredAt);
	if (session.plan === 0 || expires <= -7) return <></>;
	return expires < 0 ?
		<Warning icon="exclamation circle" message={<span>Your subscription will expire in {-expires} days. Please renew your subscription <LinkButton onClick={props.onSettings}>here</LinkButton>.</span>} contained />:
		<Failure icon="exclamation circle" message={<span>Your subscription has expired. Please renew your subscription <LinkButton onClick={props.onSettings}>here</LinkButton>.</span>} contained />
}

export function UpgradeSubscriptionAlert(props) {
	const {session } = props;
	if (session.plan !== 0) return <></>;
	return (
		<Grid
			columns={1}
			centered padded>
			<Grid.Column>
				<Warning icon="exclamation circle" message={<span>You are using a free limited subscription plan. Upgrade your subscription <LinkButton onClick={props.onSettings}>here</LinkButton> for unrestricted use.</span>} contained />
			</Grid.Column>
		</Grid>
	);
}

export function SubscriptionAlerts(props) {
	const {session,onSettings} = props;
	return (
		<>
			<SubscriptionExpiredAlert session={session} onSettings={onSettings} />
			<UpgradeSubscriptionAlert session={session} onSettings={onSettings} />
		</>
	);
}

export function SubscriptionCard(props) {
	const { session } = props;
	const expires = getExpiredDays(session.expiredAt);
	const ExpiresDate = dateFormat(new Date(session.expiredAt),'mmmm dS, yyyy');
	return (
		<Card fluid>
			<Card.Content>
				<Icon className="cf-float-right" name="setting" onClick={props.onSettings} link />
				<div className="cf-float-left"><Header size="small">Subscription</Header></div>
				<div className="cf-clear">
					<div>{PlanNames[session.plan]}</div>
					{session.plan !== 0 && <div>{expires < 0?'Active until ':'Expired since '}{ExpiresDate}</div>}
					{session.plan !== 0 && <ExpiredIndicator expires={expires} />}
				</div>
			</Card.Content>
		</Card>
	);
}