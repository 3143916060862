import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Grid } from 'semantic-ui-react';
import { AppContext} from '../app/AppContext';
import { VisitorCard, SignupCard, CertificateIssuedCard } from './dashboard/Counters';
import { SubscriptionCard } from './dashboard/Subscription';
import { SearchData, SearchBar } from '../lib/search';
import { saveToStore, useStore } from '../lib/hooks';
import { apiGetCourseList, apiSearchGuestCounters } from '../lib/api';
import { LogCard } from '../app/Log';

const EmptyCounters = {count1:0,count2:0,count3:0,count4:0};

export default function DashboardPage(props) {
	const app = useContext(AppContext);
	const navigate = useNavigate();
	const session = app.session;
	const [counters		, setCounters	] = useState(EmptyCounters);
	const [searchData	, setSearchData	] = useStore('guest/dashboard/search',SearchData());
	const [courseList	, setCourseList	] = useState([]);
	const navigateToSubscriptionSetting = () => {
		saveToStore('guest/settings/section','subscription');
		navigate('/settings');
	}
	const navigateToLogs = () => {
		saveToStore('guest/profile/section','logs');
		navigate('/profile');
	}
	useEffect(() => apiGetCourseList(app, result => setCourseList(result)),[app]);
	useEffect(() => {
		apiSearchGuestCounters(app, searchData,
			result => setCounters(result),
			result => {
				setCounters(EmptyCounters);
				console.log(result);
			}
		);
	},[app, searchData]);

	return (
		<>
		<Grid padded>
			<Grid.Row>
				<Grid.Column width={16}>
					<SearchBar data={searchData} setData={setSearchData} courseList={courseList} />
					<Card.Group itemsPerRow={4}>
						<VisitorCard counters={counters} />
						<SignupCard counters={counters} />
						<CertificateIssuedCard counters={counters} />
						<SubscriptionCard session={session} onSettings={navigateToSubscriptionSetting} />
					</Card.Group>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={12}>

				</Grid.Column>
				<Grid.Column width={4}>
					<LogCard app={app} onSettings={navigateToLogs} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
		</>
	);
}