import {CertificateCanvas} from '../lib/CertificateLib';
import {PreviewIndicator} from '../lib/utils';
import {useQuery} from '../lib/hooks';

export default function CertificatePage(props) {
	const query = useQuery();
	const scale = props.scale?props.scale:100;
	const id = props.id?props.id:query.get('id');
	const preview = props.preview||query.get('preview') === 'true';
	const indicator = props.indicator === undefined || props.indicator === true;
	return(
		<>
			<PreviewIndicator preview={preview && indicator} />
			<CertificateCanvas id={id} preview={preview} scale={scale} />
		</>
	);
};

/*
import {useContext,useEffect,useRef} from 'react';
import {AppContext} from './AppContext';
import {CertificateCanvas} from '../lib/CertificateLib';
import {PreviewIndicator} from '../lib/utils';
import {useQuery} from '../lib/hooks';

const CertificateFrameStyle = {
	borderStyle:'solid',
	borderWidth:'1px',
	borderColor:'#c0c0c0',

	padding:'0',
	margin:'0',
	width:'100%'
}

function CertificateContainer(props) {
	const scale = props.scale;
	if (!scale) return props.children;
	return (
		<div style={{width:scale,margin:'auto',paddingTop:`calc(32px * ${scale})`}}>
			{props.children}
		</div>
	)
}

export default function CertificatePage(props) {
	const query = useQuery();
	const app = useContext(AppContext);
	const scale = props.scale;
	const id = props.id?props.id:query.get('id');
	const preview = props.preview||query.get('preview') === 'true';
	const indicator = props.indicator === undefined || props.indicator === true;
	const path= app.phpBase + '/view_cert.php?preview='+preview+'&id='+id+'&x='+Math.random();
	const frameRef = useRef();
	const handleLoadFrame = () => {
		const frame = frameRef.current;
		const image = frameRef.current.getSVGDocument().getElementById("root");
		const imageBounds = image.getAttribute("viewBox").split(" ");
		const aspectRatio = parseFloat(imageBounds[3]) /parseFloat(imageBounds[2]);
		frame.height = frame.clientWidth * aspectRatio;
	};
	useEffect(() => {
		function handleResize(event) {
			const frame = frameRef.current;
			const image = frameRef.current.getSVGDocument().getElementById("root");
			const imageBounds = image.getAttribute("viewBox").split(" ");
			const aspectRatio = parseFloat(imageBounds[3]) /parseFloat(imageBounds[2]);
			frame.height = frame.clientWidth * aspectRatio;
		}
		window.addEventListener('resize', handleResize);
		return () => {window.removeEventListener('resize', handleResize)}
	},[]);
	return(
		<>
			<PreviewIndicator preview={preview && indicator} />
			<CertificateContainer scale={scale}>
				<iframe ref={frameRef} title={id}
					src={path} style={CertificateFrameStyle}
					onLoad={handleLoadFrame} />
			</CertificateContainer>
		</>
	);
}; */
