import {Dropdown, Popup} from 'semantic-ui-react';

const scaleList = [ 1, 0.75, 0.5, 0.35, 0.20, 0.10];

export function ScaleSelector(props) {
	const handleScaleSelect = scale => {
		props.setScale(scale);
	};
	return (
		<Popup content="scale preview" trigger={
			<Dropdown item icon='zoom in'>
			<Dropdown.Menu>
				{scaleList.map(x => <Dropdown.Item key={x} active={x === props.scale} onClick={e=>handleScaleSelect(x)}>{x * 100 + "%"}</Dropdown.Item>)}
        	</Dropdown.Menu>
      	</Dropdown>} />
	);
}

