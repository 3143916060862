import { useContext } from 'react';
import { Navigate, Outlet , Route , Routes, useLocation, useNavigate } from 'react-router-dom';
import { Header, Image, Menu } from 'semantic-ui-react';
import { AppContext } from '../app/AppContext';
import { apiSignOut } from '../lib/api';
import DashboardPage from './Dashboard';
import UsersPage from './Users';
import SettingsPage from './Settings';
import SiteLogo from '../lib/images/main-logo.png';
import './Site.css';

function AdminSitePage(props) {
	const app = useContext(AppContext);
	const location	= useLocation().pathname;
	const navigate	= useNavigate();
	const RouteItem = props => 
		<Menu.Item onClick={()=>navigate(props.path)}
			 active={location.startsWith(props.path)} link>
			<Header>{props.text}</Header>
		</Menu.Item>;

	const handleSignOut = () => apiSignOut(app);

	return (
		<div className="cf-admin-page">
			<div className="cf-admin-pane-side">
				<Image className="cf-admin-page-logo" src={SiteLogo} size="small" centered />
					<Menu className="cf-admin-page-menu" secondary vertical>
						<RouteItem path="/dashboard"	text="Dashboard"	/>
						<RouteItem path="/users"		text="Users"		/>
						<RouteItem path="/settings"		text="Settings"		/>
						<Menu.Item onClick={handleSignOut}>
						<Header size="medium">Sign Out</Header>
					</Menu.Item>
				</Menu>
			</div>
			<div className="cf-admin-pane-main">
				<Outlet />
			</div>
		</div>
	)
}

export default function AdminSite(props) {
	return (
		<Routes>
			<Route path="/" element={<AdminSitePage />}>
				<Route path=""			element={<Navigate to="/dashboard"	/>}	/>
				<Route path="login"		element={<Navigate to="/dashboard"	/>}	/>
				<Route path="dashboard"	element={<DashboardPage				/>} />
				<Route path="users"		element={<UsersPage					/>}	/>
				<Route path="settings"	element={<SettingsPage				/>} />
				<Route path="*"			element={<Navigate to="/dashboard"	/>}	/>
			</Route>
		</Routes>
	);
}