import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Breadcrumb, Button, Grid, Header} from 'semantic-ui-react';

/*

import {Button, Header, Label, Menu, Table} from 'semantic-ui-react';

export function CourseActionBar(props) {
	const cid = useParams().cid;
	const location = useLocation().pathname;
	const navigate = useNavigate();

	const handleStepChanged = path => {
		if (props.update) {
			const course = props.update();
			if (!course) return;
		}
		navigate(path);
	}

	const handleSaveAndExit = () => {
		if (props.update) {
			const course  = props.update(); if (!course) return;
			if (props.commit) props.commit(course, true, false);
		}
	};

	const RouteItem = props => {
		return (
			<Menu.Item active={props.path.startsWith(location)} onClick={()=>handleStepChanged(props.path)}>
				<Header><Label color="grey" circular>{props.step}</Label>&nbsp;&nbsp;&nbsp;&nbsp;{props.text}</Header>
			</Menu.Item>
		);
	};

	return (
		<Table celled style={{borderWidth:'0',borderBottomWidth:'thin',margin:'0'}}>
			<Table.Row>
				<Table.Cell>
					<Menu widths={4} secondary>
						<RouteItem path={'/course/' + cid + '/details/'		} step="1" text="Course"		/>
						<RouteItem path={'/course/' + cid + '/certificate/'	} step="2" text="Certificate"	/>
						<RouteItem path={'/course/' + cid + '/funnel/'		} step="3" text="Funnel"		/>
						<RouteItem path={'/course/' + cid + '/review/'		} step="4" text="Review"		/>
					</Menu>
				</Table.Cell>
				<Table.Cell collapsing>
					<Button color="blue" onClick={handleSaveAndExit}>Save &amp; Exit</Button>
				</Table.Cell>
			</Table.Row>
		</Table>
	)
*/

export function CourseActionBar(props) {
	const cid = useParams().cid;
	const location = useLocation().pathname;
	const navigate = useNavigate();

	const handleStepChanged = path => {
		if (props.update) {
			const course = props.update();
			if (!course) return;
		}
		navigate(path);
	}

	const handleSaveAndExit = () => {
		if (props.update) {
			const course  = props.update(); if (!course) return;
			if (props.commit) props.commit(course, true, false);
		}
	};

	const RouteItem = props => {
		return (
			<>
				<Breadcrumb.Section active={props.path.startsWith(location)}
					onClick={()=>handleStepChanged(props.path)}>{props.text}</Breadcrumb.Section>
				{!props.last && <Breadcrumb.Divider content='>' />}
			</>
		);
	};

	return (
		<>
		<div style={{backgroundColor:'#eeeeee'}}>
			<Grid columns={2} padded>
				<Grid.Column verticalAlign="middle"><Header>{cid==='new'?'New':'Edit'} Course</Header></Grid.Column>
				<Grid.Column textAlign="right"><Button color="blue" onClick={handleSaveAndExit}>Save &amp; Exit</Button></Grid.Column>
			</Grid>
		</div>
		<br />
		<div style={{textAlign:'center'}}>
			<Breadcrumb size='large'>
				<RouteItem path={'/course/' + cid + '/details/'		} text="Course"			/>
				<RouteItem path={'/course/' + cid + '/certificate/'	} text="Certificate"	/>
				<RouteItem path={'/course/' + cid + '/funnel/'		} text="Funnel"			/>
				<RouteItem path={'/course/' + cid + '/review/'		} text="Review"	last	/>
			</Breadcrumb>
			<br /><br />
		</div>
		</>
	)
}