import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { Loading, Failure, Comment } from '../../lib/alerts';
import { apiDeleteUser } from '../../lib/api';

export default function DeleteUserPopup(props) {
	const {app, open, setOpen, item, onUpdated} = props;
	const [text		,setText	] = useState('');
	const [valid	,setValid	] = useState(false);
	const [loading	,setLoading	] = useState('');
	const [failure	,setFailure	] = useState('');

	useEffect(() => setValid(text === 'DELETE'),[text]);

	const handleOnClose = () => {
		setOpen(false);
		setLoading('');
		setFailure('');
		setText('');
	};

	const handleConfirm = () => {
		setFailure('');
		setLoading('Attempting to delete user ...');
		apiDeleteUser(app, item.id,
			result => { handleOnClose(); onUpdated (result); },
			result => { handleOnClose(); setFailure(result); });
	};

	return (
		<Modal
			size="tiny"
			open={open}
			onClose={handleOnClose}
			closeOnDimmerClick={false}
			centered>
			<Modal.Header>Delete User</Modal.Header>
			<Modal.Content>
				{loading === '' && item !== null &&
				<Form>
					<Form.Input label={<span>Enter <strong>DELETE</strong> to delete user <strong>{item.name}</strong></span>} value={text} onChange={(e,d)=>setText(d.value)} onBlur={()=>setText(text.trim())} autoFocus />
					{item.type === 1 &&<Form.Field><Comment icon="exclamation circle" message={<p>Please note that deleting a subscriber account will also delete everything that is associated with the account including all courses, funnels, certificates and resources uploaded by the subscriber. This process is non-recoverable.</p>} /></Form.Field>}
				</Form>}
				<Loading message={loading} />
				<Failure message={failure} icon="times circle" />
			</Modal.Content>
			<Modal.Actions>
				<Button positive disabled={!valid} onClick={handleConfirm}>Confirm</Button>
				<Button negative onClick={handleOnClose}>Cancel</Button>
			</Modal.Actions>
		</Modal>
	);
}