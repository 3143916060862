import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
	const search = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

export function useStore(key, defaultValue) {
	const [value, setValue] = useState(() => {
		const state = sessionStorage.getItem(key);
		return state !== null ? JSON.parse(state) : defaultValue; });
	useEffect(() => sessionStorage.setItem(key, JSON.stringify(value)), [value, key]);
	return [value, setValue];
}

export function saveToStore(key, value) { sessionStorage.setItem(key, JSON.stringify(value)); }
export function deleteStore(key) { if (key) sessionStorage.removeItem(key); }