import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {AppContext} from '../app/AppContext';

function CreateNewCourse(app) {
	return {
		id			: 'new'			,
		userId		: app.session.id,
		name		: ''			,
		desc		: ''			,
		tags		: ''			,
		mailGroup	: ''			,
		certTemplateId	: ''		,
		siteTemplateId	: ''		,
		certData	: '{}'			,
		siteData	: '{}'			,
		status		: 0				,
	}
}

export default function CoursePage(props) {
	const cid = useParams().cid;
	const app = useContext(AppContext);
	const navigate = useNavigate();
	const [course, setCourse] = useState(null);
	app.course = course; app.setCourse = setCourse;

	app.saveCourse = (course, exit, live) => {
		if (course.certData === '{}' || course.siteData === '{}')
			course.status = 0; else course.status = 2;
		if (course.status === 2 && live) course.status = 1;
		if (course.id === 'new') {
			axios.post(app.apiBase + '/course', course).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) {
					const course = data.result;
					app.setCourse(course);
					app.insertCourse(course);
					if (exit)navigate('/courses');
					else navigate('/course/' + course.id + '/review');
				}
			}).catch(error => console.log(error));
		}
		else {
			axios.patch(app.apiBase + '/course', course).then(response => {
				const data = response.data;
				if (app.devMode) console.log(data);
				if (data.status) {
					const course = data.result;
					app.setCourse(course);
					app.updateCourse(course);
					if(exit) navigate('/courses');
				}
			});
		}
	};

	useEffect(() => {
		if (cid === 'new') setCourse(CreateNewCourse(app));
		else  setCourse(app.selectCourse(cid));
	},	[app, app.courses, cid]);

	if (!course) return <></>;
	return <Outlet />;
}