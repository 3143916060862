import { Card, Header } from 'semantic-ui-react';

export function SignupsCard(props) {
	const {counters} = props;
	const value = counters.count1;
	return (
		<Card>
			<Card.Content>
				<Header size="small">Signups</Header>
				<Header size="huge">{value?value:0}</Header>
			</Card.Content>
		</Card>
	);
}

export function SigninsCard(props) {
	const {counters} = props;
	const value = counters.count2;
	return (
		<Card>
			<Card.Content>
				<Header size="small">Signins</Header>
				<Header size="huge">{value?value:0}</Header>
			</Card.Content>
		</Card>
	);
}

export function RenewalsCard(props) {
	const {counters} = props;
	const value = counters.count2;
	return (
		<Card>
			<Card.Content>
				<Header size="small">Renewals</Header>
				<Header size="huge">{value?value:0}</Header>
			</Card.Content>
		</Card>
	);
}