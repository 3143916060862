import { useContext, useEffect, useState } from 'react';
import { Card, Grid } from 'semantic-ui-react';
import { AppContext} from '../app/AppContext';
import { SignupsCard, SigninsCard, RenewalsCard } from './dashboard/Counters';
import { createSearchData, BasicSearchBar } from '../lib/dates';
import { useStore } from '../lib/hooks';
import {apiSearchAdminCounters } from '../lib/api';

const EmptyCounters = {count1:0,count2:0,count3:0,count4:0};

export default function DashboardPage(props) {
	const app = useContext(AppContext);
//	const session = app.session;
	const [counters		, setCounters	] = useState(EmptyCounters);
	const [searchData	, setSearchData	] = useStore('admin/dashboard/search',createSearchData());
	useEffect(() => {
		apiSearchAdminCounters(app, searchData,
			result => setCounters(result),
			result => {
				setCounters(EmptyCounters);
				console.log(result);
			}
		);
	},[app, searchData]);

	return (
		<>
		<Grid padded>
			<Grid.Row>
				<Grid.Column width={16}>
					<BasicSearchBar data={searchData} setData={setSearchData} />
					<Card.Group itemsPerRow={4}>
						<SignupsCard counters={counters} />
						<SigninsCard counters={counters} />
						<RenewalsCard counters={counters} />
					</Card.Group>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		</>
	);
}