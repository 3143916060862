import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import SignInPage from './SignIn';
import SignUpPage from './SignUp';
import ResetPasswordPage from './ResetPassword';
import ActivateAccountPage from './ActivateAccount';
import PaymentPage from '../app/Payment';
import MainLogo from '../lib/images/main-logo.png';
import './Site.css';

function LoginSitePage(props) {
	return (
		<div className="cf-login-page">
			<div className="cf-login-pane-side"></div>
			<div className="cf-login-pane-main">
				<Image className="cf-login-page-logo" src={MainLogo} />
				<br className="cf-clear-right" />
				<Outlet />
			</div>
		</div>
	);
}

export default function LoginSite(props) {
	return (
		<Routes>
			<Route path="/" element={<LoginSitePage />}>
				<Route path=""						element={<Navigate to='/signin' />	} />
				<Route path="signin"				element={<SignInPage />				} />
				<Route path="signup"				element={<SignUpPage />				} />
				<Route path="reset/password"		element={<ResetPasswordPage />		} />
				<Route path="payment/:mode/:data"	element={<PaymentPage />			} />
				<Route path="activate/:key"			element={<ActivateAccountPage />	} />
				<Route path="*"						element={<Navigate to='/signin' />	} />
			</Route>
		</Routes>
	);
}