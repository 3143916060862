import { Grid, Label } from 'semantic-ui-react';

export default function SettingsPage(props) {
	return (
		<Grid padded>
			<Grid.Column>
				<Label>Settings</Label>
			</Grid.Column>
		</Grid>
	);
}